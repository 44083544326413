import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { SourceTreeService } from '../../services/source-tree.service';
import { File } from 'src/app/classes/homebox/File';
import { FolderService } from '../../services/folder.service';
import { FileService } from '../../services/file.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { HomeboxService } from '../../services/homebox.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';


@Component({
	selector: 'app-file',
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit, OnDestroy {

	@Input () file: File
	private submenu: ElementRef
	@ViewChild ('submenu') set element (element: ElementRef) { this.submenu = element }

	private inputname: ElementRef
	@ViewChild ('inputname') set input (input: ElementRef) { this.inputname = input }

	public colours: string[]

	private mousePosition: {x: number, y: number}
	private windowSize: {w: number, h: number}
	private submenuPosition: {x: number, y:number}

	public showSubmenuFlag: boolean
	public nameChanging: boolean
	public showFileSelector: boolean
	public dialog: any

	private getTypeSubscription: Subscription = new Subscription ()
	private getFileSelectorCallbackSubscription: Subscription = new Subscription ()
	private removeFileSubscription : Subscription = new Subscription ()
	private updateFileSubscription: Subscription = new Subscription ()

	viewType: string

	constructor ( private sourceTreeService: SourceTreeService, private folderService: FolderService, private fileService: FileService, private homeboxService: HomeboxService, private errorService: ErrorService ) {
		this.colours = ['ffffff', 'fe665c', 'f6a753', 'f1ce5e', '69cb62', '53b5f0', 'd286db', 'a4a4a5']
		this.windowSize = {w: window.innerWidth, h: window.innerHeight}
		this.mousePosition = {x: 0, y: 0}
		this.showSubmenuFlag = false
		this.submenuPosition = {x: 0, y: 0}
		this.nameChanging = false
		this.showFileSelector = false

		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este archivo?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}

		this.viewType = this.homeboxService.type
		this.getTypeSubscription = this.homeboxService.getType ().subscribe (
			(type: string = 'grid') => this.viewType = type,
			(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'files.component.ts')
		)
	}

	ngOnInit() {
		this.getFileSelectorCallbackSubscription = this.fileService.getFileSelectorCallback ().subscribe (() => {
			this.showFileSelector = false
		})
	}

	ngOnDestroy () {
		this.getTypeSubscription.unsubscribe ()
		this.getFileSelectorCallbackSubscription.unsubscribe ()
		this.removeFileSubscription.unsubscribe ()
		this.updateFileSubscription.unsubscribe ()
	}

	//doble click en el archivo
	selectFile () {
		if (!this.nameChanging) {
			this.fileService.openFile (this.file)
		}
			
	}

	downloadFile () {

		this.fileService.downloadFile (this.file)
	}

	showSubmenu (e) {
		
		this.showSubmenuFlag = true
		e.preventDefault();

		setTimeout (() => {
			this.mousePosition = { x: e.clientX, y: e.clientY }

			if (this.mousePosition.x + this.submenu.nativeElement.innerWidth > this.windowSize.w) {
				this.mousePosition.x -= this.submenu.nativeElement.innerWidth
			}
			
			if (this.mousePosition.y + this.submenu.nativeElement.innerHeight > this.windowSize.h) {
				this.mousePosition.y -= this.submenu.nativeElement.innerHeight
			}
			
			this.submenuPosition = {x: this.mousePosition.x, y: this.mousePosition.y}
		}, 0)
	}

	hideSubmenu () { this.showSubmenuFlag = false }

	//cambiar color
	changeColour (e, colour: string = this.colours[0]) {

		e.stopPropagation()
		colour = (colour == this.colours[0]) ? null : colour
		this.file.setRgb (colour)

		this.updateFile (this.file, () => { this.hideSubmenu () })
	}
	//end cambiar color

	//cambiar nombre
	changeName (e) {

		e.stopPropagation()
		this.hideSubmenu ()
		this.nameChanging = true
		
		setTimeout (() => { this.inputname.nativeElement.select () }, 0)
	}

	inputNameFocusOut () {

		this.nameChanging = false
		this.updateFile (this.file)
	}

	keyDownInputName (event) {
		
		if(event.keyCode == 13) {
			this.nameChanging = false
			this.updateFile (this.file)
		}
	}
	//end cambiar nombre

	//mover la carpeta de directorio
	moveFile () {
		this.fileService.setSelectedFile (this.file)
		this.showFileSelector = true
	}


	public removeFile () {
		this.dialog.show = true
	}

	public dialogRemoveFileCallback (flag: boolean = false) {

		this.dialog.show = false

		if (flag) {
			this.sourceTreeService.removeFile (this.file)
			this.removeFileSubscription = this.fileService.removeFile (this.file).subscribe ((err: Error) => { },
			(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'files.component.ts'))
		}
	}


	private updateFile (file: File = new File (), callback: () => any = () => {}) {

		this.updateFileSubscription = this.fileService.updateFile (file).subscribe ((err: Error) => {
			
			if (err.status == httpStatus.success) {
				callback ()
			}
		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'files.component.ts'))

	}

}
