import { Country } from './country';
import { ContactLawyer } from './ContactLawyer';

export class Lawyer {

	id: number = 0
	name: string = ""
	id_country: number = 0
	email: string = ""
	phone: string = ""
	country: Country = new Country ()
	address: string = ""
	contactsLawyer: ContactLawyer []

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setIdCountry ((typeof data != "undefined" && typeof data.id_country != "undefined") ? data.id_country : 0)
		this.setEmail ((typeof data != "undefined" && typeof data.email != "undefined") ? data.email : "")
		this.setPhone ((typeof data != "undefined" && typeof data.phone != "undefined") ? data.phone : "")
		this.setCountry ((typeof data != "undefined" && typeof data.country != "undefined") ? data.country : new Country ())
		this.setAddress ((typeof data != "undefined" && typeof data.address != "undefined") ? data.address : "")
		this.setContactsLawyer ((typeof data != "undefined" && typeof data.contactsLawyer != "undefined") ? data.contactsLawyer : [] )
	}

	setId (id: number = 0) { this.id = id }
	setName (name: string = "") { this.name = name }
	setIdCountry (id: number = 0) { this.id_country = id }
	setEmail (email: string = "") { this.email = email }
	setPhone (phone: string = "") { this.phone = phone }
	setCountry (country: Country = new Country ()) { this.country = country }
	setAddress (address: string = "") { this.address = address }
	setContactsLawyer (contacts: ContactLawyer[]) { this.contactsLawyer = contacts }

	getId (): number { return this.id }
	getName (): string { return this.name }
	getIdCountry (): number { return this.id_country }
	getEmail (): string { return this.email }
	getPhone (): string { return this.phone }
	getCountry (): Country { return this.country }
	getAddress (): string { return this.address }
	getContactsLawyer (): ContactLawyer[] { return this.contactsLawyer }

	clone (lawyerToClone: Lawyer) {
		this.setId (lawyerToClone.getId ())
		this.setName (lawyerToClone.getName ())
		this.setIdCountry (lawyerToClone.getIdCountry ())
		this.setEmail (lawyerToClone.getEmail ())
		this.setPhone (lawyerToClone.getPhone ())
		this.setCountry (lawyerToClone.getCountry ())
		this.setAddress (lawyerToClone.getAddress ())
		this.setContactsLawyer (lawyerToClone.getContactsLawyer ())
	}

	toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			id_country: this.getIdCountry (),
			email: this.getEmail (),
			phone: this.getPhone (),
			address: this.getAddress ()
		}
	}

	toJson () {
		return JSON.stringify (this.toArray ())
	}

}