export class User {

	private id: number = 0
	private id_role: number = 0
	private name: string = ""
	private email: string = ""
	private code: string = ""
	
	constructor (data?: any) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setIdRole ((typeof data != "undefined" && typeof data.id_role != "undefined") ? data.id_role : 0)
		this.setEmail ((typeof data != "undefined" && typeof data.email != "undefined") ? data.email : "")
		this.setCode ((typeof data != "undefined" && typeof data.code != "undefined") ? data.code : "")
	}

	public setId (id: number = 0) { this.id = id }
	public setName (name: string = "") { this.name = name }
	public setIdRole (id: number = 0) { this.id_role = id }
	public setEmail (email: string = "") { this.email = email }
	public setCode (code: string = '') { this.code = code }

	public getId () { return this.id }
	public getName () { return this.name }
	public getIdRole () { return this.id_role }
	public getEmail () { return this.email }
	public getCode () { return this.code }

	public toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			id_role: this.getIdRole (),
			email: this.getEmail (),
			code: this.getCode ()
		}
	}

	public toJson () {
		return JSON.stringify (this.toArray ())
	}

}