import { Component, OnInit, Input } from '@angular/core';
import { showSourceTree } from 'src/app/pages/homebox/animations/sourceTree.animations';
import { Folder } from 'src/app/classes/homebox/Folder';
import { SourceTreeService } from '../../services/source-tree.service';

@Component({
	selector: 'app-source-tree-folder',
	templateUrl: './source-tree-folder.component.html',
	styleUrls: ['./source-tree-folder.component.scss'],
	animations: [showSourceTree]
})
export class SourceTreeFolderComponent implements OnInit {

	@Input () sourceTree: Folder = new Folder ()

	constructor ( private sourceTreeService: SourceTreeService ) { }

	ngOnInit() { }
	
	toggleOpenedFolder () {
		this.sourceTree.setOpened (!this.sourceTree.getOpened ())
	}

	selectFolder () {
		this.sourceTreeService.setCurrentFolder (this.sourceTree)
	}

}
