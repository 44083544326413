import { Routes, RouterModule } from '@angular/router';
import { CalendarViewComponent } from './calendar/calendar-view.component';



const routes: Routes = [

	{ path: '', component: CalendarViewComponent },

];

export const CALENDAR_ROUTES = routes
