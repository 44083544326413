import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {popupBackground} from 'src/app/animations/popup.animation';
import {Lawyer} from 'src/app/classes/Lawyer';
import {LawyersService} from 'src/app/services/lawyers.service';
import {Error} from 'src/app/classes/Error';
import {httpStatus} from 'src/app/config/httpStatus';
import {Country} from 'src/app/classes/country';
import {CountriesService} from 'src/app/services/countries.service';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {MatSelect} from '@angular/material';
import {ContactLawyer} from 'src/app/classes/ContactLawyer';
import {ContactsLawyersService} from 'src/app/services/contacts-lawyers.service';
import {ErrorService} from 'src/app/services/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/Operators';


@Component({
  selector: 'app-lawyer',
  templateUrl: './lawyer.component.html',
  styleUrls: ['./lawyer.component.scss'],
  animations: [popupBackground]
})
export class LawyerComponent implements OnInit, OnDestroy {

  isWorking: boolean;
  initAnimations = false;

  @Input('lawyer')
  set lawyer(lawyer: Lawyer) {

    this._lawyer = lawyer;

    const contactsLawyerClasses = [];
    this.originalLawyer = new Lawyer(JSON.parse(JSON.stringify(lawyer)));
    this.originalLawyer.setCountry(lawyer.getCountry());

    this.changedLawyer = new Lawyer(JSON.parse(JSON.stringify(lawyer)));
    this.changedLawyer.setCountry(lawyer.getCountry());

    for (const agent of this._lawyer.getContactsLawyer()) {
      contactsLawyerClasses.push(new ContactLawyer(JSON.parse(JSON.stringify(agent))));
    }

    this.originalLawyer.setContactsLawyer(contactsLawyerClasses);
    this.changedLawyer.setContactsLawyer(contactsLawyerClasses);

    this.isWorking = false;

    setTimeout(() => {
      this.initAnimations = true;
    }, 0);
  }

  @Output() closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  _lawyer: Lawyer;
  originalLawyer: Lawyer;
  changedLawyer: Lawyer;

  countries: Country[];
  formCountriesControl = new FormControl();
  formFilteredCountriesControl = new FormControl();
  filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;

  getCountriesSubscription: Subscription = new Subscription();
  addContactsLawyersSubscription: Subscription = new Subscription();

  protected _onDestroy = new Subject<void>();

  constructor(private LawyerService: LawyersService, private countriesService: CountriesService, private contactsAgentsService: ContactsLawyersService, private errorService: ErrorService, private toastr: ToastrService) {
    this.isWorking = false;
    this.initAnimations = false;
    this.countries = [];
  }

  ngOnInit() {

    this.getCountriesSubscription = this.countriesService.getCountries().subscribe((res: Error) => {

      if (res.status == httpStatus.success) {
        this.countries = res.data;

        this.formCountriesControl.setValue(this.countries);
        this.filteredCountries.next(this.countries.slice());

        this.formFilteredCountriesControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterCountries());

        this.formCountriesControl.valueChanges.subscribe(res => {

          if (res instanceof Country) {
            this.changedLawyer.setCountry(res);
            this.changedLawyer.setIdCountry(res.getId());
          }
        });
      }
    }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'lawyers.component.ts'));
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.getCountriesSubscription.unsubscribe();
    this.addContactsLawyersSubscription.unsubscribe();
  }

  private filterCountries() {

    if (!this.countries) {
      return;
    }

    let search = this.formFilteredCountriesControl.value;

    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(country => country.getName().toLowerCase().indexOf(search) > -1)
    );
  }


  public close() {

    this._lawyer.clone(this.originalLawyer);
    this.closePopup(false);
  }

  public save() {

    if (this.changedLawyer.getIdCountry() <= 0 || this.changedLawyer.getIdCountry() == null) {

      this.toastr.warning('Es necesario añadir un país', 'Espera...');

    } else {
      this._lawyer.clone(this.changedLawyer);
      this.isWorking = true;
      this.addLawyer();
    }
  }

  private addLawyer() {

    this.LawyerService.addLawyer(this._lawyer).subscribe((res: Error) => {

      if (res.status == httpStatus.success) {

        if (typeof res.getData().getId() != 'undefined') {
          this._lawyer.setId(res.getData().getId());
        }

        for (let contactsLawyer of this._lawyer.getContactsLawyer()) {
          contactsLawyer.setIdLawyer(this._lawyer.getId());
        }

        this.addContactsLawyersSubscription = this.contactsAgentsService.addContactsLawyers(this._lawyer.getContactsLawyer()).subscribe((res: Error) => {

          if (res.status == httpStatus.success) {
            this.closePopup(true);
          }
        });
      }
    }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'lawyers.component.ts'));
  }

  private closePopup(flag = false) {

    this.initAnimations = false;
    setTimeout(() => {
      this.closeEmitter.emit(flag);
    }, 300);
  }

  public addContactLawyer() {

    let contactLawyer = new ContactLawyer();
    contactLawyer.setIdLawyer(this.changedLawyer.getId());
    this.changedLawyer.contactsLawyer.push(contactLawyer);
  }

  public removeContactLawyerCallback(contactLawyer: ContactLawyer) {

    this.changedLawyer.contactsLawyer.splice(this.changedLawyer.contactsLawyer.indexOf(contactLawyer), 1);

  }

}
