import { Routes, RouterModule } from '@angular/router';
import { HomeboxComponent } from './homebox/homebox.component';




const routes: Routes = [

	{ path: '', component: HomeboxComponent },

];

export const HOMEBOX_ROUTES = routes
