import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from './../config/routes'
import { httpStatus, httpHeaders } from './../config/httpStatus'
import { map } from 'rxjs/Operators'
import { Error } from './../classes/Error'
import { Floor } from '../classes/Floor';
import { Flat } from '../classes/Flat';
import { Status } from '../classes/Status';
import { Lawyer } from '../classes/Lawyer';
import { Country } from '../classes/country';
import { Agency } from '../classes/Agency';
import { Costumer } from '../classes/Costumer';
import { Promotion } from '../classes/Promotion';
import { Zone } from '../classes/Zone';
import { Garage } from '../classes/Garage';
import { StorageService } from './storage.service';
import { BreadcrumbsService } from './breadcrumbs.service';
import { FlatService } from './flat.service';
import { Router } from '@angular/router';
import { ErrorService } from './error.service';
import { ParkingSpace } from '../classes/ParkingSpace';
import { StorageRoom } from '../classes/StorageRoom';


@Injectable({
	providedIn: 'root'
})
export class FlatsService {

	private controller: string

	constructor(
		private http: HttpClient,
		private storage: StorageService,
		private breadcrumbsService: BreadcrumbsService,
		private flatService: FlatService,
		private router: Router,
		private errorService: ErrorService
	) {
		this.controller = 'Flats'
	}

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getFloorsAndFlats (idZone: number = 0) {

		let floorsClasses = []
		let err = new Error ()
		let body = new HttpParams ().set ('idZone', idZone.toString ())
		let errorMessage = 'No hemos podido obtener las viviendas. Vuelve a intentarlo'

		return this.http.post (this.getUrl() + "getFlatsByZoneId", body.toString (), {headers: httpHeaders}).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let floors = res.data

				for (let floor of floors) {
					floor.created_at = new Date (floor.created_at)

					let floorClass = new Floor (floor)
					let flatsClass = []

					for (let flat of floor.flats) {
						
						flat.created_at = (flat.created_at != null) ? new Date (flat.created_at) : null
						flat.has_been_cancelled = (flat.has_been_cancelled == 1) ? true : false
						flat.updated_at = (flat.updated_at != null) ? new Date (flat.updated_at) : null
						flat.reserved_at = (flat.reserved_at != null) ? new Date (flat.reserved_at) : null
						flat.registered_at = (flat.registered_at != null) ? new Date (flat.registered_at) : null

						let lawyer = new Lawyer (flat.lawyer);
						lawyer.setCountry (new Country (flat.lawyer.country))

						flat.lawyer = lawyer
						flat.status = new Status (flat.status)
						flat.promoter = new Agency (flat.promoter)

						let parkingSpacesClasses = []
						let storageRoomsClasses = []

						for (let parkingSpace of flat.costumer.parkingSpaces) { parkingSpacesClasses.push (new ParkingSpace (parkingSpace)) }
						for (let storageRoom of flat.costumer.storageRooms) { storageRoomsClasses.push (new StorageRoom (storageRoom)) } 

						flat.costumer.parkingSpaces = parkingSpacesClasses
						flat.costumer.storageRooms = storageRoomsClasses

						flat.costumer = new Costumer (flat.costumer)

						flatsClass.push (new Flat (flat))
					}

					floorClass.setFlats (flatsClass)
					floorsClasses.push (floorClass)
				}

				err.setData (floorsClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'flats.service')
				return err
			}
		}))
	}


	getFlatsByCostumerId (costumerId: number = 0) {

		let flatsClass = []
		let err = new Error ()
		let body = new HttpParams ().set ('costumerId', costumerId.toString ())
		let errorMessage = 'No hemos podido obtener las viviendas. Vuelve a intentarlo'

		return this.http.post (this.getUrl() + "getFlatsByCostumerId", body.toString (), {headers: httpHeaders}).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let flats = res.data

				for (let flat of flats) {
						
					flat.created_at = (flat.created_at != null) ? new Date (flat.created_at) : null
					flat.has_been_cancelled = (flat.has_been_cancelled == 1) ? true : false
					flat.updated_at = (flat.updated_at != null) ? new Date (flat.updated_at) : null
					flat.reserved_at = (flat.reserved_at != null) ? new Date (flat.reserved_at) : null
					flat.registered_at = (flat.registered_at != null) ? new Date (flat.registered_at) : null

					let lawyer = new Lawyer (flat.lawyer);
					lawyer.setCountry (new Country (flat.lawyer.country))

					flat.lawyer = lawyer
					flat.status = new Status (flat.status)
					flat.promoter = new Agency (flat.promoter)

					let parkingSpacesClasses = []
					let storageRoomsClasses = []

					for (let parkingSpace of flat.costumer.parkingSpaces) { parkingSpacesClasses.push (new ParkingSpace (parkingSpace)) }
					for (let storageRoom of flat.costumer.storageRooms) { storageRoomsClasses.push (new StorageRoom (storageRoom)) } 

					flat.costumer.parkingSpaces = parkingSpacesClasses
					flat.costumer.storageRooms = storageRoomsClasses

					flat.costumer = new Costumer (flat.costumer)
					flat.promotion = new Promotion (flat.tree.promotion)
					flat.zone = new Zone (flat.tree.zone)

					flatsClass.push (new Flat (flat))
				}

				err.setData (flatsClass)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'flats.service')
				return err
			}
		}))
	}



	getFlatsByAgencyId (agencyId: number = 0) {

		let flatsClass = []
		let err = new Error ()
		let body = new HttpParams ().set ('agencyId', agencyId.toString ())
		let errorMessage = 'No hemos podido obtener las viviendas. Vuelve a intentarlo'

		return this.http.post (this.getUrl() + "getFlatsByAgencyId", body.toString (), {headers: httpHeaders}).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let flats = res.data

				for (let flat of flats) {
						
					flat.created_at = (flat.created_at != null) ? new Date (flat.created_at) : null
					flat.has_been_cancelled = (flat.has_been_cancelled == 1) ? true : false
					flat.updated_at = (flat.updated_at != null) ? new Date (flat.updated_at) : null
					flat.reserved_at = (flat.reserved_at != null) ? new Date (flat.reserved_at) : null
					flat.registered_at = (flat.registered_at != null) ? new Date (flat.registered_at) : null

					let lawyer = new Lawyer (flat.lawyer);
					lawyer.setCountry (new Country (flat.lawyer.country))

					flat.lawyer = lawyer
					flat.status = new Status (flat.status)
					flat.promoter = new Agency (flat.promoter)

					let parkingSpacesClasses = []
					let storageRoomsClasses = []

					for (let parkingSpace of flat.costumer.parkingSpaces) { parkingSpacesClasses.push (new ParkingSpace (parkingSpace)) }
					for (let storageRoom of flat.costumer.storageRooms) { storageRoomsClasses.push (new StorageRoom (storageRoom)) } 

					flat.costumer.parkingSpaces = parkingSpacesClasses
					flat.costumer.storageRooms = storageRoomsClasses

					flat.costumer = new Costumer (flat.costumer)
					flat.promotion = new Promotion (flat.tree.promotion)
					flat.zone = new Zone (flat.tree.zone)

					flatsClass.push (new Flat (flat))
				}

				err.setData (flatsClass)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'flats.service')
				return err
			}
		}))
	}


	public getRoutesForTbyb () {

		let err = new Error ()

		return this.http.get (this.getUrl () + "getRoutesForTbyb").pipe (map ((res: any) => {
				
			let data = []

				for (let item of res) {

					data.push (this.fromArrayToClass (item))
				}

				err.setData (data)

				return err
		}))
	}

	//para los TBYB
	public fromArrayToClass (item) {

		let register = {
			promotion: new Promotion (),
			zone: new Zone (),
			floor: new Floor (),
			flat: new Flat ()
		}
		
		let prom = item.promotion
		prom.active = (prom.active == 1) ? true : false
		prom.created_at = new Date (prom.created_at)
		prom.sold = (prom.sold == 1) ? true : false
		register.promotion = new Promotion (prom)

		item.zone.created_at = new Date (item.zone.created_at)
		item.zone.sold = (item.zone.sold == '1') ? true : false
		let zoneClass = new Zone (item.zone)
		let garages: Garage[] = []
		for (let garage of item.zone.garages) { garages.push(new Garage (garage)) }
		zoneClass.setGarages (garages)
		register.zone = zoneClass

		item.floor.created_at = new Date (item.floor.created_at)
		register.floor = new Floor (item.floor)

		item.flat.created_at = (item.flat.created_at != null) ? new Date (item.flat.created_at) : null
		item.flat.has_been_cancelled = (item.flat.has_been_cancelled == 1) ? true : false
		item.flat.updated_at = (item.flat.updated_at != null) ? new Date (item.flat.updated_at) : null
		item.flat.reserved_at = (item.flat.reserved_at != null) ? new Date (item.flat.reserved_at) : null

		let lawyer = new Lawyer (item.flat.lawyer);
		lawyer.setCountry (new Country (item.flat.lawyer.country))

		item.flat.lawyer = lawyer
		item.flat.status = new Status (item.flat.status)
		item.flat.promoter = new Agency (item.flat.promoter)
		item.flat.costumer = new Costumer (item.flat.costumer)

		register.flat = new Flat (item.flat)

		return register

	}

	//para los TBYB
	public setAllRouteOfFlat (item) {

		this.storage.addView ('promotions', '/hometouch/promotions', item.promotion)
		this.breadcrumbsService.setPromotionLogo (item.promotion.getLogoPath ())
		this.storage.addView ('zones', '/hometouch/zones', item.zone)
		this.storage.addView ('flat', '/hometouch/flats', item.flat)
		this.flatService.setFlat (item.flat, false)

		this.router.navigate (['/hometouch/flat'])
	}
}
