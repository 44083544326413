import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { VisitsFormComponent } from './visits-form/visits-form.component';

const routes: Routes = [

    { path: 'login', component: LoginComponent },
    { path: 'visits/:url', component: VisitsFormComponent },
    { path: '**', component: LoginComponent },
    // { path: '', pathMatch: 'full', redirectTo: '/login' }
];


export const APP_ROUTES = RouterModule.forRoot (routes, { useHash: true })
