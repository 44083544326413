import { Component, OnInit, Input } from '@angular/core';
import { Folder } from 'src/app/classes/homebox/Folder';
import { SourceTreeService } from '../../services/source-tree.service';
import { FolderService } from '../../services/folder.service';
import { File } from 'src/app/classes/homebox/File';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-folder-selector',
  templateUrl: './folder-selector.component.html',
  styleUrls: ['./folder-selector.component.scss']
})
export class FolderSelectorComponent implements OnInit {

  public sourceTree: Folder
  public selectedFolder: Folder
  public selectedFile: File

  @Input () type: string

  constructor( private sourceTreeService: SourceTreeService, private folderService: FolderService, private fileService: FileService ) { }

  ngOnInit() {

    this.sourceTree = this.sourceTreeService.sourceTree
    this.selectedFolder = this.folderService.getSelectedFolder ()
    this.selectedFile = this.fileService.getSelectedFile ()

  }

  closeSelector () {
    if (this.type == 'folder') {
      this.folderService.emitFolderSelectorCallback (false)
    } else {
      this.fileService.emitFileSelectorCallback (false)
    }
  }

}
