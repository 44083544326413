import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { GoogleCalendarService } from 'src/app/services/google-calendar.service';
import { GoogleAouthService } from 'src/app/services/google-aouth.service';
import { Calendar } from 'src/app/classes/calendar/Calendar';
import { EventClass } from 'src/app/classes/calendar/Event';
import { Subscription } from 'rxjs';
//import { WeatherService } from 'src/app/services/weather.service';
import { DateService } from 'src/app/services/date.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	host: {'class': 'dashboard-component'}
})
export class DashboardComponent implements OnInit, OnDestroy {

	calendars: Calendar[]
	events: EventClass[]
	userSignInSubscription: Subscription = new Subscription ()
	hour: number
	date: Date
	month: string
	dayWeek: string
	gradientWeather: string
	colorWeather: string = "rgba(255, 0, 0, .5)"
	count = 0
	weather: any

	constructor( private googleCalendarService: GoogleCalendarService, private googleAouthService: GoogleAouthService, private zone: NgZone, /*private weatherService: WeatherService,*/ private dateService: DateService ) {
		this.calendars = []
		this.events = []
		this.weather = {}
	}

	ngOnInit() {

		this.userSignInSubscription = this.googleAouthService.userIsSignedIn ().subscribe (() => {
			this.afterSignIn ()
		})

		this.googleAouthService.initClient ()

		// this.initWeatherApi ()

		// this.date = new Date ()
		// this.month = this.dateService.monthUmmanize (this.date.getMonth ())
		// this.dayWeek = this.dateService.dayHumanize (this.date.getUTCDay ())


		// this.hour = Date.now ()

		// setInterval (() => { this.hour = Date.now () }, 1000)
		// setInterval (() => { this.initWeatherApi () }, 150000)
	}

	ngOnDestroy () {
		this.userSignInSubscription.unsubscribe ()
	}

	afterSignIn () {

		let count = 0

		this.googleCalendarService.getCalendarsFromApi ().then ((calendars: Calendar[]) => {
			this.zone.run (() => {
				this.calendars = calendars

				for (let calendar of this.calendars) {

					this.googleCalendarService.getEventsFromApi (calendar.getId (), new Date().toISOString(), 10).then ((events: EventClass[]) => {

						count += 1;
						calendar.setEvents (events)

						if (count == this.calendars.length) {

							for (let calendar of this.calendars) {
								this.events = this.events.concat (calendar.getEvents ())
							}

						}
					})
				}
			})
		})
	}

	// initWeatherApi () {

	// 	this.weatherService.getWeatherFromApi ().then ((res) => {
	// 		this.weather = res

	// 		this.colorWeather = this.weatherService.getColorDayTime ()
	// 		this.gradientWeather =  this.weatherService.getGradientColorDayTime ()
	// 	})
	// }
}
