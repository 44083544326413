import { Component, OnInit, OnDestroy } from '@angular/core';
import { Lawyer } from 'src/app/classes/Lawyer';
import { LawyersService } from 'src/app/services/lawyers.service';
import { httpStatus } from 'src/app/config/httpStatus';
import { LocalDataSource } from 'ng2-smart-table';
import { Error } from 'src/app/classes/Error';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-lawyers',
	templateUrl: './lawyers.component.html',
	styleUrls: ['./lawyers.component.scss'],
	host: {class: 'content-lawyers'}
})
export class LawyersComponent implements OnInit, OnDestroy {

	lawyers: Lawyer[] = []
	lawyerSelected: Lawyer
	showLawyerPopup: boolean
	dialog: any

	settings = {
		noDataMessage: 'Ups..! No hemos encontrado resultados',
		columns: {
		  	name: { title: 'Nombre', editable: false },
		  	email: { title: 'E-mail', editable: false },
			phone: { title: 'Teléfono', editable: false },
			country: { title: 'País', editable: false },
			address: { title: 'Dirección', editable: false },
		},
		actions: {
			add: false,
			edit: false,
			delete: false,
			custom: [
			  	{
					name: 'edit',
					title: '<i class="icon-list-preferences fas fa-pen"></i>',
			  	},
			  	{
					name: 'delete',
					title: '<i class="icon-list-preferences fas fa-trash"></i>',
				},
			],
		},
	}

	source: LocalDataSource
	
	getLawyersSubscription: Subscription = new Subscription ()
	removeLawyerSubscription: Subscription = new Subscription ()

	constructor(private lawyersService: LawyersService, private errorService: ErrorService ) {

		this.source = new LocalDataSource ()
		this.showLawyerPopup = false
		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este cliente?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() {

		this.getLawyersSubscription = this.lawyersService.getLawyers ().subscribe (res => {

			if (res.status == httpStatus.success) {
				this.lawyers = res.data

				this.loadCostumersOnTable ()

			}
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'lawyers.component.ts'))
	}

	ngOnDestroy () {

		this.getLawyersSubscription.unsubscribe ()
		this.removeLawyerSubscription.unsubscribe ()
	}


	loadCostumersOnTable () {

		let costumersForTable = []

		for (let lawyer of this.lawyers) {
			let lawy = {
				id: lawyer.getId (),
				name: lawyer.getName (),
				country: lawyer.getCountry (). getName (),
				email: lawyer.getEmail (),
				phone: lawyer.getPhone (),
				address: lawyer.getAddress ()
			}
			costumersForTable.push (lawy)	
		}

		this.source.load (costumersForTable)
	}


	rowActionClick (event) {

		this.lawyerSelected = this.lawyers.find ((lawyer: Lawyer) => lawyer.getId () == event.data.id)

		if (event.action == 'edit') {
			this.editLawyer ()
		} else {
			this.removeLawyer ()
		}
	}

	editLawyer () {

		this.showLawyerPopup = true
	}

	createLawyer () {

		this.lawyers.push (new Lawyer ())
		this.lawyerSelected = this.lawyers[this.lawyers.length - 1]
		this.showLawyerPopup = true
	}

	removeLawyer () {

		this.dialog.show = true
	}

	dialogRemoveLawyerCallback (flag: boolean) {

		this.dialog.show = false

		if (flag) {
			
			this.removeLawyerSubscription = this.lawyersService.removeLawyer (this.lawyerSelected.getId ()).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {

					this.lawyers.splice (this.lawyers.indexOf (this.lawyerSelected), 1)
					this.loadCostumersOnTable ()
				}
				
			}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'lawyers.component.ts'))	
		}
	}


	closePopupLawyerCallback (flag: boolean = false) {

		this.showLawyerPopup = false
		
		if (!flag && this.lawyers[this.lawyers.length - 1].getId () <= 0) {
			this.lawyers.splice (-1, 1);
		}

		this.loadCostumersOnTable ()
	}
}
