import {Component, OnDestroy, OnInit} from '@angular/core';
import {StorageService} from 'src/app/services/storage.service';
import {FlatsService} from 'src/app/services/flats.service';
import {FlatService} from 'src/app/services/flat.service';
import {Floor} from 'src/app/classes/Floor';
import {httpStatus} from 'src/app/config/httpStatus';
import {showFlats} from 'src/app/animations/flat.animations';
import {Flat} from 'src/app/classes/Flat';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from 'src/app/services/error.service';


@Component({
  selector: 'app-flats',
  templateUrl: './flats.component.html',
  styleUrls: ['./flats.component.scss'],
  host: {'class': 'flats-component'},
  animations: [showFlats]
})
export class FlatsComponent implements OnInit, OnDestroy {

  floors: Floor[];
  maxNumberFlatsInFloor: number;
  arrayMaxNumberFlatsInFloor: number[];
  arrayTextOfColumns: string[];
  zoneId: number;

  getFloorsAndFlatsSub: Subscription = new Subscription();

  constructor(private storage: StorageService, private flatsService: FlatsService, private router: Router, private flatService: FlatService, private errorService: ErrorService) {
    this.floors = [];
    this.maxNumberFlatsInFloor = 0;
    this.arrayTextOfColumns = [];
    this.zoneId = 0;
  }

  ngOnInit() {

    this.storage.setCurrentViewName('flats');

    for (const view of this.storage.viewList) {
      if (view.name === 'zones') {
        this.zoneId = view.data.id;
      }
    }

    this.getFloorsAndFlatsSub = this.flatsService.getFloorsAndFlats(this.zoneId).subscribe((res) => {

      if (res.status === httpStatus.success) {

        const floors = res.data;
        this.floors = floors.reverse();
        this.startAnimation(true);

        this.calculateMaxNumberOfFlatsInFloors();
      }
    }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'flats.component.ts'));
  }

  ngOnDestroy() {
    this.getFloorsAndFlatsSub.unsubscribe();
  }

  calculateMaxNumberOfFlatsInFloors() {

    let maxNumberFlatsInFloor = 0;
    let startCharCode = 65;

    for (const floor of this.floors) {
      if (floor.getFlats().length > maxNumberFlatsInFloor) {
        maxNumberFlatsInFloor = floor.getFlats().length;
      }
    }

    this.arrayMaxNumberFlatsInFloor = Array.from(Array(maxNumberFlatsInFloor).keys());

    //sólo para dama IV Bloque 2
    if (this.zoneId === 13) {
      startCharCode += 12;
    }

    if (this.zoneId === 26 || this.zoneId === 27 || this.zoneId === 28) {
      for (let i = maxNumberFlatsInFloor; i > 0 ; i--) {
        this.arrayTextOfColumns.push((i).toString() + ' - ' + String.fromCharCode(startCharCode - 1 + i));
      }
    } else {
      for (let i = 0; i < maxNumberFlatsInFloor; i++) {
        this.arrayTextOfColumns.push((i + 1).toString() + ' - ' + String.fromCharCode(startCharCode + i));
      }
    }
  }


  showFlat(flat: Flat) {

    this.storage.addView('flat', this.router.url, flat);
    //this.flatService.setFlat (flat, false)

    this.startAnimation(false, () => {
      this.router.navigate(['/hometouch/flat']);
    });
  }


  startAnimation(show: boolean = false, callback = () => {
  }) {

    let timer = 0;

    this.floors.forEach((floor: Floor) => {

      floor.getFlats().forEach((flat: Flat) => {
        setTimeout(() => {
          flat.setShowAnimation(show);
        }, timer);

        timer += 20;
      });

    });

    setTimeout(() => {
      callback();
    }, timer + 500);
  }
}
