import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component'
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
	declarations: [
		DashboardComponent
	],
	imports: [
		CommonModule,
		SharedModule
	],

	exports: [
		DashboardComponent
	]
})
export class DashboardModule { }
