import { Injectable } from '@angular/core';
import { routes } from '../config/routes';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { Error } from './../classes/Error'
import { Agent } from '../classes/Agent';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class AgentsService {

	private controller = 'Agents';

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	

	getUrl () {
		return routes.api + this.controller + "/"
	}


	addAgents (agents: Agent[]) {

		let err = new Error ()
		let agentsArray = []
		let errorMessage = 'Ups..! No hemos podido actualizar la agencia. Vuelve a intentarlo'

		for (let agent of agents)
			agentsArray.push (agent.toArray ())

		let body = new HttpParams ().set ('agents', JSON.stringify (agentsArray))


		return this.http.post (this.getUrl () + "addAgents", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				// if (typeof res.data.id != "undefined") {
				// 	agent.setId (res.data.id)
				// }
				
				// err.setData (agent)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'agents.service')
				return err
			}
			

		}))
	}
	

	removeAgent (id: number) {

		let err = new Error ()
		let errorMessage = 'Ups..! No hemos podido actualizar la agencia. Vuelve a intentarlo'

		let body = new HttpParams ()
		.set ('id', id.toString ())

		return this.http.post (this.getUrl() + "removeAgent", body.toString (), {headers: httpHeaders}).pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {

				err.setData (res.data.id)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'agents.service')
				return err
			}
		}))
	}
}
