import { Component, OnInit, OnDestroy } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';
import { FlatService } from 'src/app/services/flat.service';
import { Costumer } from 'src/app/classes/Costumer';
import { CostumersService } from 'src/app/services/costumers.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-flat-information',
	templateUrl: './flat-information.component.html',
	styleUrls: ['./flat-information.component.scss'],
	host: {class: 'flat-information-content'}
})

export class FlatInformationComponent implements OnInit, OnDestroy {

	flat: Flat
	acordeon: boolean[] = [true, false, false, false]
	costumers: Costumer[]
	showCostumerPopup: boolean
	itemsListForPopup: any[]
	showItemList: boolean

	getFlatSubscription: Subscription
	getCostumersSubscription: Subscription

	constructor( private flatService: FlatService, private costumerService: CostumersService, private toastr: ToastrService, private errorService: ErrorService ) {
		this.showCostumerPopup = false;
		this.showItemList = false;
		this.itemsListForPopup = []
	}

	ngOnInit() {

		this.flat = this.flatService.flat
		this.getFlatSubscription = this.flatService.getFlat ().subscribe ((flat: Flat) => this.flat = flat )

		this.getCostumersSubscription = this.costumerService.getCostumers ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {
				this.costumers = res.getData ()

				if (this.flat.getIdCostumer () > 0) {
					
					this.flat.setCostumer (this.costumers.find ((costumer: Costumer) => costumer.getId () == this.flat.getIdCostumer ()))
					this.flatService.setFlat (this.flat)
				}
			}
			
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-agency.component.ts'))
	}


	ngOnDestroy () {

		this.getFlatSubscription.unsubscribe ()
		this.getCostumersSubscription.unsubscribe ()
	}


	public updateAcordeon (position) {

		for (let index in this.acordeon)
			this.acordeon[index] = false

		this.acordeon[position] = true
	}

	

	public closePopupCostumerCallback () {

		this.showCostumerPopup = false
		this.flatService.setFlat (this.flat)
	}

	public openCostumerSelector () {

		this.itemsListForPopup = []

		for (let costumer of this.costumers) {

			this.itemsListForPopup.push ({
				selected: (costumer.getId () == this.flat.getIdCostumer ()) ? true : false,
				value: costumer.getName () + " " + costumer.getSurname (),
				data: costumer
			})	
		}

		this.showItemList = true
	}

	public appFilterCallback (item: any) {

		this.showItemList = false

		let className = item.constructor.name

		if (className == 'Costumer') {
			this.flat.setCostumer (item)
			this.flat.setIdCostumer (item.getId ())
			this.flatService.setFlat (this.flat)
		}
	}
}
