import { Component, OnInit, Input, NgZone, EventEmitter, Output } from '@angular/core';
import { EventClass } from 'src/app/classes/calendar/Event';
import { AmazingTimePickerService } from 'amazing-time-picker';
import * as moment from 'moment';
import { Calendar } from 'src/app/classes/calendar/Calendar';
import { GoogleCalendarService } from 'src/app/services/google-calendar.service';
import { NotificationTypes } from '../notification/notificationTypes';
import { calendarsIdsEditables } from 'src/app/config/googleCalendarSettings';

@Component({
	selector: 'app-event-popup',
	templateUrl: './event-popup.component.html',
	styleUrls: ['./event-popup.component.scss']
})
export class EventPopupComponent implements OnInit {

	@Input () event: EventClass = null
	@Input () typeOfEdit: string = 'create'
	@Output () eventAddedCallback: EventEmitter<EventClass> = new EventEmitter<EventClass> ()
	@Output () eventUpdatedCallback: EventEmitter<EventClass> = new EventEmitter<EventClass> ()

	@Input ('calendars')
		set calendars (calendars: Calendar[]) {

			if (calendars.length <= 0) {

				this.googleCalendarService.getCalendarsFromApi ().then ((calendarsApi: Calendar[]) => {
					this.zone.run (() => {
						for (let calendar of calendarsApi) {
							if (calendar.getRole () == 'owner' && calendarsIdsEditables.indexOf (calendar.getId ()) > -1) {
								this._calendars.push (calendar)
							}
						}
					})
				})

			} else {

				for (let calendar of calendars) {
					if (calendar.getRole () == 'owner' && calendarsIdsEditables.indexOf (calendar.getId ()) > -1) {
						this._calendars.push (calendar)
					}
				}
			}

			if (typeof this.event.getId () != "undefined") {
				this.calendarSelected = this.calendars.find ((calendar: Calendar) => calendar.getId () == this.event.getCalendarId ())
			}
		}

		get calendars () { return this._calendars }

	datepickerSettings: any
	_calendars: Calendar[] = []
	calendarSelected: Calendar
	notification: any

	_title: string
	set title (title) {
		this.event.setSummary (title)
		this._title = title
	}

	get title () {
		this._title = this.event.getSummary ()
		return this._title
	}

	
	

	constructor(private atp: AmazingTimePickerService, private googleCalendarService: GoogleCalendarService, private zone: NgZone) {

		this.datepickerSettings = {
			bigBanner: false,
			timePicker: false,
			format: 'dd-MM-yyyy',
			defaultOpen: false
		}

		this.notification = { type: NotificationTypes.warning, text: null }
	}

	ngOnInit() { }

	setCalendar (calendar: Calendar) {
		this.calendarSelected = calendar
		this.event.setCalendarId (calendar.getId ())
	}

	setDate (stringDate: string) {

		let dateObj = new Date (stringDate)
		
		let date = moment (dateObj)

		let start = moment (moment (date).format ())
		let end = moment (moment (date).format ())

		start.hours (this.event.getStart ().hours ()).minutes (this.event.getStart ().minutes ())
		end.hours (this.event.getEnd ().hours ()).minutes (this.event.getEnd ().minutes ())

		this.event.setStart (start)
		this.event.setEnd (end)
	}


	openHourpicker (type = 'start') {

		const amazingTimePicker = this.atp.open();

		amazingTimePicker.afterClose().subscribe(time => {

			let start: moment.Moment = moment ()
			let end: moment.Moment = moment ()

			if (type == 'start') {

				start = moment (this.event.getStart ().format ()).hours (moment (time, 'HH:mm').hours()).minutes (moment (time, 'HH:mm').minutes ())
				end = this.event.getEnd ()

			} else {

				start = this.event.getStart ()
				end = moment (this.event.getEnd ().format ()).hours (moment (time, 'HH:mm').hours()).minutes (moment (time, 'HH:mm').minutes ())	
			}

		
			if (start.isAfter (end)) {
				end = moment (this.event.getStart ().format ()).hours (this.event.getStart ().hours()).minutes (this.event.getStart ().hours())
				end.add (1, 'h')
			}

			this.event.setStart (start)
			this.event.setEnd (end)
		});
	}

	addEvent () {

		if (this.event.getSummary () == null || this.event.getSummary () == '') {
			this.notification.text = 'Añade un título al evento';
			return;
		}

		if (this.event.getCalendarId () == null || this.event.getCalendarId () == '') {
			this.notification.text = 'Elige un calendario para el evento';
			return;
		}

		if (this.typeOfEdit == 'create') { this.createEvent () }
		else if (this.typeOfEdit == 'edit') { this.updateEvent () }

	}

	private createEvent () {

		this.googleCalendarService.createEventOnApi (this.event).then ((event: EventClass) => {
			this.eventAdded (event)
		})
	}

	private updateEvent () {

		this.googleCalendarService.updateEventOnApi (this.event).then ((event: EventClass) => {
			this.eventUpdated (event)
		})
	}



	eventAdded (event: EventClass) {
		this.eventAddedCallback.emit (event)
	}

	eventUpdated (event: EventClass) {
		this.eventUpdatedCallback.emit (event)
	}

	closePopup () {
		this.eventAddedCallback.emit (new EventClass ())
	}

	

}
