export interface PromoterInterface {
	id: number
	name: string
	logo_path: string

	getId (): number
	getName (): string
	getLogoPath (): string

	setId (id: number): void
	setName (name: string): void
	setLogoPath (logo: string): void
}

export class Promoter implements PromoterInterface {
	id
	name
	logo_path


	constructor (data?: PromoterInterface) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setLogoPath ((typeof data != "undefined" && typeof data.logo_path != "undefined") ? data.logo_path : "")
	}

	getId () { return this.id }
	getName () { return this.name }
	getLogoPath () { return this.logo_path }

	setId (id = 0) { this.id = id }
	setName (name = "") { this.name = name }
	setLogoPath (logo = "") { this.logo_path = logo }

	
}