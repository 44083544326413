import { File } from './File';

export class Folder {

	id: number = 0
	name: string = ""
	id_parent: number = 0
	rgb: string = "fff";
	created_at: Date = null
	protected: boolean = false
	folders: Folder[] = []
	files: File[] = []
	active: boolean = false
	opened: boolean = false
	imagePath: string = "assets"

	constructor (data: any = {}) {
		this.setId ((typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data.name != "undefined") ? data.name : "")
		this.setIdParent ((typeof data.id_parent != "undefined") ? data.id_parent : 0)
		this.setRgb ((typeof data.rgb != "undefined") ? data.rgb : "#fff")
		this.setCreatedAt ((typeof data.created_at != "undefined") ? data.created_at : 0)
		this.setProtected ((typeof data.protected != "undefined") ? (data.protected == 1) ? true: false : false)

		this.calculateImagePath ()
	}

	setId (id: number = 0) { this.id = id }
	setName (name: string = "") { this.name = name }
	setIdParent (id: number = 0) { this.id_parent = id }
	setRgb (rgb: string = "fff") { this.rgb = rgb }
	setCreatedAt (date: Date = null) { this.created_at = date }
	setProtected (flag: boolean = true) { this.protected = flag }
	setFolders (folders: Folder[] = []) { this.folders = folders }
	setFiles (files: File[] = []) { this.files = files }
	setActive (flag: boolean = false) { this.active = flag; this.calculateImagePath () }
	setOpened (flag: boolean = false) { this.opened = flag; }
	
	getId (): number { return this.id }
	getName (): string { return this.name }
	getIdParent (): number { return this.id_parent }
	getRgb (): string { return this.rgb }
	getCreatedAt (): Date { return this.created_at }
	getProtected (): boolean { return this.protected }
	getFolders (): Folder[] { return this.folders }
	getFiles (): File[] { return this.files }
	getActive (): boolean { return this.active }
	getOpened (): boolean { return this.opened }
	getImagePath (): string { return this.imagePath }

	calculateImagePath () {

		let imagePath = "assets/media/homebox/minifolder-"
		imagePath += (this.active) ? 'selected' : 'unselected'
		imagePath += '.png'

		this.imagePath = imagePath
		return this.imagePath
	}

	removeChildFolder (childFolder: Folder = new Folder ()) {
		this.folders.splice (this.folders.indexOf (childFolder), 1)
	}

	removeFile (file: File = new File ()) {
		this.files.splice (this.files.indexOf (file), 1)
	}

	toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			id_parent: this.getIdParent (),
			rgb: this.getRgb (),
			created_at: this.getCreatedAt (),
			protected: this.getProtected (),
		}
	}

	toJson () {
		return JSON.stringify (this.toArray ())
	}


}