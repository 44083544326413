import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Folder } from 'src/app/classes/homebox/Folder';
import { SourceTreeService } from '../../services/source-tree.service';
import { FolderService } from '../../services/folder.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { HomeboxService } from '../../services/homebox.service';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-folder',
	templateUrl: './folder.component.html',
	styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit, OnDestroy {

	@Input () folder: Folder
	private submenu: ElementRef
	@ViewChild ('submenu') set element (element: ElementRef) { this.submenu = element }

	private inputname: ElementRef
	@ViewChild ('inputname') set input (input: ElementRef) { this.inputname = input }

	public colours: string[]

	private mousePosition: {x: number, y: number}
	private windowSize: {w: number, h: number}
	private submenuPosition: {x: number, y:number}

	public showSubmenuFlag: boolean
	public nameChanging: boolean
	public showFolderSelector: boolean

	viewType: string
	public dialog: {show: boolean, text: string, config: {}}

	private getTypeSubscription: Subscription = new Subscription ()
	private getFolderSelectorCallbackSubscription: Subscription = new Subscription ()
	private removeFolderSubscription: Subscription = new Subscription ()
	private addFolderSubscription: Subscription = new Subscription ()
	
	constructor ( private sourceTreeService: SourceTreeService, private folderService: FolderService, private homeboxService: HomeboxService, private errorService: ErrorService ) {
		this.colours = ['ffffff', 'fe665c', 'f6a753', 'f1ce5e', '69cb62', '53b5f0', 'd286db', 'a4a4a5']
		this.windowSize = {w: window.innerWidth, h: window.innerHeight}
		this.mousePosition = {x: 0, y: 0}
		this.showSubmenuFlag = false
		this.submenuPosition = {x: 0, y: 0}
		this.nameChanging = false
		this.showFolderSelector = false

		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar esta carpeta? Todas las carpetas y archivos que contiene también se borrarán',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}

		this.viewType = this.homeboxService.type
		this.getTypeSubscription = this.homeboxService.getType ().subscribe ((type: string = 'grid') => this.viewType = type)
	}

	ngOnInit() {
		//calback cuando el selector de carpetas termine o se cierre
		this.getFolderSelectorCallbackSubscription = this.folderService.getFolderSelectorCallback ().subscribe (() => {
			this.showFolderSelector = false
		})
	}

	ngOnDestroy () {
		this.getTypeSubscription.unsubscribe ()
		this.getFolderSelectorCallbackSubscription.unsubscribe ()
		this.removeFolderSubscription.unsubscribe ()
		this.addFolderSubscription.unsubscribe ()
	}

	selectFolder () {
		if (!this.nameChanging)
			this.sourceTreeService.setCurrentFolder (this.folder)
	}

	showSubmenu (e) {
		
		this.showSubmenuFlag = true
		e.preventDefault();

		setTimeout (() => {
			this.mousePosition = { x: e.clientX, y: e.clientY }

			if (this.mousePosition.x + this.submenu.nativeElement.innerWidth > this.windowSize.w) {
				this.mousePosition.x -= this.submenu.nativeElement.innerWidth
			}
			
			if (this.mousePosition.y + this.submenu.nativeElement.innerHeight > this.windowSize.h) {
				this.mousePosition.y -= this.submenu.nativeElement.innerHeight
			}
			
			this.submenuPosition = {x: this.mousePosition.x, y: this.mousePosition.y}
		}, 0)
	}

	hideSubmenu () { this.showSubmenuFlag = false }

	//cambiar color
	changeColour (e, colour: string = this.colours[0]) {

		e.stopPropagation()
		colour = (colour == this.colours[0]) ? null : colour
		this.folder.setRgb (colour)

		this.updateFolder (this.folder, () => { this.hideSubmenu () })

	}
	//end cambiar color

	//cambiar nombre
	changeName (e) {

		e.stopPropagation()
		this.hideSubmenu ()
		this.nameChanging = true
		
		setTimeout (() => { this.inputname.nativeElement.select () }, 0)
	}

	inputNameFocusOut () {

		this.nameChanging = false
		this.updateFolder (this.folder)
	}

	keyDownInputName (event) {
		
		if(event.keyCode == 13) {
			this.nameChanging = false
			this.updateFolder (this.folder)
		}
	}
	//end cambiar nombre


	//mover la carpeta de directorio
	moveFolder () {
		this.folderService.setSelectedFolder (this.folder)
		this.showFolderSelector = true
	}
	//end mover la carpeta de directorio

	//descargar carpeta
	public downloadFolder () {
		this.folderService.downloadFolder (this.folder)
	}

	//end descargar carpeta

	//eliminar carpeta
	public removeFolder () {
		this.dialog.show = true
	}

	public dialogRemoveFolderCallback (flag: boolean = false) {
		
		this.dialog.show = false

		if (flag) {

			if (this.folder.getProtected () == true) {
				alert ('La carpeta está protegida')
				return
			}

			this.sourceTreeService.removeFolder (this.folder)

			this.removeFolderSubscription = this.folderService.removeFolder (this.folder).subscribe ((res: Error) => {
				//console.log ('error: ', res)
				
			},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'folder.component.ts'))
		}
	}
	//end eliminar carpeta


	private updateFolder (folder: Folder = new Folder (), callback: () => any = () => {}) {

		this.addFolderSubscription = this.folderService.addFolder (folder).subscribe ((err: Error) => {
			
			if (err.status == httpStatus.success) {
				callback ()
			}
		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'folder.component.ts'))

	}

}
