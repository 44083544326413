import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators';
import { Error } from 'src/app/classes/Error'
import { httpStatus } from '../config/httpStatus';
import { AlertClass } from '../classes/AlertClass';
import { FlatsService } from './flats.service';
import { ErrorService } from './error.service';

@Injectable({
	providedIn: 'root'
})
export class AlertsService {

	private controller: string
	alerts: AlertClass[]
	alertsEmitter: EventEmitter<AlertClass[]> = new EventEmitter<AlertClass[]> ()

	constructor( private http: HttpClient, private flatsService: FlatsService, private errorService: ErrorService ) {
		this.controller = 'Alerts'
		this.alerts = []
		this.getAlertsFromApi ()
	}

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getAlertsFromApi () {
		

		return this.http.get (this.getUrl () + "getAlerts").pipe (map ((res:any) => {

			let err = new Error ()
			this.alerts = []
			let errorMessage = 'No hemos podido obtener las alertas. Vuelve a intentarlo'
			
			if (res.status == httpStatus.success) {

				let alerts = res.data

				for (let alertArray of alerts) {
					
					let alertClass = new AlertClass ({id: alertArray.id, id_flat: alertArray.id_flat, content: alertArray.content})

					let classes = this.flatsService.fromArrayToClass (alertArray)

					let flat = classes.flat
					let floor = classes.floor
					let zone = classes.zone
					let promotion = classes.promotion

					alertClass.setFlat (flat)
					alertClass.setFloor (floor)
					alertClass.setZone (zone)
					alertClass.setPromotion (promotion)

					this.alerts.push (alertClass)
				}

				this.alertsEmitter.emit (this.alerts)
				return this.alerts

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, "Ups..!", 'alerts.service')
				return err
			}

		}))
	}

	getAlerts () {
		return this.alertsEmitter
	}



}
