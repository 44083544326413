import { Routes } from '@angular/router'
import { FlatInformationComponent } from './flat-information/flat-information.component';
import { FlatCommentsComponent } from './flat-comments/flat-comments.component';
import { FlatHistoryComponent } from './flat-history/flat-history.component';
import { TbybComponent } from './tbyb/tbyb.component';

export const FLAT_ROUTES: Routes = [
	{path: 'information', component: FlatInformationComponent},
	{path: 'comments', component: FlatCommentsComponent},
	{path: 'history', component: FlatHistoryComponent},
	{path: 'tbyb', component: TbybComponent},
	{path: '**', pathMatch: 'full', redirectTo: 'information'},
]