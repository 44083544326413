import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { routes } from './../config/routes'
import { httpStatus } from './../config/httpStatus'
import { map } from 'rxjs/Operators'
import { Error } from './../classes/Error'
import { Country } from '../classes/country';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class CountriesService {

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	private controller = 'Countries';

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getCountries () {

		let countriesClasses = []
		let err = new Error ()
		let errorMessage = 'No hemos podido descargar los paises. Vuelve a intentarlo'

		return this.http.get (this.getUrl () + "getCountries").pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let countries = res.data;
				
				for (let country of countries) {
					let coun = new Country (country)
					countriesClasses.push (coun)
				}

				err.setData (countriesClasses)
				return err

			}  else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'countries.service')
				return err
			}
		}))
	}
}
