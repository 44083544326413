import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';


const routes: Routes = [

	{ path: '', component: DashboardComponent },

];

export const DASHBOARD_ROUTES = routes
