import { Injectable } from '@angular/core';
import { routes } from '../config/routes';
import { HttpClient, HttpParams } from '@angular/common/http';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { Error } from './../classes/Error'
import { Agency } from '../classes/Agency';
import { Country } from '../classes/country';
import { Agent } from '../classes/Agent';
import { ErrorService } from './error.service';

@Injectable({
	providedIn: 'root'
})
export class AgenciesService {

	private controller = 'Promoters';

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getAgencies () {

		let err = new Error ()
		let errorMessage = 'No hemos podido descargar las las agencias. Vuelve a intentarlo'

		return this.http.get (this.getUrl () + "getPromoters").pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {

				let agencies = res.data
				let agenciesClasses = []

				for (let agency of agencies) {

					let marketClass = new Country (agency.marketClass)
					let agencyClass = new Agency (agency)
					let agentsClasses = []
					agencyClass.setMarketClass (marketClass)

					for (let agent of agency.agents) {
						agent = new Agent (agent)
						agentsClasses.push (agent)
					}

					agencyClass.setAgents (agentsClasses)
					agenciesClasses.push (agencyClass)
				}

				err.setData (agenciesClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'agencies.service')
				return err
			}
		}))
	}


	public addAgency (agency: Agency) {

		let err = new Error ()
		let body = new HttpParams ().set ('promoter', agency.toJson ())
		let errorMessage = 'No hemos podido actualizar la agencia. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "addPromoter", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				if (typeof res.data.id != "undefined") {
					agency.setId (res.data.id)
				}
				
				err.setData (agency)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, "Ups..!", 'agencies.service')
				return err
			}
			

		}))
	}
	

	removeAgency (agency: Agency) {

		let err = new Error ()
		let body = new HttpParams ().set ('id', agency.getId().toString ())
		let errorMessage = 'No hemos podido eliminar la agencia. Vuelve a intentarlo'

		return this.http.post (this.getUrl() + "removePromoter", body.toString (), {headers: httpHeaders}).pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {

				err.setData (res.data.id)
				return err
				
			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, "Ups..!", 'agencies.service')
				return err
			}

		}))

	}
	
}
