import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { routes } from '../config/routes';
import { httpHeaders, httpStatus } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { Error } from './../classes/Error'
import { Visit } from '../classes/Visit';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  private controller = 'Visits';

  constructor( private http: HttpClient, private errorService: ErrorService ) { }

  getUrl () {
    return routes.api + this.controller + '/'
  }

  public addVisit (data: any) {

    data.budget = parseInt (data.budget)

    const err = new Error ()
    const body = new HttpParams ().set ('visit', JSON.stringify (data))
    const errorMessage = 'No podemos añadir la visita. Vuelve a intentarlo'

    return this.http.post (this.getUrl () + 'addVisit', body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

      if (res.status === httpStatus.success) {

        err.setData (true)
        return err

      } else {

        err.setError (errorMessage)
        this.errorService.notifyError (res, errorMessage, 'Ups..!', 'visits.service')
        return err
      }
    }))
  }

  getAllVisits () {

		let visitsClasses = []
		let err = new Error ()
		let errorMessage = 'No hemos podido descargar las promociones. Vuelve a intentarlo'

		return this.http.get (this.getUrl () + "getVisits").pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {
				let visits = res.data;
				
				for (let visit of visits) {
					let prom = visit
					prom.active = (prom.active == 1) ? true : false
					prom.created_at = new Date (prom.created_at)
          prom.sold = (prom.sold == 1) ? true : false
          
					visitsClasses.push (new Visit (prom))
				}

				err.setData (visitsClasses)

				return err

			}  else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'promotions.service')
				return err
			}

		}))

  }
  
  public deleteVisit (id: number) {

    const err = new Error ()
    const body = new HttpParams ().set ('id', JSON.stringify (id))
    const errorMessage = 'No podemos borrar la visita. Vuelve a intentarlo'

    return this.http.post (this.getUrl () + 'deleteVisit', body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

      if (res.status === httpStatus.success) {

        err.setData (true)
        return err

      } else {

        err.setError (errorMessage)
        this.errorService.notifyError (res, errorMessage, 'Ups..!', 'visits.service')
        return err
      }
    }))


  }
}
