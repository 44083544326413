import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { routes } from '../config/routes';
import { Agency } from '../classes/Agency';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { MatSelect } from '@angular/material';
import { AgenciesService } from '../services/agencies.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from '../config/httpStatus';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil, take } from 'rxjs/Operators';
import { ErrorService } from '../services/error.service';
import { Country } from '../classes/country';
import { CountriesService } from '../services/countries.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { VisitsService } from '../services/visits.service';
import { ToastrService } from 'ngx-toastr';
import { PromotionsService } from '../services/promotions.service';
import { Promotion } from '../classes/Promotion';

@Component({
  selector: 'app-visits-form',
  templateUrl: './visits-form.component.html',
  styleUrls: ['./visits-form.component.scss']
})
export class VisitsFormComponent implements OnInit, AfterViewInit {

  public urlLogo: string
  public datepickerSettings: any
  public currentDate: string
  public isWorking: boolean
  public fields: any

  // select agency
  agencies: Agency[]
  agencySelected: Agency
  formAgenciesControl = new FormControl()
  formFilteredAgenciesControl = new FormControl ()
  filteredAgencies: ReplaySubject<Agency[]> = new ReplaySubject<Agency[]>(1);
  @ViewChild('selectAgencies') selectAgencies: MatSelect;
  protected _onDestroy = new Subject<void>();
  private getAgenciesSubscription: Subscription = new Subscription ()

  // select country
  // select
  countries: Country[]
  formCountriesControl = new FormControl()
  formFilteredCountriesControl = new FormControl ()
  filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  private getCountriesSubscription: Subscription = new Subscription ()

  constructor (
    private agenciesService: AgenciesService,
    private errorService: ErrorService,
    private countriesService: CountriesService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private visitsService: VisitsService,
    private toastr: ToastrService,
    private promotionService: PromotionsService,
    private router: Router
  ) {
    this.agencies = []
    this.agencySelected = new Agency()
  }

  ngOnInit() {
    this.urlLogo = routes.media + 'logo/logo-orange.png'
    this.isWorking = false

    this.resetFields ()

    this.promotionService.getPromotionByUrl (this.activatedRoute.snapshot.paramMap.get('url')).subscribe ((response: Error) => {

      if (response.status === httpStatus.success) {
        this.fields.promotion_id = (response.data  as any).id
      } else {
        this.router.navigate (['login'])
      }

    })


    this.getAgenciesSubscription = this.agenciesService.getAgencies ().subscribe ((res: Error) => {

      if (res.status === httpStatus.success) {

          this.agencies = this.agencies.concat (res.data)

          this.formAgenciesControl.setValue(this.agencies);

          this.formAgenciesControl.valueChanges.subscribe ((res) => {
              this.fields.agency_id = res.id
          }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-agency.component.ts'))

          this.filteredAgencies.next(this.agencies.slice());
          this.formFilteredAgenciesControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterAgencies ());

          this.setInitialValueAgencies();

      }
    }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-agency.component.ts'))


    this.getCountriesSubscription = this.countriesService.getCountries ().subscribe ((res: Error) => {

      if (res.status === httpStatus.success) {
        this.countries = res.data

        this.formCountriesControl.setValue(this.countries);
        this.filteredCountries.next(this.countries.slice());

        this.formFilteredCountriesControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
          () => this.filterCountries() ,
          (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agency.component.ts'));

        this.formCountriesControl.valueChanges.subscribe (res => {

          if (res instanceof Country) {
            this.fields.country_id = res.id
          }
        })
      }

    },(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agency.component.ts'))
  }

  ngAfterViewInit() {
    this.setInitialValueAgencies();
  }

  public save () {

    if (typeof this.fields.promotion_id === 'undefined') {
      this.toastr.warning ('Ha ocurrido un error. Refresca la página', 'Espera...')
      return
    }

    if (typeof this.fields.agency_id === 'undefined' || this.fields.agency_id === 0) {
      this.toastr.warning ('Tienes que seleccionar una agencia', 'Espera...')
      return
    }

    if (typeof this.fields.country_id === 'undefined' || this.fields.country_id === 0) {
      this.toastr.warning ('Tienes que seleccionar una nacionalidad', 'Espera...')
      return
    }

    this.isWorking = true

    this.visitsService.addVisit (this.fields).subscribe ((res) => {
      if (res.status === httpStatus.success) {
        this.toastr.success ('La visita se ha guardado correctamente', '¡Perfecto!')
        this.resetFields ()
        this.isWorking = false

        setTimeout (() => { window.location.reload () }, 2000)
      }
    })
  }

  private filterAgencies () {

    if (!this.agencies) {
      return
    }

    let search = this.formFilteredAgenciesControl.value;

    if (!search) {
        this.filteredAgencies.next(this.agencies.slice());
        return;
    } else {
        search = search.toLowerCase();
    }

    this.filteredAgencies.next(
        this.agencies.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
    );
}

  private filterCountries () {

    if (!this.countries) {
      return
    }

    let search = this.formFilteredCountriesControl.value;

    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
    );
  }

  protected setInitialValueAgencies() {

    this.filteredAgencies.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
        this.selectAgencies.compareWith = (a: Agency, b: Agency) => a && b && a === b;
    })
  }

  private resetFields () {
    this.fields = {
      created_at: this.datePipe.transform(new Date (), 'yyyy-MM-dd'),
      agency_id: 0,
      country_id: 0,
      name_contact: '',
      phone_contact: '',
      email_contact: '',
      budget: 0,
      preferences: '',
      observations: '',
    }
  }

}
