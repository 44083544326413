import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { popupBackground } from 'src/app/animations/popup.animation';
import { Costumer } from 'src/app/classes/Costumer';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { CostumersService } from 'src/app/services/costumers.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { takeUntil, take } from 'rxjs/Operators';
import { MatSelect } from '@angular/material';
import { Status } from 'src/app/classes/Status';
import { StorageRoom } from 'src/app/classes/StorageRoom';
import { StorageRoomsService } from 'src/app/services/storage-rooms.service';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-storage-room',
	templateUrl: './storage-room.component.html',
	styleUrls: ['./storage-room.component.scss'],
	animations: [ popupBackground ]
})
export class StorageRoomComponent implements OnInit, OnDestroy {

	@Input () garage
	@Input ('storageRoom')
	set storageRoom (storageRoom: StorageRoom) {

		this._storageRoom = storageRoom
		this.originalStorageRoom = new StorageRoom (JSON.parse (JSON.stringify (storageRoom)))
		this.originalStorageRoom.setStatus (new Status (JSON.parse (JSON.stringify (storageRoom.status))))
		this.originalStorageRoom.setCostumer (new Costumer (JSON.parse (JSON.stringify (storageRoom.costumer))))

		this.changedStorageRoom = new StorageRoom (JSON.parse (JSON.stringify (storageRoom)))
		this.changedStorageRoom.setStatus (new Status (JSON.parse (JSON.stringify (storageRoom.status))))
		this.changedStorageRoom.setCostumer (new Costumer (JSON.parse (JSON.stringify (storageRoom.costumer))))
		
		this.isWorking = false
		
		setTimeout (() => { this.initAnimations = true }, 0)
	}
	
	@Output () closeEmitter: EventEmitter<StorageRoom> = new EventEmitter<StorageRoom> ()
	_storageRoom: StorageRoom
	originalStorageRoom: StorageRoom
	changedStorageRoom: StorageRoom

	isWorking: boolean
	initAnimations: boolean = false

	showCostumerPopup: boolean
	showStatusSelector: boolean

	costumers: Costumer[]
	formCostumersControl = new FormControl()
	formFilteredCostumersControl = new FormControl ()
	filteredCostumers: ReplaySubject<Costumer[]> = new ReplaySubject<Costumer[]>(1);
	@ViewChild('singleSelect') singleSelect: MatSelect;

	protected _onDestroy = new Subject<void>();

	addStorageRoomSub: Subscription = new Subscription ()
	getCostumersSub: Subscription = new Subscription ()

	constructor ( private costumersService: CostumersService, private storageRoomsService: StorageRoomsService, private errorService: ErrorService ) {
		this.isWorking = false
		this.initAnimations = false
		this.costumers = []
		this.showCostumerPopup = false
		this.showStatusSelector = false
	}

	ngOnInit() {
		this.getCostumersSub = this.costumersService.getCostumers ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {

				this.costumers.push (new Costumer ({id: null, name: 'Desenlazar cliente'}))
				this.costumers = this.costumers.concat (res.data)

				for (let costumer of this.costumers) {
					if (costumer.getId () == this._storageRoom.getIdCostumer ()) {
						this._storageRoom.setCostumer (costumer)
						this.changedStorageRoom.setCostumer (costumer)
						this.originalStorageRoom.setCostumer (costumer)
					}
				}

				this.formCostumersControl.setValue(this.costumers)

				this.formCostumersControl.valueChanges.subscribe (res => {
					
					if (res instanceof Costumer && res.getId () != null) {

						this.changedStorageRoom.setIdCostumer (res.getId ())
						this.changedStorageRoom.setCostumer (res)
					} else {

						this.changedStorageRoom.setIdCostumer (null)
						this.changedStorageRoom.setCostumer (new Costumer ({id: null, name: ''}))
					}
				})
				
				this.filteredCostumers.next(this.costumers.slice());

				this.formFilteredCostumersControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
					this.filterCostumers ();
				});
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'storage-room.component.ts'))
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete()
		this.addStorageRoomSub.unsubscribe ()
		this.getCostumersSub.unsubscribe ()
	}

	close () {
		this._storageRoom.clone (this.originalStorageRoom)
		this.closePopup ()
	}

	save () {
		this._storageRoom.clone (this.changedStorageRoom)
		this.isWorking = true
		this.addStorageRoom ()
		//this.closeEmitter.emit (true)
	}

	addStorageRoom () {

		this.addStorageRoomSub = this.storageRoomsService.addStorageRoom (this._storageRoom).subscribe ((res: Error) => {
			
			if (res.status == httpStatus.success) {
				this.closePopup ()
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'storage-room.component.ts'))
	}

	closePopup () {
		
		this.initAnimations = false
		setTimeout (() => { this.closeEmitter.emit (this._storageRoom) }, 300)
	}

	private filterCostumers () {

		if (!this.costumers) {
			return;
		}

		let search = this.formFilteredCostumersControl.value;

		if (!search) {
			this.filteredCostumers.next(this.costumers.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		
		this.filteredCostumers.next(
			this.costumers.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
		);
	}

	openPopupCostumerEdit () {
		this.showCostumerPopup = true
	}


	closePopupCostumerCallback (flag: boolean = false) {
		this.showCostumerPopup = false
	}

	openPopupStatusSelector () {
		this.showStatusSelector = true
	}

	statusSelectorCallback (status: Status) {

		if (status.getId () === "10") {
            this.changedStorageRoom.setHasBeenCancelled (1)
        }

		this.changedStorageRoom.setStatus (status)
		this.changedStorageRoom.setIdStatus (status.getId ())
		this.showStatusSelector = false
	}

}
