import { Component, OnInit, OnDestroy } from '@angular/core';
import { CostumersService } from 'src/app/services/costumers.service';
import { Error } from 'src/app/classes/Error';
import { LocalDataSource } from 'ng2-smart-table';
import { Costumer } from 'src/app/classes/Costumer';
import { httpStatus } from 'src/app/config/httpStatus';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr';


@Component({
	selector: 'app-costumers',
	templateUrl: './costumers.component.html',
	styleUrls: ['./costumers.component.scss'],
	host: {class: 'content-costumers'}
})

export class CostumersComponent implements OnInit, OnDestroy {

	costumers: Costumer[]
	costumerSelected: Costumer
	showCostumerPopup: boolean
	showCostumerFlatsPopup: boolean
	dialog: any

	settings = {
		noDataMessage: 'Ups..! No hemos encontrado resultados',
		columns: {
		  	name: { title: 'Nombre', editable: false },
		  	surname: { title: 'Apellidos', editable: false },
		  	card_id: { title: 'DNI/NIE', editable: false },
		  	passport: { title: 'Pasaporte', editable: false },
		  	email: { title: 'E-mail', editable: false },
		  	phone: { title: 'Teléfono', editable: false },
			country: { title: 'País', editable: false },
			address: { title: 'Dirección', editable: false, width: "250px" },
		},
		actions: {
			add: false,
			edit: false,
			delete: false,
			custom: [
				{
					name: 'watch',
					title: '<i class="icon-list-preferences fas fa-home"></i>',
				},
				{
					name: 'edit',
					title: '<i class="icon-list-preferences fas fa-pen"></i>',
				},
				{
					name: 'delete',
					title: '<i class="icon-list-preferences fas fa-trash"></i>',
				},
			  
			],
		  },
	}

	source: LocalDataSource

	getCostumersSubscription: Subscription = new Subscription ()
	removeCostumerSubscription: Subscription = new Subscription ()

	constructor( private costumersService: CostumersService, private errorService: ErrorService, private toastr: ToastrModule ) {
		this.costumers = []
		this.costumerSelected = new Costumer ()
		this.source = new LocalDataSource ()
		this.showCostumerPopup = false
		this.showCostumerFlatsPopup = false

		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este cliente?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() {

		this.getCostumersSubscription = this.costumersService.getCostumers ().subscribe ((res: Error) => {
			
			if (res.status = httpStatus.success) {
				this.costumers = res.getData ()
				
				this.loadCostumersOnTable ()
			}
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'costumers.component.ts'))
	}

	ngOnDestroy () {

		this.getCostumersSubscription.unsubscribe ()
		this.removeCostumerSubscription.unsubscribe ()
	}

	loadCostumersOnTable () {

		let costumersForTable = []

		for (let costumer of this.costumers) {
			let costum = {
				id: costumer.getId (),
				name: costumer.getName (),
				surname: costumer.getSurname (),
				card_id: costumer.getCardId (),
				passport: costumer.getPassport (),
				email: costumer.getEmail (),
				phone: costumer.getPhone (),
				country: costumer.getCountry (),
				address: costumer.getAddress ()
			}
			costumersForTable.push (costum)	
		}

		this.source.load (costumersForTable)

	}

	rowActionClick (event) {

		this.costumerSelected = this.costumers.find ((costumer:Costumer) => costumer.getId () == event.data.id)

		if (event.action == 'edit') {
			this.editCostumer ()
		} else if (event.action == 'watch') {
			this.watchCostumer ()
		} else {
			this.removeCostumer ()
		}
	}

	editCostumer () {

		this.showCostumerPopup = true
	}

	closePopupCostumerCallback (flag: boolean = false) {

		this.showCostumerPopup = false

		if (!flag && this.costumers[this.costumers.length - 1].getId () <= 0) {
			this.costumers.splice (-1, 1);
		}

		this.loadCostumersOnTable ()
	}

	createCostumer () {

		this.costumers.push (new Costumer ())
		this.costumerSelected = this.costumers[this.costumers.length - 1]
		this.showCostumerPopup = true
	}

	removeCostumer () {

		this.dialog.show = true
	}

	dialogRemoveCostumerCallback (flag: boolean) {

		this.dialog.show = false

		if (flag) {
			
			this.removeCostumerSubscription = this.costumersService.removeCostumer (this.costumerSelected.getId ()).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {

					this.costumers.splice (this.costumers.indexOf (this.costumerSelected), 1)
					this.loadCostumersOnTable ()
				}
			}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'costumers.component.ts'))	
		}
	}


	watchCostumer () {
		this.showCostumerFlatsPopup = true
	}

	closePopupCostumerFlatsCallback (flag: boolean = false) {

		this.showCostumerFlatsPopup = false
	}
}
