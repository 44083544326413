import { Component, OnInit, OnDestroy, Inject, NgZone } from '@angular/core';
import { routes } from './../../../../config/routes';
import { DOCUMENT } from '@angular/platform-browser';
import { FlatService } from 'src/app/services/flat.service';
import { CommentsService } from 'src/app/services/comments.service';
import { Flat } from 'src/app/classes/Flat';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { Calendar } from 'src/app/classes/calendar/Calendar';
import { GoogleAouthService } from 'src/app/services/google-aouth.service';
import { GoogleCalendarService } from 'src/app/services/google-calendar.service';
import { EventClass } from 'src/app/classes/calendar/Event';
import { Comment } from 'src/app/classes/Comment'
import { NotificationTypes } from 'src/app/components/notification/notificationTypes';
import { Subscription } from 'rxjs';
import { AlertsService } from 'src/app/services/alerts.service';
import { UserService } from 'src/app/services/user.service';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr'
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { HistoryLogsService } from 'src/app/services/history-logs.service';

@Component({
    selector: 'app-flat-comments',
    templateUrl: './flat-comments.component.html',
    styleUrls: ['./flat-comments.component.scss'],
    host: {class: 'app-flats-comments'}
})
export class FlatCommentsComponent implements OnInit, OnDestroy {

    routes = routes
    textareaHeight: any
    flat: Flat
    comments: Comment[]
    calendars: Calendar[]
    eventEditting: EventClass
    commentEditting: Comment
    typeOfEdit: string
    showPopupEvent: boolean
    notification = { text: '', type: NotificationTypes.error }
    commentSelected: Comment
    textareaComment: string
    dialog: any

    getFlatSubscription: Subscription = new Subscription ()
    getCommentsSubscription: Subscription = new Subscription ()
    userSignInSubscription: Subscription = new Subscription ()
    createCommentSubscription: Subscription = new Subscription ()
    updateSubscription: Subscription = new Subscription ()
    update2Subscription: Subscription = new Subscription ()
    removeCommentSubscription: Subscription = new Subscription ()

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private flatService: FlatService,
        private commentsService: CommentsService,
        private googleAouthService: GoogleAouthService,
        private googleCalendarService: GoogleCalendarService,
        private zone: NgZone,
        private alertsService: AlertsService,
        private userService: UserService,
        private errorService: ErrorService,
        private toastr: ToastrService,
        private historyLogService: HistoryLogsService
    ) {

        this.textareaHeight = 16
        this.flat = new Flat ()
        this.eventEditting = new EventClass ()
        this.calendars = []
        this.showPopupEvent = false
        this.typeOfEdit = 'create'
        this.textareaComment = ""
        this.dialog = {
            show: false,
            text: '¿Seguro que quieres borrar este comentario?',
            config: {cancelText: 'Cancelar', successText: 'Eliminar'}
        }
    }

    ngOnInit() {

        this.flat = this.flatService.flat
        this.calendars = []

        this.getComments ()

        this.getFlatSubscription = this.flatService.getFlat ().subscribe ((flat: Flat) => {

            this.flat = flat
            this.getComments ()

        }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))

        this.userSignInSubscription = this.googleAouthService.userIsSignedIn ().subscribe (() => {
            
            this.googleCalendarService.getCalendarsFromApi ().then ((calendars: Calendar[]) => {
                this.zone.run (() => {
                    this.calendars = calendars
                })
            })
        }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))

        this.googleAouthService.initClient ()
    }

    ngOnDestroy () {

        this.getFlatSubscription.unsubscribe ()
        this.getCommentsSubscription.unsubscribe ()
        this.userSignInSubscription.unsubscribe ()
        this.createCommentSubscription.unsubscribe ()
        this.updateSubscription.unsubscribe ()
        this.update2Subscription.unsubscribe ()
        this.removeCommentSubscription.unsubscribe ()
    }


    getComments () {
        
        this.getCommentsSubscription = this.commentsService.getComments (this.flat.getId ()).subscribe ((res: Error) => {
            
            if (res.status == httpStatus.success) {
                this.comments = res.data
            }

        }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))
    }

    
    addComment () {

        if (this.textareaComment != "") {

            let comment = new Comment ()
            comment.setContent (this.textareaComment)
            comment.setHasAlert (false)
            comment.setIdFlat (this.flat.getId ())
            comment.setIdUser (this.userService.getUser ().getId ())
            comment.setUser (this.userService.getUser ())

            this.createCommentSubscription = this.commentsService.createComment (comment).subscribe ((res:Error) => {

                if (res.status == httpStatus.success) {
                    this.comments.unshift (res.getData ())
                    this.textareaComment = ''
                }

            }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))

            let historyLog = new HistoryLog ()
            historyLog.setIdFlat (this.flat.getId ())
            historyLog.setIdStatus (this.flat.getIdStatus ())
            historyLog.setIdUser (this.userService.getUser ().getId ())
            historyLog.setLog ('Ha añadido un comentario: ' + comment.getContent ())

            this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))
        }
    }


    eventClick (comment: Comment) {
        this.commentSelected = comment

        if (comment.getIdEvent () == "") {
            this.createEvent (comment)
        } else {
            this.editEvent (comment)
        }	
    }

    editEvent (comment: Comment) {

        this.typeOfEdit = 'edit'

        this.googleCalendarService.getEventFromApi (comment.getIdEvent (), comment.getIdCalendar ()).then ((event: EventClass) => {
            this.eventEditting = event
            this.showPopupEvent = true
        })
    }

    createEvent (comment: Comment) {

        this.typeOfEdit = 'create'
        this.eventEditting = new EventClass ()
        this.eventEditting.setSummary (comment.getContent ())
        this.showPopupEvent = true
    }

    //callback cuando un evento es añadido
    eventAdded (event: EventClass) {
        
        this.showPopupEvent = false

        if (event.getId () != null) {
            this.commentSelected.setIdEvent (event.getId ())
            this.commentSelected.setIdCalendar (event.getCalendarId ())

            this.updateSubscription = this.commentsService.update (this.commentSelected).subscribe (() => {
                
                this.toastr.success ('Hemos añadido el evento al calendario', '¡Genial!')

            }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))


            let historyLog = new HistoryLog ()
            historyLog.setIdFlat (this.flat.getId ())
            historyLog.setIdStatus (this.flat.getIdStatus ())
            historyLog.setIdUser (this.userService.getUser ().getId ())
            historyLog.setLog ('Ha añadido un evento al comentario: ' + this.commentSelected.getContent () + '. Con el título: ' + event.getSummary ())

            this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))
        }
    }

    eventUpdated (event: EventClass) {

        this.showPopupEvent = false
        this.toastr.success ('Hemos actualizado el evento', '¡Genial!')

        let historyLog = new HistoryLog ()
        historyLog.setIdFlat (this.flat.getId ())
        historyLog.setIdStatus (this.flat.getIdStatus ())
        historyLog.setIdUser (this.userService.getUser ().getId ())
        historyLog.setLog ('Ha actualizado un evento en el comentario: ' + this.commentSelected.getContent () + '. Con el título: ' + event.getSummary ())

        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))
    }

    createAlert (comment: Comment) {

        comment.setHasAlert (!comment.getHasAlert ())

        this.update2Subscription = this.commentsService.update (comment).subscribe ((res) => {
            this.alertsService.getAlertsFromApi ().subscribe ()
        })

        let historyLog = new HistoryLog ()
        historyLog.setIdFlat (this.flat.getId ())
        historyLog.setIdStatus (this.flat.getIdStatus ())
        historyLog.setIdUser (this.userService.getUser ().getId ())
        historyLog.setLog ('Ha ' + ((comment.getHasAlert ()) ? 'creado' : 'eliminado') + ' una alerta en el comentario: ' + comment.getContent ())

        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))
    }


    removeComment (comment: Comment) {
        this.commentEditting = comment
        this.dialog.show = true
    }

    dialogRemoveCommentCallback (flag: boolean = false) {

        this.dialog.show = false

        if (flag) {

            this.removeCommentSubscription = this.commentsService.removeComment (this.commentEditting.getId ()).subscribe ((res: Error) => {
                
                if (res.status == httpStatus.success) {

                    let historyLog = new HistoryLog ()
                    historyLog.setIdFlat (this.flat.getId ())
                    historyLog.setIdStatus (this.flat.getIdStatus ())
                    historyLog.setIdUser (this.userService.getUser ().getId ())
                    historyLog.setLog ('Ha eliminado el comentario: ' + this.commentEditting.getContent ())

                    this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))

                    this.alertsService.getAlertsFromApi ().subscribe () //update listado alertas
                    this.comments.splice (this.comments.indexOf (this.commentEditting), 1)
                }

            }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-comments.component.ts'))
        }
    }


    resizeTextarea (event: KeyboardEvent) { //keydown textarea

        let textarea = document.getElementById('comentario')
        
        setTimeout (() => {
            textarea.style.cssText = 'height:auto; padding:0';
            textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
        }, 0)
    }

}
