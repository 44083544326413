export class Agent {

	id: number = 0
	name: string = ""
	surname: string = ""
	email: string = ""
	phone: string = ""
	position: string = ""
	id_promoter: number = 0

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0 )
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : '' )
		this.setSurname ((typeof data != "undefined" && typeof data.surname != "undefined") ? data.surname : '' )
		this.setEmail ((typeof data != "undefined" && typeof data.email != "undefined") ? data.email : '' )
		this.setPhone ((typeof data != "undefined" && typeof data.phone != "undefined") ? data.phone : '' )
		this.setPosition ((typeof data != "undefined" && typeof data.position != "undefined") ? data.position : '' )
		this.setIdPromoter ((typeof data != "undefined" && typeof data.id_promoter != "undefined") ? data.id_promoter : 0 )
	}

	setId (id: number = 0) { this.id = id }
	setName (name: string = "") { this.name = name }
	setSurname (surname: string = "") { this.surname = surname }
	setEmail (email: string = "") { this.email = email }
	setPhone (phone: string = "") { this.phone = phone }
	setPosition (position: string = "") { this.position = position }
	setIdPromoter (id: number = 0) { this.id_promoter = id }

	getId () { return this.id }
	getName () { return this.name }
	getSurname () { return this.surname }
	getEmail () { return this.email }
	getPhone () { return this.phone }
	getPosition () { return this.position }
	getIdPromoter () { return this.id_promoter }

	toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			surname: this.getSurname (),
			email: this.getEmail (),
			phone: this.getPhone (),
			position: this.getPosition (),
			id_promoter: this.getIdPromoter (),
		}
	}

	toJson () {
		return JSON.stringify (this.toArray ())
	}

}