import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { routes } from './routes';

export const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
	url: routes.api + "Files/uploadFiles",
	maxFilesize: 400,
	method:"post",
	//acceptedFiles: '*',
	paramName:"userfile",
	autoProcessQueue: true,
}