import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router'

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	viewList = []
	viewListEmitter:EventEmitter<any> = new EventEmitter<any>();

	currentViewName = "";
	currentViewNameEmmiter: EventEmitter<any> = new EventEmitter<any>();

	constructor(private router: Router) {
		this.viewList = []
		this.getFromLocalStorage ()
	}

	setCurrentViewName (name: string = "") {
		this.currentViewName = name
		this.currentViewNameEmmiter.emit (this.currentViewName)
		this.removeFromName (name)
		this.setToLocalStorage ()
	}

	getCurrentViewName () {
		return this.currentViewNameEmmiter
	}

	addView (name: string, route: string,  object: any) {

		this.removeFromName (name);

		if (this.viewList == null) {
			this.viewList = [{name: name, route: route, data: object}]
		} else {
			this.viewList.push ({name: name, route: route, data: object})
		}

		this.setToLocalStorage ()

	}

	removeFromName (name: string = "") {

		let found = false
		let newList = []

		if (this.viewList != null) {
			for (let view of this.viewList) {

				if (view.name == name) { found = true };
	
				if (!found) {
					if (newList == null) {
						newList = [view]
					} else {
						newList.push (view)
					}
				}
			}
	
			this.viewList = newList
		}
	}

	updateView (viewName: string = "", data: any = []) {

		for (let view of this.viewList) {
			if (view.name == viewName) {
				view.data = data
			}
		}

		this.setToLocalStorage ()

	}

	getViewList () {
		return this.viewListEmitter
	}

	private setToLocalStorage () {
		localStorage.setItem ('viewList', JSON.stringify(this.viewList))
		this.viewListEmitter.emit (this.viewList)
	}

	private getFromLocalStorage () {
		this.viewList = JSON.parse(localStorage.getItem ('viewList'))
		return this.viewList
	}
}
