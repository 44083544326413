import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Error } from '../classes/Error';
import { routes } from '../config/routes';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { ParkingSpace } from '../classes/ParkingSpace';
import { Status } from '../classes/Status';
import { StorageRoom } from '../classes/StorageRoom';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class GaragesService {

	private controller: any = {}

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller.parkingSpaces = 'ParkingSpaces'
		this.controller.storageRooms = 'StorageRooms'
	}

	getUrl () {
		return {
			parkingSpaces: routes.api + this.controller.parkingSpaces + "/",
			storageRooms: routes.api + this.controller.storageRooms + "/"
		}
	}

	getParkingSpacesByIdGarage (id: number = 0) {

		let err = new Error ()
		let body = new HttpParams ().set ('id', id.toString ())
		let errorMessage = 'No hemos podido descargar las plazas de garaje. Vuelve a intentarlo'

		return this.http.post (this.getUrl ().parkingSpaces + "getParkingSpacesByIdGarage", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				let parkingSpaces = res.data
				let parkingSpacesClasses: ParkingSpace[] = []

				for (let parkingSpace of parkingSpaces) {

					let parkingSpaceClass = new ParkingSpace (parkingSpace)
					parkingSpaceClass.setStatus (new Status (parkingSpace.status))
					
					parkingSpacesClasses.push (parkingSpaceClass)
				}

				err.setData (parkingSpacesClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'garages.service')
				return err
			}
			
		}))
	}


	getStorageRoomsByIdGarage (id: number = 0) {

		let err = new Error ()
		let body = new HttpParams ().set ('id', id.toString ())
		let errorMessage = 'No hemos podido descargar los trasteros. Vuelve a intentarlo'

		return this.http.post (this.getUrl ().storageRooms + "getStorageRoomsByIdGarage", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				let storageRooms = res.data
				let storageRoomsClasses: StorageRoom[] = []

				for (let storageRoom of storageRooms) {

					let storageRoomClass = new StorageRoom (storageRoom)
					storageRoomClass.setStatus (new Status (storageRoom.status))
					
					storageRoomsClasses.push (storageRoomClass)
				}

				err.setData (storageRoomsClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'garages.service')
				return err
			}
			
		}))
	}
}
