import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { showStatusSelector } from 'src/app/animations/status-selector.animations';

@Component({
	selector: 'app-filter-list',
	templateUrl: './filter-list.component.html',
	styleUrls: ['./filter-list.component.scss'],
	animations: [ showStatusSelector ]
})
export class FilterListComponent implements OnInit {

	_items: any
	@Input ('items')
	set items (items: any) {
		this._items = items
	}

	@Output () statusSelectedEmmiter: EventEmitter<any> = new EventEmitter<any> ()

	animationFlag: boolean

	constructor() {
		this.animationFlag = false
		this.items = []
	}

	ngOnInit() {
	}

	selectItem (item) {
		this.statusSelectedEmmiter.emit (item)
	}

}
