import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Status } from 'src/app/classes/Status';
import { StatusService } from './../../services/status.service';
import { Error } from './../../classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { showStatusSelector } from 'src/app/animations/status-selector.animations';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-status-selector',
	templateUrl: './status-selector.component.html',
	styleUrls: ['./status-selector.component.scss'],
	animations: [showStatusSelector]
})
export class StatusSelectorComponent implements OnInit, OnDestroy {

	statuses: Status[]
	animationFlag: boolean
	_statusSelectedId: number
	@Input() table: string
	@Output () statusSelectedEmmiter: EventEmitter<Status> = new EventEmitter<Status> ()

	@Input ('statusSelectedId')
	set statusSelectedId (statusSelectedId: number) {
		this._statusSelectedId = statusSelectedId
		this.animationFlag = true;
	}

	getStatusesSub: Subscription = new Subscription ()

	constructor( private statusService: StatusService, private errorService: ErrorService ) {
		this.animationFlag = false
		
		if (this.table == '' || this.table == null) {
			this.table = 'statuses'
		}
	}

	ngOnInit() {

		this.getStatusesSub = this.statusService.getStatuses (this.table).subscribe ((err: Error) => {

			if (err.status == httpStatus.success) {
				this.statuses = err.data;
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'status-selector.component.ts'))
	}

	ngOnDestroy () {
		this.getStatusesSub.unsubscribe ()
	}


	selectStatus (status: Status) {
		this._statusSelectedId = status.getId ()
		this.statusSelectedEmmiter.emit (status)
		this.animationFlag = false
	}

	getStatusBackgroundStyle (status: Status) {

		if (status.getId () == 9 && this.table == 'statuses') {
			return {'background-image': 'url(assets/media/icons/close.png)', 'background-color': status.getRgb ()}
		}

		return {'background': status.getRgb()}

	}

}
