import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators';
import { Observable } from 'rxjs';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { Error } from '../classes/Error';
import { Comment } from '../classes/Comment';
import { User } from '../classes/User';
import { ErrorService } from './error.service';




@Injectable({
    providedIn: 'root'
})
export class CommentsService {

    private controller: string

    constructor( private http: HttpClient, private errorService: ErrorService ) {
        this.controller = 'Comments'
    }

    getUrl () {
        return routes.api + this.controller + "/"
    }

    getComments (idFlat: number = 0): Observable<Error> {

        if (idFlat == 0) {
            return this.getAllComments ()
        } else {
            return this.getCommentsFromFlat (idFlat)
        }

    }

    //NO SE USA
    private getAllComments (): Observable<Error> {

        let floorsClasses = []
        let err = new Error ()

        return this.http.get (this.getUrl() + "getComments").pipe (map ((res: any) => {

            return err
        }))

    }


    private getCommentsFromFlat (idFlat: number = 0): Observable<Error> {

        let commentsClasses = []
        let err = new Error ()
        let errorMessage = 'No hemos podido descargar los comentarios. Vuelve a intetntarlo'
        let body = new HttpParams ().set ('idFlat', idFlat.toString ())

        return this.http.post (this.getUrl() + "getCommentsFromFlat", body.toString (), {headers: httpHeaders}).pipe (map ((res: Error) => {

            if (res.status == httpStatus.success) {

                for (let com of res.data) {
                    let comment = new Comment (com)
                    comment.setUser (new User (com.user))
                    commentsClasses.push (comment)
                }


                err.setData (commentsClasses)

                return err;

            } else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, "Ups..!", 'comments.service')
                return err
            }
        }))
    }


    update (comment: Comment): Observable<Error> {

        let err = new Error ()
        let body = new HttpParams ().set ('comment', comment.toJson ())
        let errorMessage = "No hemos podido actualizar los comentarios. Vuelve a intentarlo"

        return this.http.post (this.getUrl() + "updateComment", body.toString (), {headers: httpHeaders}).pipe (map ((res: Error) => {

            if (res.status == httpStatus.success) {

                err.setData (comment)

                return err;
            } else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, "Ups..!", 'comments.service')
                return err
            }

        }))
    }

    createComment (comment: Comment) {

        let err = new Error ()
        let body = new HttpParams ().set ('comment', comment.toJson ())
        let errorMessage = 'No hemos podido crear el comentario. Vuelve a intentarlo'

        return this.http.post (this.getUrl() + "createComment", body.toString (), {headers: httpHeaders}).pipe (map ((res: Error) => {

            if (res.status == httpStatus.success) {

                let data: any  = res.data
                comment.setId (data.comment.id)
                comment.setCreatedAt (new Date (data.comment.created_at))

                err.setData (comment)

                return err;
            } else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, "Ups..!", 'comments.service')
                return err
            }

        }))

    }

    removeCommentsWidthEventId (eventId: string) {

        let err = new Error ()
        let body = new HttpParams ().set ('eventId', eventId)
        let errorMessage = 'No hemos podido eliminar el comentario. Vuelve a intentarlo'

        return this.http.post (this.getUrl() + "removeCommentsWidthEventId", body.toString (), {headers: httpHeaders}).pipe (map ((res: Error) => {

            if (res.status == httpStatus.success) {

                err.setData (true)
                return err;

            } else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, "Ups..!", 'comments.service')
                return err
            }
        }))	
    }


    removeComment (commentId: number) {

        let err = new Error ()
        let errorMessage = 'No hemos podido eliminar el comentario. Vuelve a intentarlo'
        let body = new HttpParams ().set ('commentId', commentId.toString ())

        return this.http.post (this.getUrl() + "removeComment", body.toString (), {headers: httpHeaders}).pipe (map ((res: Error) => {

            if (res.status == httpStatus.success) {

                err.setData (true)
                return err;

            } else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, "Ups..!", 'comments.service')
                return err
            }
        }))
    }
}
