import { Component, OnInit, Input } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';

@Component({
  selector: 'app-flat-information-map',
  templateUrl: './flat-information-map.component.html',
  styleUrls: ['./flat-information-map.component.scss']
})
export class FlatInformationMapComponent implements OnInit {

  @Input() flat: Flat

  constructor() { }

  ngOnInit() {
  }

}
