import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertClass } from 'src/app/classes/AlertClass';
import { AlertsService } from 'src/app/services/alerts.service';
import { Subscription } from 'rxjs';
import { FlatsService } from 'src/app/services/flats.service';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-alerts-list',
	templateUrl: './alerts-list.component.html',
	styleUrls: ['./alerts-list.component.scss']
})
export class AlertsListComponent implements OnInit, OnDestroy {

	alerts: AlertClass[]
	alertsSubscription: Subscription = new Subscription ()
	getAlertsSub: Subscription = new Subscription ()

	constructor(private alertsService: AlertsService, private flatsService: FlatsService, private errorService: ErrorService ) {
		this.alerts = []
	}

	ngOnInit() {

		this.alertsSubscription = this.alertsService.getAlertsFromApi ().subscribe ((alerts: AlertClass[]) => {
			this.alerts = alerts

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'alerts-list.component.ts'))

		this.getAlertsSub = this.alertsService.getAlerts ().subscribe ((alerts: AlertClass[]) => {
			this.alerts = alerts

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'alerts-list.component.ts'))

	}

	ngOnDestroy () {
		this.alertsSubscription.unsubscribe ()
		this.getAlertsSub.unsubscribe ()
	}

	public selectAlert (alert: AlertClass = new AlertClass ()) {

		this.flatsService.setAllRouteOfFlat ({
			promotion: alert.getPromotion (),
			zone: alert.getZone (),
			floor: alert.getFloor (),
			flat: alert.getFlat ()
		})

	}

}
