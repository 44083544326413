import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContactLawyer } from 'src/app/classes/ContactLawyer';
import { httpStatus } from 'src/app/config/httpStatus';
import { ContactsLawyersService } from 'src/app/services/contacts-lawyers.service';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-lawyer',
  templateUrl: './contact-lawyer.component.html',
  styleUrls: ['./contact-lawyer.component.scss']
})
export class ContactLawyerComponent implements OnInit, OnDestroy {

  @Input ('contactLawyer') contactLawyer: ContactLawyer
	@Output () removeContactLawyerEmitter: EventEmitter<ContactLawyer> = new EventEmitter<ContactLawyer> ()
	dialog: any

	removeContactLawyerSub: Subscription = new Subscription ()
	

	constructor( private contactsLawyersService: ContactsLawyersService, private errorService: ErrorService ) {
		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este agente?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() { }

	ngOnDestroy () {
		this.removeContactLawyerSub.unsubscribe ()
	}

	removeContactLawyer () {
		this.dialog.show = true
	}


	dialogRemoveContactLawyerCallback (flag: boolean) {

		this.dialog.show = false

		if (flag) {
			
			this.removeContactLawyerSub = this.contactsLawyersService.removeContactLawyer (this.contactLawyer.getId ()).subscribe ((res: any) => {
				
				if (res.status == httpStatus.success) {
					this.removeContactLawyerEmitter.emit (this.contactLawyer)
				}
			},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'contact-lawyer.component.ts'))	
		}
	}

}
