import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { HttpClientModule } from '@angular/common/http';

import { PromotionsComponent } from './promotions/promotions.component';
import { HometouchComponent } from './hometouch/hometouch.component';
import { HOMETOUCH_ROUTES } from './hometouch-routing.module';
import { ZonesComponent } from './zones/zones.component';
import { FlatsComponent } from './flats/flats.component';
import { FlatViewComponent } from './flat-view/flat-view.component';
import { FlatInformationComponent } from './flat-view/flat-information/flat-information.component';
import { FlatCommentsComponent } from './flat-view/flat-comments/flat-comments.component';
import { FlatHistoryComponent } from './flat-view/flat-history/flat-history.component';
import { FlatComponent } from './../../components/flat/flat.component';
import { MapZoneComponent } from './map-zone/map-zone.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DEFAULT_DROPZONE_CONFIG } from 'src/app/config/dropzoneSettings';
import { FlatInformationInformationComponent } from './flat-view/flat-information/flat-information-information/flat-information-information.component';
import { FlatInformationLawyerComponent } from './flat-view/flat-information/flat-information-lawyer/flat-information-lawyer.component';
import { FlatInformationAgencyComponent } from './flat-view/flat-information/flat-information-agency/flat-information-agency.component';
import { FlatInformationCostumerComponent } from './flat-view/flat-information/flat-information-costumer/flat-information-costumer.component';
import { MatSelectModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HomeboxModule } from '../homebox/homebox.module';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { GarageComponent } from './garage/garage.component'

import { TbybComponent } from './flat-view/tbyb/tbyb.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { MaterialModule } from 'src/app/modules/material.module';


@NgModule({
	declarations: [
		HometouchComponent,
		PromotionsComponent,
		ZonesComponent,
		FlatsComponent,
		FlatComponent,
		FlatViewComponent,
		FlatInformationComponent,
		FlatCommentsComponent,
		FlatHistoryComponent,
		MapZoneComponent,
		FlatInformationInformationComponent,
		FlatInformationLawyerComponent,
		FlatInformationAgencyComponent,
		FlatInformationCostumerComponent,
		TbybComponent,
		GarageComponent,
	],

	imports: [
		CommonModule,
		RouterModule.forChild (HOMETOUCH_ROUTES),
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		AngularSvgIconModule,
		SharedModule,
		ComponentsModule,
		DropzoneModule,
		MatSelectModule,
		MatIconModule,
		MatFormFieldModule,
		NgxMatSelectSearchModule,
		ReactiveFormsModule,
		SelectDropDownModule,
		HomeboxModule,
		FullCalendarModule,
		MaterialModule
	],

	exports: [
		HometouchComponent,
		PromotionsComponent,
		FlatComponent,
		RouterModule
	],

	providers: [
		{
			provide: DROPZONE_CONFIG,
			useValue: DEFAULT_DROPZONE_CONFIG
		  }
	]

	

})
export class HometouchModule { }
