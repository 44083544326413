import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators'
import { Error } from './../classes/Error'
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { Zone } from '../classes/Zone';
import { Floor } from '../classes/Floor';
import { Status } from '../classes/Status';
import { Promoter } from '../classes/Promoter';
import { Flat } from '../classes/Flat';
import { Garage } from '../classes/Garage';
import { ErrorService } from './error.service';
import { ParkingSpace } from '../classes/ParkingSpace';
import { StorageRoom } from '../classes/StorageRoom';
import { Costumer } from '../classes/Costumer';


@Injectable({
	providedIn: 'root'
})
export class ZonesService {

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	private controller = 'Zones';

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getZones (idPromotion: number = 0) {

		let zonesClasses = []
		let err = new Error ()
		let body = new HttpParams ().set ('idPromotion', idPromotion.toString ())
		let errorMessage = 'No hemos podido descargar las zonas. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "getZonesByPromotionId", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				let zones = res.data

				for (let zone of zones) {
					zone.created_at = new Date (zone.created_at)
					zone.sold = (zone.sold == '1') ? true : false

					let garages: Garage[] = []

					for (let garage of zone.garages) {
						garages.push(new Garage (garage))
					}

					let zoneClass = new Zone (zone)
					zoneClass.setGarages (garages)

					zonesClasses.push (zoneClass)

				}

				err.setData (zonesClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'zones.service')
				return err
			}
			
		}))
	}


	getInfoForMap (idZone: number) {
		
		let floor: Floor
		let flats: Flat[] = []
		let err = new Error ()
		let body = new HttpParams ().set ('idZone', idZone.toString ())
		let errorMessage = 'No hemos podido descargar las zonas. Vuelve a intentarlo'


		return this.http.post (this.getUrl () + "getInfoForMap", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				let data: any = res.data

				floor = new Floor (data.floor)

				for (let flat of data.flats) {

					flat.created_at = new Date (flat.created_at)
					flat.updated_at = new Date (flat.updated_at)
					flat.reserved_at = new Date (flat.reserved_at)
					flat.status = new Status (flat.status)
					flat.promoter = new Promoter (flat.promoter)

					let parkingSpacesClasses = []
						let storageRoomsClasses = []

					for (let parkingSpace of flat.costumer.parkingSpaces) { parkingSpacesClasses.push (new ParkingSpace (parkingSpace)) }
					for (let storageRoom of flat.costumer.storageRooms) { storageRoomsClasses.push (new StorageRoom (storageRoom)) } 

					flat.costumer.parkingSpaces = parkingSpacesClasses
					flat.costumer.storageRooms = storageRoomsClasses

					flat.costumer = new Costumer (flat.costumer)
						
					flats.push (new Flat (flat))

				}

				err.setData ({floor: floor, flats: flats})
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'zones.service')
				return err
			}
			
		}))
	}
}
