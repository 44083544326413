import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Flat } from 'src/app/classes/Flat';
import { Status } from 'src/app/classes/Status';
import { FlatService } from 'src/app/services/flat.service';
import { Lawyer } from 'src/app/classes/Lawyer';
import { Country } from 'src/app/classes/country';
import { Agency } from 'src/app/classes/Agency';
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { HistoryLogsService } from 'src/app/services/history-logs.service';
import { UserService } from 'src/app/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';
import { CommentsService } from 'src/app/services/comments.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';


@Component({
    selector: 'app-flat-view',
    templateUrl: './flat-view.component.html',
    styleUrls: ['./flat-view.component.scss'],
    host: {class: 'flat-view'},
    
})
export class FlatViewComponent implements OnInit {

    flat: Flat
    menu = []
    showStatusSelector: boolean
    showHomebox: boolean
    numOfComments: number

    constructor (
        private storage: StorageService,
        @Inject(LOCALE_ID) private locale: string,
        private flatService: FlatService,
        private errorService: ErrorService,
        private userService: UserService,
        private historyLogService: HistoryLogsService,
        private commentsService: CommentsService
    ) {

        this.showStatusSelector = false
        this.showHomebox = false
        this.numOfComments = 0
    }

    ngOnInit() {

        this.storage.setCurrentViewName ('flat-view')

        for (let view of this.storage.viewList) {
            if (view.name == 'flat') {
                
                view.data.created_at = new Date (view.data.created_at)
                view.data.updated_at = new Date (view.data.updated_at)
                view.data.reserved_at = new Date (view.data.reserved_at)
                view.data.status = new Status (view.data.status)
                view.data.promoter = new Agency (view.data.promoter)

                let lawyer = new Lawyer (view.data.lawyer);
                lawyer.setCountry (new Country (view.data.lawyer.country))
                view.data.lawyer = lawyer

                this.flat = new Flat (view.data)

                this.flatService.setFlat (this.flat, false)
            }
        }

        this.menu = [
            {imagePath: "check-mark.png", title: 'Información', url: 'information'},
            {imagePath: "chat.png", title: 'Comentarios', url: 'comments'},
            {imagePath: "clock.png", title: 'Historial', url: 'history'},
        ];

        this.flatService.getFlat ().subscribe ((flat: Flat) => {
            this.flat = flat

            this.commentsService.getComments (this.flat.getId ()).subscribe ((res: Error) => {

                if (res.status == httpStatus.success) {

                    this.numOfComments = res.data.length

                }
            })
        })

        
    }

    setPrice (event) {

        let priceNotFormated = parseInt (this.getValFromEvent (event).replace ("€", '').toString ())
        if (isNaN (priceNotFormated)) priceNotFormated = 0
        this.flat.setPrice (priceNotFormated)
        this.flatService.setFlat (this.flat)

        let historyLog = new HistoryLog ()
        historyLog.setIdFlat (this.flat.getId ())
        historyLog.setIdStatus (this.flat.getIdStatus ())
        historyLog.setIdUser (this.userService.getUser ().getId ())
        historyLog.setLog ('Ha actualizado el precio: ' + this.flatService.flat.getPrice ())

        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
    }

    setName (event) {
        this.flat.setName (this.getValFromEvent(event))
        this.flatService.setFlat (this.flat)

        let historyLog = new HistoryLog ()
        historyLog.setIdFlat (this.flat.getId ())
        historyLog.setIdStatus (this.flat.getIdStatus ())
        historyLog.setIdUser (this.userService.getUser ().getId ())
        historyLog.setLog ('Ha actualizado el nombre: ' + this.flatService.flat.getName ())

        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
    }

    setStatus (status: Status) {

        if (status.getId () == 9) {
            this.flat.setHasBeenCancelled (true)
        }

        this.flat.setStatus (status)
        this.flat.setIdStatus (status.getId ())
        this.flatService.setFlat (this.flat)
        this.showStatusSelector = false

        let historyLog = new HistoryLog ()
        historyLog.setIdFlat (this.flat.getId ())
        historyLog.setIdStatus (this.flat.getIdStatus ())
        historyLog.setIdUser (this.userService.getUser ().getId ())
        historyLog.setLog ('Ha actualizado el estado: ' + this.flatService.flat.getStatus ().getName ())

        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
    }

    getValFromEvent (event) {
        return event.srcElement.value;
    }

    getBackgroundStatusStyle (flat: Flat) {

        if (flat.getHasBeenCancelled ()) {
            return {'background-image': 'url(assets/media/icons/close.png)', 'box-shadow': 'inset 0px 0px 0px 8px' + flat.getStatus ().getRgb ()}
        }

        return {'box-shadow': 'inset 0px 0px 0px 8px' + flat.getStatus ().getRgb ()}
    }

    openHomebox () {
        this.showHomebox = true
    }

    closeHomebox (event) {
        this.showHomebox = false
    }
}
