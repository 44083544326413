import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventPopupComponent } from './event-popup/event-popup.component';
import { MaterialModule } from '../modules/material.module';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NotificationComponent } from './notification/notification.component';
import { EventToolsComponent } from './event-tools/event-tools.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CostumerComponent } from './costumer/costumer.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { AlertsListComponent } from './alerts-list/alerts-list.component';
import { LawyerComponent } from './lawyer/lawyer.component';
import { AgencyComponent } from './agency/agency.component';
import { AgentComponent } from './agent/agent.component';
import { ContactLawyerComponent } from './contact-lawyer/contact-lawyer.component';
import { GaragesSelectorComponent } from './garages-selector/garages-selector.component';
import { ParkingSpaceComponent } from './parking-space/parking-space.component';
import { StatusSelectorComponent } from './status-selector/status-selector.component';
import { CommercialComponent } from './commercial/commercial.component';

import { FullCalendarModule } from 'ng-fullcalendar';
import { TbybSelectorComponent } from './tbyb-selector/tbyb-selector.component';
import { StorageRoomComponent } from './storage-room/storage-room.component';
import { AgenciesSelectorComponent } from './agencies-selector/agencies-selector.component';
import { FlatInformationMapComponent } from './flat-information-map/flat-information-map.component';
import { CostumerFlatsComponent } from './costumer-flats/costumer-flats.component';
import { AgencyFlatsComponent } from './agency-flats/agency-flats.component';



@NgModule({
  declarations: [
    EventPopupComponent,
    NotificationComponent,
    EventToolsComponent,
    ConfirmDialogComponent,
    CostumerComponent,
    FilterListComponent,
    AlertsListComponent,
    LawyerComponent,
    AgencyComponent,
    AgentComponent,
    ContactLawyerComponent,
    GaragesSelectorComponent,
    ParkingSpaceComponent,
    StatusSelectorComponent,
    CommercialComponent,
    TbybSelectorComponent,
    StorageRoomComponent,
    AgenciesSelectorComponent,
    FlatInformationMapComponent,
    CostumerFlatsComponent,
    AgencyFlatsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AmazingTimePickerModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FullCalendarModule,
  ],

  exports: [
    EventPopupComponent,
    NotificationComponent,
    EventToolsComponent,
    ConfirmDialogComponent,
    CostumerComponent,
    FilterListComponent,
    AlertsListComponent,
    LawyerComponent,
    AgencyComponent,
    AgentComponent,
    ContactLawyerComponent,
    GaragesSelectorComponent,
    ParkingSpaceComponent,
    StatusSelectorComponent,
    CommercialComponent,
    TbybSelectorComponent,
    StorageRoomComponent,
    AgenciesSelectorComponent,
    FlatInformationMapComponent,
    CostumerFlatsComponent,
    AgencyFlatsComponent
  ]
})
export class ComponentsModule { }
