import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Garage } from 'src/app/classes/Garage';

@Component({
	selector: 'app-garages-selector',
	templateUrl: './garages-selector.component.html',
	styleUrls: ['./garages-selector.component.scss']
})
export class GaragesSelectorComponent implements OnInit {

	@Input() garages: Garage[]
	@Output() garageEmitter: EventEmitter<any> = new EventEmitter<any>()

	constructor() {
		
	}

	ngOnInit() {
	}

	selectGarage (garage: Garage) {
		this.emitEventGarage (garage)
	}

	closePopup () {
		this.emitEventGarage (null)
	}

	emitEventGarage (data: any) {
		this.garageEmitter.emit (data)
	}

}
