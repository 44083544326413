import { Status } from './Status';
import { User } from './User';

export class HistoryLog {

	id: number = 0
	id_flat: number = 0
	id_user: number = 0
	id_status: number = 0
	log: string = ""
	created_at: Date = new Date ()
	status: Status
	showLog: boolean
	user: User

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setIdFlat ((typeof data != "undefined" && typeof data.id_lat != "undefined") ? data.id_lat : 0)
		this.setIdUser ((typeof data != "undefined" && typeof data.id_user != "undefined") ? data.id_user : 0)
		this.setIdStatus ((typeof data != "undefined" && typeof data.id_status != "undefined") ? data.id_status : 0)
		this.setLog ((typeof data != "undefined" && typeof data.log != "undefined") ? data.log : "")
		this.setCreatedAt ((typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : new Date ())
		this.setStatus ((typeof data != "undefined" && typeof data.status != "undefined") ? data.status : new Status ())
		this.setUser ((typeof data != "undefined" && typeof data.user != "undefined") ? data.user : new User ())
		this.setShowLog (false)
	}

	public setId (id: number = 0) { this.id = id }
	public setIdFlat (id: number = 0) { this.id_flat = id }
	public setIdUser (id: number = 0) { this.id_user = id }
	public setIdStatus (id: number = 0) { this.id_status = id }
	public setLog (log: string = "") { this.log = log }
	public setCreatedAt (date: Date = new Date ()) { this.created_at = date }
	public setStatus (status: Status = new Status ()) { this.status = status }
	public setUser (user: User = new User ()) { this.user = user }
	public setShowLog (flag: boolean = false) { this.showLog = flag }

	public getId () { return this.id }
	public getIdFlat () { return this.id_flat }
	public getIdUser () { return this.id_user }
	public getIdStatus () { return this.id_status }
	public getLog () { return this.log }
	public getCreatedAt () { return this.created_at }
	public getStatus () { return this.status }
	public getUser () { return this.user }
	public getShowLog () { return this.showLog }

	public humanizeDate () {
		return this.getCreatedAt ()
	}

	public toArray () {
		return {
			id: this.getId (),
			id_flat: this.getIdFlat (),
			id_user: this.getIdUser (),
			id_status: this.getIdStatus (),
			log: this.getLog (),
			created_at: this.getCreatedAt ()
		}
	}

	public toJson () {
		return JSON.stringify (this.toArray ())
	}

}