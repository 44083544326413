import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const showBreadcrumb: AnimationTriggerMetadata = trigger('showBreadcrumb', [
	state ('false', style({
		opacity: 0,
		marginTop: "-100px"
	})),

	state ('true', style({
		opacity: 1,
		marginTop: "0"
	})),

	transition ('true => false', animate('1s ease-in-out')),
	transition ('false => true', animate('1s ease-in-out'))
])

export const showLogo: AnimationTriggerMetadata = trigger('showLogo', [
	state ('false', style({
		opacity: 0,
	})),

	state ('true', style({
		opacity: 1,
	})),

	transition ('true => false', animate('1s ease-in-out')),
	transition ('false => true', animate('1s ease-in-out'))
])