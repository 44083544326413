// export const googleCalendarSettings = {

// 		"apiKey": 'AIzaSyCpCINV9XCJTqAM_Q5WICfy007eHdA0mes',
// 		"client_id":"974674364408-kpqpqp71h62t7l6pktumhrhit5vjvulo.apps.googleusercontent.com",
// 		"project_id":"hometouch-230908",
// 		"auth_uri":"https://accounts.google.com/o/oauth2/auth",
// 		"token_uri":"https://oauth2.googleapis.com/token",
// 		"auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs",
// 		"client_secret":"I6EO7JsUJwAq1z707oIQQZkW",
// 		"redirect_uris":["urn:ietf:wg:oauth:2.0:oob","http://localhost"],
// 		"discovery_docs": ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
// 		'scope': "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
	
// }

export const googleCalendarSettings = {

	"apiKey": 'AIzaSyDH3BVI7KUrRUmzH0LkK9KaPErXjOo-_Fg',
	"client_id":"156845462206-7cmhcbqn6s4m8i8flrjsvi3kc4m142t5.apps.googleusercontent.com",
	"project_id":"hometouch-235611",
	"auth_uri":"https://accounts.google.com/o/oauth2/auth",
	"token_uri":"https://oauth2.googleapis.com/token",
	"auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs",
	"client_secret":"GvJEzxZrggWBTMQH0pdLgmZA",
	"redirect_uris":["urn:ietf:wg:oauth:2.0:oob","http://localhost","http://localhost:4200","http://dicopgroup.com","http://hometouch.dicopgroup.com","http://127.0.0.1","http://127.0.0.1:4200"],
	"discovery_docs": ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
	'scope': "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"

}

export const calendarsIdsEditables = ['hometouch.urmosa@gmail.com', 'op8omttlo6itoh04kekn77f4f0@group.calendar.google.com', '0hdbrpms76o3rpnaqp8pbb1sb4@group.calendar.google.com']
