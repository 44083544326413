import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../user.service';

@Injectable({
	providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

	constructor (private userSirvice: UserService, private router: Router) {}

	canActivate(): boolean {

		if (this.userSirvice.isLogged ()) {
			return true
		} else {
			this.router.navigate (['login'])
			return false
		}	
	}
}
