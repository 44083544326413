import { routes } from '../config/routes';

export class Garage {

	id: number = 0
	id_zone: number = 0
	name: string = ""
	order: number = 0
	plan_path: string = ""

	constructor (data: any = {}) {
		this.setId ((typeof data.id != "undefined") ? data.id : 0)
		this.setIdZone ((typeof data.id_zone != "undefined") ? data.id_zone : 0)
		this.setName ((typeof data.name != "undefined") ? data.name : "")
		this.setOrder ((typeof data.order != "undefined") ? data.order : 0)
		this.setPlanPath ((typeof data.plan_path != "undefined") ? data.plan_path : "")
	}

	setId (id: number = 0) { this.id = id }
	setIdZone (id: number = 0) { this.id_zone = id }
	setName (name: string = "") { this.name = name }
	setOrder (order: number = 0) { this.order = order }
	setPlanPath (plan: string = "") { this.plan_path = plan }
	
	getId () { return this.id }
	getIdZone () { return this.id_zone }
	getName () { return this.name }
	getOrder () { return this.order }
	getPlanPath () { return routes.media + "garages/" + this.plan_path }

}