import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const popupBackground: AnimationTriggerMetadata = trigger('popupBackground', [
	
	state ('false', style({opacity: 0})),
	state ('true', style({opacity: 1})),

	//hide
	transition ('true => false', animate('.3s ease-in-out', keyframes ([
		style ({opacity: 1, offset: 0}),
		style ({opacity: 0, offset: 1}),
	]))),

	//show
	transition ('false => true', animate('.3s ease-in-out', keyframes ([
		style ({opacity: 0, offset: 0}),
		style ({opacity: 1, offset: 1}),
	])))
])