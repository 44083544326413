import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData, DatePipe } from '@angular/common';
registerLocaleData(localeEs);

import { APP_ROUTES } from './app-routing.module';

import { PagesModule } from './pages/pages.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedModule } from './shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { VisitsFormComponent } from './visits-form/visits-form.component';
import { MaterialModule } from './modules/material.module';
import { MatSelectModule, MatIconModule, MatFormFieldModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';




@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        VisitsFormComponent,
     ],

    imports: [
        BrowserModule,
        APP_ROUTES,
        PagesModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        MaterialModule,
        MatSelectModule,
        MatIconModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
    ],

    providers: [
        { provide: LOCALE_ID, useValue: 'es' },
        LoginGuardGuard,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
