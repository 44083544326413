import { Component, OnInit, OnDestroy } from '@angular/core';
import { SourceTreeService } from '../../services/source-tree.service';
import { Folder } from 'src/app/classes/homebox/Folder';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-files-tree',
	templateUrl: './files-tree.component.html',
	styleUrls: ['./files-tree.component.scss'],
})
export class FilesTreeComponent implements OnInit, OnDestroy {

	sourceTree: Folder
	getSourceTreeSub: Subscription = new Subscription ()

	constructor ( private sourceTreeService: SourceTreeService, private errorService: ErrorService ) {
		this.sourceTree = new Folder ()
	}

	ngOnInit() {

		this.getSourceTreeSub = this.sourceTreeService.getSourceTree ().subscribe ((sourceTree: any) => {

			this.sourceTree = sourceTree
			
		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'files-tree.component.ts'))

	}

	ngOnDestroy () {
		this.getSourceTreeSub.unsubscribe ()
	}

}
