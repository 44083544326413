import { EventClass } from './Event';

export interface CalendarInterface {

	setId (id: string): void
	setBackgroundColor (bg: string): void
	setForegroundColor (color: string): void
	setSummary (summary: string): void
	setRole (role: string): void

	getId (): string
	getBackgroundColor (): string
	getForegroundColor (): string
	getSummary (): string
	getRole (): string

}

export class Calendar implements CalendarInterface {

	id: string
	backgroundColor: string
	foregroundColor: string
	summary: string
	events: EventClass[]
	role: string

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : "")
		this.setBackgroundColor ((typeof data != "undefined" && typeof data.backgroundColor != "undefined") ? data.backgroundColor : "#000")
		this.setForegroundColor ((typeof data != "undefined" && typeof data.foregroundColor != "undefined") ? data.foregroundColor : "#fff")
		this.setSummary ((typeof data != "undefined" && typeof data.summary != "undefined") ? data.summary : "")
		this.setEvents ((typeof data != "undefined" && typeof data.events != "undefined" && data.events.length > 0) ? data.events : [])
		this.setRole ((typeof data != "undefined" && typeof data.accessRole != "undefined") ? data.accessRole : "")
	}

	setId (id = "") { this.id = id }
	setBackgroundColor (bg = "#000") { this.backgroundColor = bg }
	setForegroundColor (color = '#fff') { this.foregroundColor = color }
	setSummary (summary = "") { this.summary = summary }
	setEvents (events: EventClass[]) { this.events = events }
	setRole (role: string) { this.role = role }

	getId () { return this.id }
	getBackgroundColor () { return this.backgroundColor }
	getForegroundColor () { return this.foregroundColor }
	getSummary () { return this.summary }
	getEvents () { return this.events }
	getRole () { return this.role }

	addEvent (event: EventClass) {
		this.events.push (event)
	}

	getEventById (id: string = '') {
		return this.getEvents (). find ((event: EventClass) => event.getId () == id)
	}
	

	getEventsForFullCalendar () {

		let eventsFullCalendar = []

		for (let event of this.getEvents ()) {

			let ev = {
				title: event.getSummary (),
				start: event.getStart (),
				end: event.getEnd (),
				color: this.getBackgroundColor (),
				textColor: "#fff",
				editable: (this.getRole() == 'reader') ? false : true,
				id: event.getId (),
				calendarId: event.getCalendarId ()
			}

			eventsFullCalendar.push (ev)
		}

		return eventsFullCalendar

	}

	//reemplaza un evento por el mismo actualizado
	replaceEvent (event: EventClass) {

		let events = this.getEvents ()
		let indexOldEvent: number = events.findIndex ((ev: EventClass) => ev.getId () == event.getId ())
			
		if (indexOldEvent < 0) {
			return
		}

		events[indexOldEvent] = event
		this.setEvents (events)
	}

	removeEvent (event:EventClass) {

		let events = this.getEvents ()
		let eventIndex: number = events.findIndex ((ev: EventClass) => ev.getId () == event.getId ())

		if (eventIndex < 0) {
			return
		}

		this.events.splice (eventIndex, 1)
	}

	


	

}