import { User } from './User';

export class Comment {

	private id: number = 0
	private id_user: number = 0
	private id_flat: number = 0
	private id_event: string = ""
	private id_calendar: string = ""
	private has_alert: boolean = false
	private content: string = ""
	private created_at: Date
	private user: User

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setIdUser ((typeof data != "undefined" && typeof data.id_user != "undefined") ? data.id_user : 0)
		this.setIdFlat ((typeof data != "undefined" && typeof data.id_flat != "undefined") ? data.id_flat : 0)
		this.setIdEvent ((typeof data != "undefined" && typeof data.id_event != "undefined" && data.id_event != null) ? data.id_event : "")
		this.setIdCalendar ((typeof data != "undefined" && typeof data.id_calendar != "undefined" && data.id_calendar != null) ? data.id_calendar : "")
		this.setHasAlert ((typeof data != "undefined" && typeof data.has_alert != "undefined") ? data.has_alert : false)
		this.setContent ((typeof data != "undefined" && typeof data.content != "undefined") ? data.content : "")
		this.setCreatedAt ((typeof data != "undefined" && typeof data.created_at != "undefined") ? new Date(data.created_at) : new Date ())
		this.setUser ((typeof data != "undefined" && typeof data.user != "undefined") ? data.user : new User ())
	}

	getId (): number { return this.id }
	getIdUser (): number { return this.id_user }
	getIdFlat (): number { return this.id_flat }
	getIdEvent (): string { return this.id_event }
	getIdCalendar (): string { return this.id_calendar }
	getHasAlert (): boolean { return this.has_alert }
	getContent (): string { return this.content }
	getCreatedAt (): Date { return this.created_at }
	getUser (): User { return this.user }

	setId (id: number): void { this.id = id }
	setIdUser (id: number): void { this.id_user = id }
	setIdFlat (id: number): void { this.id_flat = id }
	setIdEvent (id: string): void { this.id_event = id }
	setIdCalendar (id: string): void { this.id_calendar = id }
	setHasAlert (flag: boolean): void { this.has_alert = flag }
	setContent (content: string): void { this.content = content }
	setCreatedAt (date: Date): void { this.created_at = date }
	setUser (user: User): void { this.user = user }

	toJson () {
		return JSON.stringify (this.toArray ())
	}

	toArray () {
		return {
			id: this.getId (),
			id_user: this.getIdUser (),
			id_flat: this.getIdFlat (),
			id_event: this.getIdEvent (),
			id_calendar: this.getIdCalendar (),
			has_alert: (this.getHasAlert ()) ? 1: 0,
			content: this.getContent (),
			created_at: this.getCreatedAt ()
		}
	}
 

}