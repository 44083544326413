import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {popupBackground} from 'src/app/animations/popup.animation';
import {Commercial} from 'src/app/classes/commercial';
import {CommercialsService} from 'src/app/services/commercials.service';
import {Error} from 'src/app/classes/Error';
import {httpStatus} from 'src/app/config/httpStatus';
import {Options} from 'fullcalendar';
import {EventClass} from 'src/app/classes/calendar/Event';
import {Subscription} from 'rxjs';
import {CalendarComponent} from 'ng-fullcalendar';
import {Calendar} from 'src/app/classes/calendar/Calendar';
import {GoogleCalendarService} from 'src/app/services/google-calendar.service';
import {GoogleAouthService} from 'src/app/services/google-aouth.service';
import * as moment from 'moment';
import {NotificationTypes} from 'src/app/components/notification/notificationTypes';
import {ErrorService} from 'src/app/services/error.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss'],
  animations: [popupBackground]
})
export class CommercialComponent implements OnInit, OnDestroy {

  isWorking: boolean;
  initAnimations: boolean = false;

  private calendarId: string;
  calendarOptions: Options = {};
  calendar: Calendar;
  eventEditting: EventClass;
  dialog: any;
  @ViewChild('ucCalendar') ucCalendar: CalendarComponent;
  notification = {text: '', type: NotificationTypes.error};

  @Input('commercial')
  set commercial(commercial: Commercial) {
    this._commercial = commercial;
    this.originalCommercial = new Commercial(JSON.parse(JSON.stringify(commercial)));
    this.changedCommercial = new Commercial(JSON.parse(JSON.stringify(commercial)));
    this.isWorking = false;

    setTimeout(() => {
      this.initAnimations = true;
    }, 0);


    if (this._commercial.getId() > 0) {
      this.initCalendar();
    }
  }

  @Output() closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  _commercial: Commercial;
  originalCommercial: Commercial;
  changedCommercial: Commercial;

  userSignInSubscription: Subscription = new Subscription();
  addCommercialSubscription: Subscription = new Subscription();
  getEventsSubscription: Subscription = new Subscription();
  createEventSubscription: Subscription = new Subscription();
  removeEventSubscription: Subscription = new Subscription();

  constructor(
    private commercialService: CommercialsService,
    private googleCalendarService: GoogleCalendarService,
    private googleAouthService: GoogleAouthService,
    private zone: NgZone,
    private errorService: ErrorService
  ) {
    this.isWorking = false;
    this.initAnimations = false;
    this.calendarId = '9kh43u66grkq208pcq5tjqq3ik@group.calendar.google.com';
    this.dialog = {
      show: false,
      text: '¿Seguro que quieres borrar este evento?',
      config: {cancelText: 'Cancelar', successText: 'Eliminar'}
    };
  }

  ngOnInit() {

    this.userSignInSubscription = this.googleAouthService.userIsSignedIn().subscribe(() => {
      if (this._commercial.getId() > 0) {
        this.afterSignIn();
      }
    });
    this.googleAouthService.initClient();

  }

  ngOnDestroy() {

    this.userSignInSubscription.unsubscribe();
    this.addCommercialSubscription.unsubscribe();
    this.getEventsSubscription.unsubscribe();
    this.createEventSubscription.unsubscribe();
    this.removeEventSubscription.unsubscribe();
  }

  close() {

    this._commercial.clone(this.originalCommercial);
    this.closePopup(false);
  }

  save() {

    this._commercial.clone(this.changedCommercial);
    this.isWorking = true;
    this.addCommercial();
  }

  addCommercial() {

    this.addCommercialSubscription = this.commercialService.addCommercial(this._commercial).subscribe((res: Error) => {

      let data: any = res.data;

      if (typeof data.id != 'undefined' && data.id != null && data.id > 0) {
        this._commercial.setId(data.id);
      }

      if (res.status == httpStatus.success) {
        this.closePopup(true);
      }
    }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'commercial.component.ts'));
  }

  closePopup(flag: boolean = false) {

    this.initAnimations = false;
    setTimeout(() => {
      this.closeEmitter.emit(flag);
    }, 300);
  }

  afterSignIn() {

    this.googleCalendarService.getCalendarsFromApi().then((calendars: Calendar[]) => {
      this.zone.run(() => {

        for (let calendar of calendars) {
          if (calendar.id == this.calendarId) {
            this.calendar = calendar;
          }
        }

        this.googleCalendarService.getEventsFromApi(this.calendar.getId()).then((events: EventClass[]) => {

          this.getEventsSubscription = this.commercialService.getEvents(this._commercial).subscribe((err: Error) => {

            let eventsOfFlat: EventClass[] = [];

            if (err.status == httpStatus.success) {

              for (let eventDb of err.data) {
                for (let event of events) {
                  if (event.getId() == eventDb.id_event) {
                    eventsOfFlat.push(event);
                  }
                }
              }

              this.calendar.setEvents(eventsOfFlat);
              this.fillCalendar();

            }
          }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'commercial.component.ts'));
        });
      });
    });
  }


  fillCalendar() {

    let events = this.calendar.getEventsForFullCalendar();
    this.ucCalendar.renderEvents(events);
  }


  // ---------- EVENTS FROM FULL CALENDAR

  eventClick(data) {

    this.eventEditting = this.calendar.getEventById(data.detail.event.id);
    this.dialog.show = true;
  }


  dayClick(data) {

    if (this._commercial.getId() <= 0) {
      this.notification.text = 'Primero tienes que crear un comercial';
      this.notification.type = NotificationTypes.error;
      return;
    }

    this.addNewEvent(data.detail.date);
  }

  // ---------- END EVENTS FROM FULL CALENDAR

  addNewEvent(dateStart: moment.Moment = moment()) {

    let event = new EventClass();
    event.setSummary('Libranza: ' + this.changedCommercial.getName());
    let date = moment(dateStart.format());

    date.hours(0).minutes(0);

    event.setStart(date);
    event.setEnd(moment(date.format()).add(23, 'hours').add(59, 'minutes'));
    event.setCalendarId(this.calendarId);

    this.googleCalendarService.createEventOnApi(event).then((newEvent: EventClass) => {

      this.createEventSubscription = this.commercialService.createEvent(newEvent, this.changedCommercial).subscribe((err: Error) => {

        if (err.status == httpStatus.success) {
          this.calendar.addEvent(event);
          this.fillCalendar();
        }
      }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'commercial.component.ts'));
    });
  }

  dialogRemoveEventCallback(flag: boolean = false) {

    this.dialog.show = false;

    if (flag) {

      this.googleCalendarService.removeEventOnApi(this.eventEditting).then((event: EventClass) => {

        this.removeEventSubscription = this.commercialService.removeEvent(this.eventEditting).subscribe(() => {

          this.calendar.removeEvent(this.eventEditting);
          this.fillCalendar();

        }, (error: HttpErrorResponse) => this.errorService.notifyMainError(error, 'commercial.component.ts'));
      });
    }
  }

  initCalendar() {

    this.calendarOptions = {
      height: 100,
      editable: false,
      eventLimit: false,

      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month, listMonth',
      },

      defaultView: 'month',
      //fixedWeekCount: false,

      buttonText: {
        today: 'Hoy',
        month: 'Mes',
        //list: 'Próximos eventos'
      },

      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      firstDay: 1,
      //columnHeader: false,
      titleFormat: 'MMMM YYYY',

      events: []
    };
  }
}
