import { routes } from './../config/routes'

export interface PromotionInterface {
	id: number
	url: string
	name: string
	logo_path: string
	active: boolean
	created_at: Date
	sold: boolean
	order: number
	showAnimation: boolean

	setId (): void
	setUrl (): void
	setName (): void
	setLogoPath (): void
	setActive (): void
	setCtreatedAt (): void
	setSold (): void
	setOrder (): void
	setShowAnimation (): void

	getId (): number
	getUrl (): string
	getName (): string
	getLogoPath (): string
	getActive (): boolean
	getCreatedAt (): Date
	getSold (): boolean
	getOrder (): number
	getShowAnimation (): boolean
}

export class Promotion implements PromotionInterface {

	id = 0
	name = ""
	url = ''
	logo_path = ""
	active = false
	created_at = new Date ()
	sold = false
	order = 0
	showAnimation = false

	constructor (data?: PromotionInterface) {
		this.id = (typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0
		this.name = (typeof data != "undefined" && typeof data.name != "undefined") ? data.name : ''
		this.url = (typeof data != "undefined" && typeof data.url != "undefined") ? data.url : ''
		this.logo_path = (typeof data != "undefined" && typeof data.logo_path != "undefined") ? data.logo_path : ''
		this.active = (typeof data != "undefined" && typeof data.active != "undefined") ? data.active : false
		this.created_at = (typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : new Date ()
		this.sold = (typeof data != "undefined" && typeof data.sold != "undefined") ? data.sold : false
		this.order = (typeof data != "undefined" && typeof data.order != "undefined") ? data.order : 0
	}

	setId (id: number = 0) { this.id = id }
	setName (name: string = "") { this.name = name }
	setUrl (url: string = "") { this.url = url }
	setLogoPath (logo: string = "") { this.logo_path = logo }
	setActive (active: boolean = false) { this.active = active }
	setCtreatedAt (date: Date = new Date()) { this.created_at = date }
	setSold (sold: boolean = false) { this.sold = sold }
	setOrder (order: number = 0) { this.order = order }
	setShowAnimation (flag: boolean = false) { this.showAnimation = flag }

	getId () { return this.id }
	getName () { return this.name }
	getUrl () { return this.url }
	getLogoPath () { return routes.media + 'promotions/' + this.logo_path }
	getActive () { return this.active }
	getCreatedAt () { return this.created_at }
	getSold () { return this.sold }
	getOrder () { return this.order }
	getShowAnimation () { return this.showAnimation }
}