import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PreferencesComponent } from './preferences/preferences.component';
import { PREFERENCES_ROUTES } from './preferences-routing.module';
import { AgenciesComponent } from './agencies/agencies.component';
import { LawyersComponent } from './lawyers/lawyers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CostumersComponent } from './costumers/costumers.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CommercialsComponent } from './commercials/commercials.component';
import { HomeboxModule } from '../homebox/homebox.module';
import { VisitsComponent } from './visits/visits.component';
import { DataTablesModule } from 'angular-datatables';



@NgModule({

    declarations: [
        PreferencesComponent,
        AgenciesComponent,
        LawyersComponent,
        CommercialsComponent,
        DashboardComponent,
        CostumersComponent,
        VisitsComponent,
    ],

    imports: [
        CommonModule,
        RouterModule.forChild (PREFERENCES_ROUTES),
        FormsModule,
        ComponentsModule,
        Ng2SmartTableModule,
        HomeboxModule,
        DataTablesModule
    ],

    exports: [
        PreferencesComponent,
        AgenciesComponent,
        LawyersComponent,
        CommercialsComponent,
        DashboardComponent,
    ]
})
export class PreferencesModule { }
