import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { popupBackground } from 'src/app/animations/popup.animation';
import { ParkingSpace } from 'src/app/classes/ParkingSpace';
import { Costumer } from 'src/app/classes/Costumer';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { CostumersService } from 'src/app/services/costumers.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { takeUntil, take } from 'rxjs/Operators';
import { MatSelect } from '@angular/material';
import { ParkingSpaceService } from 'src/app/services/parking-space.service';
import { Status } from 'src/app/classes/Status';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';

@Component({
    selector: 'app-parking-space',
    templateUrl: './parking-space.component.html',
    styleUrls: ['./parking-space.component.scss'],
    animations: [ popupBackground ]
})
export class ParkingSpaceComponent implements OnInit, OnDestroy {

    @Input () garage
    @Input ('parkingSpace')
    set parkingSpace (parkingSpace: ParkingSpace) {

        this._parkingSpace = parkingSpace

        this.originalParkingSpace = new ParkingSpace (JSON.parse (JSON.stringify (parkingSpace)))
        this.originalParkingSpace.setStatus (new Status (JSON.parse (JSON.stringify (parkingSpace.status))))

        this.changedParkingSpace = new ParkingSpace (JSON.parse (JSON.stringify (parkingSpace)))
        this.changedParkingSpace.setStatus (new Status (JSON.parse (JSON.stringify (parkingSpace.status))))
        
        this.isWorking = false
        
        setTimeout (() => { this.initAnimations = true }, 0)
    }
    
    @Output () closeEmitter: EventEmitter<ParkingSpace> = new EventEmitter<ParkingSpace> ()
    _parkingSpace: ParkingSpace
    originalParkingSpace: ParkingSpace
    changedParkingSpace: ParkingSpace

    isWorking: boolean
    initAnimations: boolean = false

    showCostumerPopup: boolean
    showStatusSelector: boolean

    costumers: Costumer[]
    formCostumersControl = new FormControl()
    formFilteredCostumersControl = new FormControl ()
    filteredCostumers: ReplaySubject<Costumer[]> = new ReplaySubject<Costumer[]>(1);
    @ViewChild('singleSelect') singleSelect: MatSelect;

    protected _onDestroy = new Subject<void>();

    getCostumersSub: Subscription = new Subscription ()
    addParkingSpaceSub: Subscription = new Subscription ()

    constructor ( private costumersService: CostumersService, private parkingSpaceService: ParkingSpaceService, private errorService: ErrorService ) {
        this.isWorking = false
        this.initAnimations = false
        this.costumers = []
        this.showCostumerPopup = false
        this.showStatusSelector = false
    }

    ngOnInit() {

        this.getCostumersSub = this.costumersService.getCostumers ().subscribe ((res: Error) => {

            if (res.status == httpStatus.success) {

                this.costumers.push (new Costumer ({id: null, name: 'Desenlazar cliente'}))

                this.costumers = this.costumers.concat (res.data)

                for (let costumer of this.costumers) {
                    if (costumer.getId () == this._parkingSpace.getIdCostumer ()) {
                        this._parkingSpace.setCostumer (costumer)
                        this.changedParkingSpace.setCostumer (costumer)
                        this.originalParkingSpace.setCostumer (costumer)
                    }
                }

                this.formCostumersControl.setValue(this.costumers)

                this.formCostumersControl.valueChanges.subscribe (res => {
                    
                    if (res instanceof Costumer && res.getId () != null) {

                        this.changedParkingSpace.setIdCostumer (res.getId ())
                        this.changedParkingSpace.setCostumer (res)
                    } else {

                        this.changedParkingSpace.setIdCostumer (null)
                        this.changedParkingSpace.setCostumer (new Costumer ({id: null, name: ''}))
                    }
                })
                
                this.filteredCostumers.next(this.costumers.slice());

                this.formFilteredCostumersControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
                    this.filterCostumers ();
                });
            }

        },(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'parking-space.component.ts'))
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete()
        this.getCostumersSub.unsubscribe ()
        this.addParkingSpaceSub.unsubscribe ()
    }

    
    close () {
        this._parkingSpace.clone (this.originalParkingSpace)
        this.closePopup ()
    }

    save () {
        this._parkingSpace.clone (this.changedParkingSpace)
        this.isWorking = true
        this.addParkingSpace ()
    }

    addParkingSpace () {

        this.addParkingSpaceSub = this.parkingSpaceService.addParkingSpace (this._parkingSpace).subscribe ((res: Error) => {
            
            if (res.status == httpStatus.success) {
                this.closePopup ()
            }
        },(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'parking-space.component.ts'))
    }

    closePopup () {
        
        this.initAnimations = false
        setTimeout (() => { this.closeEmitter.emit (this._parkingSpace) }, 300)
    }

    private filterCostumers () {

        if (!this.costumers) {
            return;
        }

        let search = this.formFilteredCostumersControl.value;

        if (!search) {
            this.filteredCostumers.next(this.costumers.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        
        this.filteredCostumers.next(
            this.costumers.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
        );
    }

    openPopupCostumerEdit () {
        this.showCostumerPopup = true
    }


    closePopupCostumerCallback (flag: boolean = false) {
        this.showCostumerPopup = false
    }

    openPopupStatusSelector () {
        this.showStatusSelector = true
    }

    statusSelectorCallback (status: Status) {

        if (status.getId () === "9") {
            this.changedParkingSpace.setHasBeenCancelled (1)
        }

        this.changedParkingSpace.setStatus (status)
        this.changedParkingSpace.setIdStatus (status.getId ())
        this.showStatusSelector = false
    }

}
