import { Component, OnInit, OnDestroy } from '@angular/core'
import { routes } from '../config/routes'
import { Router } from '@angular/router'
import { UserService } from '../services/user.service'
import { ToastrService } from 'ngx-toastr'
import { Error } from '../classes/Error';
import { httpStatus } from '../config/httpStatus';
import { User } from '../classes/User';
import { Subscription } from 'rxjs';
import { ErrorService } from '../services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{

	srcMedia: string
	key: number[]
	routes = routes
	isWorking: boolean
	checkCodeSubscription: Subscription = new Subscription ()
	

	constructor(private route: Router, private userService: UserService, private toastr: ToastrService, private errorService: ErrorService) {
		this.srcMedia = routes.media
		this.isWorking = false
		this.key = []
	}

	ngOnInit() { }

	ngOnDestroy () {
		this.checkCodeSubscription.unsubscribe ()
	}

	clickNumber (number: number) {
		this.key.push (number)

		if (this.key.length == 6) {
			this.checkCode ()
		}	
	}

	remove () {
		if (this.key.length > 0) {
			this.key.splice(-1,1)
		}
	}

	checkCode () {

		this.isWorking = true

		this.checkCodeSubscription = this.userService.checkCode (this.key.join ("")).subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {

				let user: User = new User (res.data)
				
				this.userService.recordUserInLocalStorage (user)
				this.route.navigate (['hometouch'])

			} else {
				
				this.toastr.warning (res.errors.toString (), "Ups..!")
				this.isWorking = false
				this.key = []
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'login.component.ts'))
	}
}
