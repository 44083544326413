import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbsService {

	promotionLogo = ""
	promotionLogoEmitter: EventEmitter<string> = new EventEmitter<string> ()

	constructor() {}

	setPromotionLogo (logo = "") {
		this.promotionLogo = logo
		this.promotionLogoEmitter.emit (this.promotionLogo)
	}

	getPromotionLogo (): EventEmitter<string> {
		return this.promotionLogoEmitter
	}
}
