import { routes } from '../config/routes';

export class Document {

	id: number = 0
	id_flat: number = 0
	file_path: string = ""
	created_at: Date = new Date ()
	imageExtensions = ['png', 'jpg', 'svg', 'gif', 'jpeg']
	draggableIndex: number
	scale: number
	angle: number

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setIdFlat ((typeof data != "undefined" && typeof data.id_flat != "undefined") ? data.id_flat : 0)
		this.setFilePath ((typeof data != "undefined" && typeof data.file_path != "undefined") ? data.file_path : '')
		this.setCreatedAt ((typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : new Date ())
	}

	setId (id: number = 0) { this.id = id }
	setIdFlat (id: number = 0) { this.id_flat = id }
	setFilePath (filePath: string = "") { this.file_path = filePath }
	setCreatedAt (date: Date = new Date ()) { this.created_at = date }
	setDraggableIndnex (draggableIndex: number) { this.draggableIndex = draggableIndex }
	
	getId (): number { return this.id }
	getIdFlat (): number { return this.id_flat }
	getFilePath (): string { return  routes.media + "flats_documents/" + this.getIdFlat() + "/" + this.getName () }
	getName (): string { return this.file_path }
	getCreatedAt (): Date { return this.created_at }
	getDraggableIndex () { return this.draggableIndex }

	getIcon () {

		let ext = this.getExtension ()
		let path = routes.media + "icons/files/"
		

		if (this.isImage ()) {
			return this.getFilePath ()
		} else {
			return path + ext + '.svg';
		}
	}

	isImage () {

		let ext = this.getExtension ()

		if (this.imageExtensions.indexOf (ext.toLocaleLowerCase ()) > -1) {
			return true;
		} else {
			return false
		}
	}

	getExtension () {
		return this.getFilePath ().split ('.').reverse ()[0]
	}
}