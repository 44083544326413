import { Country } from './country';
import { Agent } from './Agent';

export class Agency {

	id:number
	name: string
	id_folder: number
	address: string
	email: string
	phone: string
	market: number
	marketClass: Country
	agents: Agent[]
	webpage: string
	observations: string
	lat: string
	lon: string

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0 )
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : '' )
		this.setIdFolder ((typeof data != "undefined" && typeof data.id_folder != "undefined") ? parseInt (data.id_folder) : 0 )
		this.setAddress ((typeof data != "undefined" && typeof data.address != "undefined") ? data.address : '' )
		this.setEmail ((typeof data != "undefined" && typeof data.email != "undefined") ? data.email : '' )
		this.setPhone ((typeof data != "undefined" && typeof data.phone != "undefined") ? data.phone : '' )
		this.setMarket ((typeof data != "undefined" && typeof data.market != "undefined") ? data.market : 0)
		this.setMarketClass ((typeof data != "undefined" && typeof data.marketClass != "undefined") ? data.marketClass : new Country () )
		this.setLat ((typeof data != "undefined" && typeof data.lat != "undefined") ? data.lat : '' )
		this.setLon ((typeof data != "undefined" && typeof data.lon != "undefined") ? data.lon : '' )
		this.setAgents ((typeof data != "undefined" && typeof data.agents != "undefined") ? data.agents : [] )
		this.setWebpage ((typeof data != "undefined" && typeof data.webpage != "undefined") ? data.webpage : '' )
		this.setObservations ((typeof data != "undefined" && typeof data.observations != "undefined") ? data.observations : '' )
	}

	getId () { return this.id }
	getName () { return this.name }
	getIdFolder () { return this.id_folder }
	getAddress () { return this.address }
	getEmail () { return this.email }
	getPhone () { return this.phone }
	getMarket () { return this.market }
	getMarketClass () { return this.marketClass }
	getLat () { return this.lat }
	getLon () { return this.lon }
	getAgents () { return this.agents }
	getWebpage () { return this.webpage }
	getObservations () { return this.observations }
	
	
	setId (id: number = 0) { this.id = id }
	setName (name: string = '') { this.name = name }
	setIdFolder (id_folder: number = 0) { this.id_folder = id_folder }
	setAddress (address: string = "") { this.address = address }
	setEmail (email: string = "") { this.email = email }
	setPhone (phone: string = "") { this.phone = phone }
	setMarket (market: number = 0) { this.market = market }
	setMarketClass (market: Country = new Country) { this.marketClass = market }
	setLat (lat: string = "") { this.lat = lat }
	setLon (lon: string = "") { this.lon = lon }
	setAgents (agents: Agent[] = []) { this.agents = agents }
	setWebpage (webpage: string = "") { this.webpage = webpage }
	setObservations (observations: string = "") { this.observations = observations }

	getWebpageFormated () {

		let url = this.getWebpage ()
		url = url.toLowerCase ()
		url = url.replace ("http://", "")
		url = url.replace ("https://", "")
		url = url.replace ("www.", "")
		
		return url;
	}

	toArray () {
		return {
			'id': this.getId (),
			'name': this.getName (),
			'id_folder' : this.getIdFolder (),
			'address': this.getAddress (),
			'email': this.getEmail (),
			'phone': this.getPhone (),
			'market': this.getMarket (),
			'webpage': this.getWebpage (),
			'observations': this.getObservations (),
			'lat': this.getLat (),
			'lon': this.getLon (),
		}
	}

	toJson () {
		return JSON.stringify (this.toArray ())
	}

	clone (agency: Agency) {
		this.setId (agency.getId ())
		this.setName (agency.getName ())
		this.setIdFolder (agency.getIdFolder ())
		this.setAddress (agency.getAddress ())
		this.setEmail (agency.getEmail ())
		this.setPhone (agency.getPhone ())
		this.setMarket (agency.getMarket ())
		this.setMarketClass (agency.getMarketClass ())
		this.setWebpage (agency.getWebpage ())
		this.setObservations (agency.getObservations ())
		this.setAgents (agency.getAgents ())
		this.setLat (agency.getLat ())
		this.setLon (agency.getLon ())
	}
	

}