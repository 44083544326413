import { Component, OnInit, OnDestroy } from '@angular/core';
import { PromotionsService } from './../../../services/promotions.service'
import { Promotion } from './../../../classes/Promotion';
import { ErrorInterface } from './../../../classes/Error'
import { showPromotions } from 'src/app/animations/promotions.animations';
import { Router } from '@angular/router'
import { StorageService } from 'src/app/services/storage.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { FlatsService } from 'src/app/services/flats.service';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.scss'],
	animations: [ showPromotions ],
})
export class PromotionsComponent implements OnInit, OnDestroy {

	promotions: Promotion[]
	showTbybSelector: boolean

	getPromotionsSubscription: Subscription = new Subscription ()

	constructor(private promotionsService: PromotionsService, private router: Router, private storage: StorageService, private breadcrumbsService: BreadcrumbsService, private flatsService: FlatsService, private errorService: ErrorService) {
		this.showTbybSelector = false
	}

	ngOnInit() {

		this.storage.setCurrentViewName ('promotions')
		this.breadcrumbsService.setPromotionLogo ()

		this.getPromotionsSubscription = this.promotionsService.getPromotions ().subscribe ((promotions: ErrorInterface) => {

			if (!promotions.hasErrors ()) {
				this.promotions = promotions.getData ()
			}

			this.startAnimation (true)
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'promotions.component.ts'))
	
	}
	
	ngOnDestroy () {
		
		this.getPromotionsSubscription.unsubscribe ()
	}

	selectPromotion (promotion: Promotion) {

		if (promotion.getId () == 10) {

			this.showTbybSelector = true

		} else {

			this.storage.addView ('promotions', this.router.url, promotion)
			this.breadcrumbsService.setPromotionLogo (promotion.getLogoPath ())

			this.startAnimation (false, () => {
				this.router.navigate (['/hometouch/zones'])
			})
		}	
	}

	startAnimation (show: boolean = false, callback = () => {}) {

		let timer = 0

		this.promotions.forEach ((promotion: Promotion) => {
			setTimeout (() => {
				promotion.setShowAnimation (show)
			}, timer)

			timer += 70
		})

		setTimeout (() => { callback () }, timer + 500)
	}


	closeTbybSelectorCallback (item) {
		
		this.showTbybSelector = false;

		if (item != null) {

			this.flatsService.setAllRouteOfFlat (item)
		}
	}
}
