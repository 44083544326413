import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DateService {

	constructor() { }

	monthUmmanize (month: number) {

		let text = "";

		switch (month) {
			case 0: text = "Enero"; break;
			case 1: text = "Febrero"; break;
			case 2: text = "Marzo"; break;
			case 3: text = "Abril"; break;
			case 4: text = "Mayo"; break;
			case 5: text = "Junio"; break;
			case 6: text = "Julio"; break;
			case 7: text = "Agosto"; break;
			case 8: text = "Septiembre"; break;
			case 9: text = "Octubre"; break;
			case 10: text = "Noviembre"; break;
			case 11: text = "Diciembre"; break;
		
			default:
				break;
		}

		return text;

	}

	dayHumanize (day: number) {
		
		let text = "";
		
		switch (day) {
			case 1: text = "Lunes"; break;
			case 2: text = "Martes"; break;
			case 3: text = "Miercoles"; break;
			case 4: text = "Jueves"; break;
			case 5: text = "Vierenes"; break;
			case 6: text = "Sábado"; break;
			case 0: text = "Domingo"; break;

			default:
				break;
		}

		return text;
	}
}
