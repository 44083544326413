import { Flat } from './Flat'
import { routes } from '../config/routes';

export interface FloorInterface {
	id: number
	id_zone: number
	name: string
	order: number
	plan_path: string
	created_at: Date
	flats: Flat[]

	setId (id: number): void
	setIdZone (idZone: number): void
	setName (name: string): void
	setOrder (order: number): void
	setPlanPath (planPath: string): void
	setCreatedAt (date: Date): void
	setFlats (flats: Flat[]): void

	getId (): number
	getIdZone (): number
	getName (): string
	getOrder (): number
	getPlanPath (): string
	getCreatedAt (): Date
	getFlats (): Flat[]

}

export class Floor implements FloorInterface {
	id
	id_zone
	name
	order
	plan_path
	created_at
	flats

	constructor (data?: FloorInterface) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setIdZone ((typeof data != "undefined" && typeof data.id_zone != "undefined") ? data.id_zone : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setOrder ((typeof data != "undefined" && typeof data.order != "undefined") ? data.order : 0)
		this.setPlanPath ((typeof data != "undefined" && typeof data.plan_path != "undefined") ? data.plan_path : "")
		this.setCreatedAt ((typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : new Date ())
		this.setFlats ((typeof data != "undefined" && typeof data.flats != "undefined") ? data.flats : [])
	}

	setId (id = 0) { this.id = id }
	setIdZone (idZone = 0) { this.id_zone = idZone }
	setName (name = "") { this.name = name }
	setOrder (order = 0) { this.order = order }
	setPlanPath (planPath = "") { this.plan_path = planPath }
	setCreatedAt (date = new Date) { this.created_at = date }
	setFlats (flats) { this.flats = flats }

	getId () { return this.id }
	getIdZone () { return this.id_zone }
	getName () { return this.name }
	getOrder () { return this.order }
	getPlanPath () { return  routes.media + 'map-zones/' + this.plan_path }
	getCreatedAt () { return this.created_at }
	getFlats () { return this.flats }


}