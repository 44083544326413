import { Routes } from '@angular/router';
import { AgenciesComponent } from './agencies/agencies.component';
import { LawyersComponent } from './lawyers/lawyers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CostumersComponent } from './costumers/costumers.component';
import { CommercialsComponent } from './commercials/commercials.component';
import { VisitsComponent } from './visits/visits.component'


const routes: Routes = [

    { path: 'agencies', component: AgenciesComponent },
    { path: 'lawyers', component: LawyersComponent },
    { path: 'costumers', component: CostumersComponent },
    { path: 'commercials', component: CommercialsComponent },
    { path: 'visits', component: VisitsComponent },
    { path: '', component: DashboardComponent },
    { path: '', pathMatch: 'full', redirectTo: '' }

];

export const PREFERENCES_ROUTES = routes
