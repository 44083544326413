import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { EventClass } from 'src/app/classes/calendar/Event';

@Component({
	selector: 'app-event-tools',
	templateUrl: './event-tools.component.html',
	styleUrls: ['./event-tools.component.scss']
})
export class EventToolsComponent implements OnInit {

	_event: EventClass
	coords: any = {top: 0, right:0, bottom: 0, left: 0}
	@ViewChild ('toolsContainer') toolsContainer
	@Output () editEventEmitter: EventEmitter<EventClass> = new EventEmitter<EventClass> ()
	@Output () closeToolsEmitter: EventEmitter<boolean> = new EventEmitter<boolean> ()
	@Output () removeEventEmitter: EventEmitter<EventClass> = new EventEmitter<EventClass> ()

	@Input ('event')
	set event (event: EventClass) {

		this._event = event

		let eventFullCalendar = this._event.getFullCalendarEvent ().getBoundingClientRect();
		let toolsContainer = this.toolsContainer.nativeElement.getBoundingClientRect ()

		this.coords = {top: eventFullCalendar.top , left: eventFullCalendar.left - toolsContainer.width - 10}

	}

	constructor() { }

	ngOnInit() {
	}

	editEvent () {
		this.editEventEmitter.emit (this._event)
	}

	closeTools () {
		this.closeToolsEmitter.emit (true)
	}

	removeEvent () {
		this.removeEventEmitter.emit (this._event)
	}

}
