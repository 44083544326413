import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HomeboxService {

	public search: string
	public searchEventEmitter: EventEmitter<string> = new EventEmitter<string> ()

	public type: string
	public typeEventEmitter: EventEmitter<string> = new EventEmitter<string> ()


	constructor() {
		this.search = ""
		this.type = 'grid'
	}

	setSearch (search: string = '') {
		this.search = search
		this.searchEventEmitter.emit (this.search)
	}

	setType (type: string = 'grid') {
		this.type = type
		this.typeEventEmitter.emit (this.type)
	}

	getSearch () { return this.searchEventEmitter }
	getType () { return this.typeEventEmitter }


}
