import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const showItemsList: AnimationTriggerMetadata = trigger('showItemsList', [

	
	state ('false', style({ opacity: 0, marginTop: 0, height: '0px', padding: '0px 10px' })),
	state ('true', style({ opacity: 1, height: '*', marginTop: '10px' })),

	//hide
	transition ('true => false', animate('.5s ease-in-out', keyframes ([
		style ({opacity: 1, height: '*', marginTop: '10px', padding: '10px', offset: 0}),
		style ({opacity: 0, height: '0px', marginTop: 0, padding: '0px 10px', offset: 1}),
	]))),

	//show
	transition ('false => true', animate('.5s ease-in-out', keyframes ([
		style ({opacity: 0, height: '0px', marginTop: 0, padding: '0px 10px', offset: 0}),
		style ({opacity: 1, height: '*', marginTop: '10px', padding: '10px', offset: 1}),
	])))
])


export const showPreferencesButton: AnimationTriggerMetadata = trigger('showPreferencesButton', [

	
	state ('false', style({opacity: 0, marginTop: '100px'})),
	state ('true', style({opacity: 1, marginTop: 0})),

	//hide
	transition ('true => false', animate('1s ease-in-out', keyframes ([
		style ({opacity: 1, marginTop: 0, offset: 0}),
		style ({opacity: 0, marginTop: '100px', offset: 1}),
	]))),

	//show
	transition ('false => true', animate('1s ease-in-out', keyframes ([
		style ({opacity: 0, marginTop: '100px', offset: 0}),
		style ({opacity: 1, marginTop: 0, offset: 1}),
	])))
])