import { Injectable } from '@angular/core';
import { Status } from '../classes/Status';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators'
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { Error } from './../classes/Error'
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class StatusService {

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	private controller = 'Statuses';

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getStatuses (table: string = 'statuses') {

		let statusClasses = []
		let err = new Error ()
		let body = new HttpParams ().set ('table', JSON.stringify (table))
		let errorMessage = 'No hemos podido descargar los estados. Vuelve a intentarlo'

		return this.http.post (this.getUrl() + "getStatuses",body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let statuses = res.data;

				for (let status of statuses) {
					statusClasses.push (new Status (status))
				}

				err.setData (statusClasses)

				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'status.service')
				return err
			}
		}))
	}
}
