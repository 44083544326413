import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Agency } from 'src/app/classes/Agency';
import { showFilterList } from 'src/app/animations/map-zone.animation';


@Component({
	selector: 'app-agencies-selector',
	templateUrl: './agencies-selector.component.html',
	styleUrls: ['./agencies-selector.component.scss'],
	animations: [ showFilterList ]
})
export class AgenciesSelectorComponent implements OnInit, AfterViewInit {

	_agencies: Agency []
	_agenciesFiltered: Agency []
	@Input ('agencies')
	set agencies (agencies: Agency[]) {
		this._agencies = agencies
		this._agenciesFiltered = agencies
	}

	agencySelected: Agency
	@Output() agencyEmitter: EventEmitter<Agency> = new EventEmitter<Agency> ()
	@ViewChild ('inputSearch') inputSearch: ElementRef
	textToSearch: string

	constructor() {
		this._agencies = []
		this._agenciesFiltered = []
		this.agencySelected = new Agency ()
		this.textToSearch = ""
	}

	ngOnInit() {
		
	}

	ngAfterViewInit () {
		this.inputSearch.nativeElement.focus ()
	}


	//click en cada agencia
	selectAgency (agency: Agency = new Agency ()) {
		this.agencySelected = agency
		this.emitAgency ()
	}


	//emitir el observable de la agencia
	emitAgency () {
		this.agencyEmitter.emit (this.agencySelected)
	}

	//"x" close popup
	closePopUp () {
		this.agencyEmitter.emit (new Agency ())
	}

	//evento cuando se escribe una letra en el buscador
	keyupsearch () {
		
		this._agenciesFiltered = []

		for (let agency of this._agencies) {
			if (agency.getName ().toLowerCase ().indexOf (this.textToSearch.toLowerCase ()) > -1) {
				this._agenciesFiltered.push (agency)
			}
		}
		
	}

}
