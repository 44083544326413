import { Component, OnInit, OnDestroy } from '@angular/core';
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { FlatService } from 'src/app/services/flat.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { Status } from 'src/app/classes/Status';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-flat-history',
	templateUrl: './flat-history.component.html',
	styleUrls: ['./flat-history.component.scss'],
	host: {class: 'app-flats-history'}
})
export class FlatHistoryComponent implements OnInit, OnDestroy {

	historyLogs: HistoryLog[]
	getHistoryLogsSub: Subscription = new Subscription ()

	constructor( private flatService: FlatService, private errorService: ErrorService ) { }

	ngOnInit() {

		this.getHistoryLogsSub = this.flatService.getHistoryLogs ().subscribe ((res: Error) => {

			if (res.status = httpStatus.success) {
				this.historyLogs = res.data
			}

		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'lawyers.component.ts'))
	}

	ngOnDestroy () {
		this.getHistoryLogsSub.unsubscribe ()
	}

	getStatusBackgroundStyle (status: Status) {

		if (status.getId () == 9) {
			return {'background-image': 'url(assets/media/icons/close.png)', 'background-color': status.getRgb ()}
		}

		return {'background': status.getRgb()}
	}

	public showLog (historyLog: HistoryLog) {
		
		this.historyLogs[this.historyLogs.indexOf (historyLog)].setShowLog (!historyLog.getShowLog ())
	}

}
