export class Commercial {

	private id: number = 0
	private name: string = ""
	private position: string = ""

	constructor (data?) {

		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setPosition ((typeof data != "undefined" && typeof data.position != "undefined") ? data.position : "")
	}

	setId (id: number = 0): void { this.id = id }
	setName (name: string = ""): void { this.name = name }
	setPosition (position: string = ""): void { this.position = position }
	
	getId (): number { return this.id }
	getName (): string { return this.name }
	getPosition (): string { return this.position }
	

	clone (newCommercial: Commercial) {
		this.setId (newCommercial.getId ())
		this.setName (newCommercial.getName ())
		this.setPosition (newCommercial.getPosition())
	}

	toJson () {
		return JSON.stringify (this.toArray ())
	}

	toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			position: this.getPosition (),
		}
	}

}