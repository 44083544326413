import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const showFlats: AnimationTriggerMetadata = trigger('showFlats', [

	
	state ('false', style({
		opacity: 0,
		transform: "translateY(50px)"
	})),

	state ('true', style({
		opacity: 1,
		transform: "translateY(0px)"
	})),

	//hide
	transition ('true => false', animate('.5s ease-in-out')),

	//show
	transition ('false => true', animate('.5s ease-in-out'))
])