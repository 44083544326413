import { Status } from './Status';
import { Costumer } from './Costumer';

export class StorageRoom {

    id: number = 0
    id_garage: number = 0
    id_costumer: number = 0
    id_status: number = 0
    name: string = ""
    price: number = 0
    coords_map: string = ""
    status: Status = new Status ()
    costumer: Costumer = new Costumer ()
    has_been_cancelled: number = 0

    constructor (data: any = {}) {
        this.setId ((typeof data.id != "undefined") ? data.id : 0)
        this.setIdGarage ((typeof data.id_garage != "undefined") ? data.id_garage : 0)
        this.setIdCostumer ((typeof data.id_costumer != "undefined") ? data.id_costumer : 0)
        this.setIdStatus ((typeof data.id_status != "undefined") ? data.id_status : 0)
        this.setName ((typeof data.name != "undefined") ? data.name : "")
        this.setPrice ((typeof data.price != "undefined") ? data.price : 0)
        this.setCoordsMap ((typeof data.coords_map != "undefined") ? data.coords_map : 0)
        this.setStatus ((typeof data.status != "undefined") ? data.status : new Status ())
        this.setCostumer ((typeof data.costumer != "undefined") ? data.costumer : new Costumer ())
        this.setHasBeenCancelled ((typeof data.has_been_cancelled !== 'undefined') ? data.has_been_cancelled : 0)
    }

    setId (id: number = 0) { this.id = id }
    setIdGarage (idGarage: number = 0) { this.id_garage = idGarage }
    setIdCostumer (idCostumer: number = 0) { this.id_costumer = idCostumer }
    setIdStatus (idStatus: number = 0) { this.id_status = idStatus }
    setName (name: string = "") { this.name = name }
    setPrice (price: number = 0) { this.price = price }
    setCoordsMap (coords: string = "") { this.coords_map = coords }
    setStatus (status: Status = new Status ()) { this.status = status }
    setCostumer (costumer: Costumer = new Costumer ()) { this.costumer = costumer }
    setHasBeenCancelled (flag: number = 0) { this.has_been_cancelled = flag }

    getId () { return this.id }
    getIdGarage () { return this.id_garage }
    getIdCostumer () { return this.id_costumer }
    getIdStatus () { return this.id_status }
    getName () { return this.name }
    getPrice () { return this.price }
    getCoordsMap () { return this.coords_map }
    getStatus () { return this.status }
    getCostumer () { return this.costumer }
    getHasBeenCancelled () { return this.has_been_cancelled }

    clone (storageRoom: StorageRoom) {
        this.setId (storageRoom.getId ())
        this.setIdGarage (storageRoom.getIdGarage ())
        this.setIdCostumer (storageRoom.getIdCostumer ())
        this.setIdStatus (storageRoom.getIdStatus ())
        this.setName (storageRoom.getName ())
        this.setPrice (storageRoom.getPrice ())
        this.setCoordsMap (storageRoom.getCoordsMap ())
        this.setStatus (storageRoom.getStatus ())
        this.setCostumer (storageRoom.getCostumer ())
        this.setHasBeenCancelled (storageRoom.getHasBeenCancelled ())
    }

    toArray () {
        return {
            id: this.getId (),
            id_garage: this.getIdGarage (),
            id_costumer: this.getIdCostumer (),
            id_status: this.getIdStatus (),
            name: this.getName (),
            price: this.getPrice (),
            coords_map: this.getCoordsMap (),
            has_been_cancelled: this.getHasBeenCancelled ()
        }
    }

    toJson () {
        return JSON.stringify (this.toArray ())
    }

}