import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { PagesComponent } from './pages/pages.component';
import { PAGES_ROUTES } from './pages-routing.module';

import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CalendarModule } from './calendar/calendar.module';
import { HometouchModule } from './hometouch/hometouch.module';
import { PreferencesModule } from './preferences/preferences.module';
import { HomeboxModule } from './homebox/homebox.module';
import { RouterModule } from '@angular/router';


@NgModule({
	declarations: [
		PagesComponent,
	],

	exports: [
		PagesComponent,
	],

	imports: [
		CommonModule,
		RouterModule.forChild (PAGES_ROUTES),
		HttpClientModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HometouchModule,
		SharedModule,
		DashboardModule,
		CalendarModule,
		PreferencesModule,
		HomeboxModule
	],
})
export class PagesModule { }
