import { Component, OnInit, AfterViewInit, ViewChild, Renderer } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { routes } from 'src/app/config/routes';
import { VisitsService } from 'src/app/services/visits.service';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})

export class VisitsComponent implements OnInit, AfterViewInit  {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  constructor (private renderer: Renderer, private visitService: VisitsService) { }

  ngOnInit(): void {
    this.dtOptions = {
      ajax: {
        url: routes.api + 'Visits/getDatatable',
        type: 'POST'
      },
      responsive: true,
      searching: true,

      // Pagination settings
      dom:"<'row'<'col-sm-12'tr>>\n\t\t\t<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",
      // read more: https://datatables.net/examples/basic_init/dom.html

      lengthMenu: [5, 10, 25, 50, 100],
      pageLength: 10,
      orderClasses: false,
      language: { 'lengthMenu': 'Display _MENU_' },

      searchDelay: 500,
      processing: true,
      serverSide: true,
      pagingType: 'full_numbers',
      paging: true,
      order: [
        [10, 'desc']
      ],

      columns: [
        { data: 'promotion' },
        { data: 'seller' },
        { data: 'agency' },
        { data: 'phone_contact' },
        { data: 'email_contact' },
        { data: 'name_contact' },
        { data: 'country' },
        { data: 'budget' },
        { data: 'preferences' },
        { data: 'observations' },
        { data: 'created_at' },
        { data: 'actions', width: '50px' }
      ],

      columnDefs: [
        {
          targets: 3,
          render: (phoneNumber, type, rowData, meta) => {
            return `<a href='callto:${phoneNumber}'>${phoneNumber}</a>`
          }
        },
        {
          targets: 4,
          render: (email, type, rowData, meta) => {
            return `<a href='mailto:${email}'>${email}</a>`
          }
        },
        {
          targets: -1,
          render: (column, type, rowData, meta) => {
            console.log (rowData)
            return `<i data-visit-id='${rowData.id}' class="icon-list-preferences-datatable fas fa-trash"></i>`;
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      $('input.table-filter').on('keyup change', function (e) {
        e.preventDefault ()

          const params = {};
          $('tr.filter').find('.table-filter').each(function() {
            const i = $(this).data('col-index');
            if (params[i]) {
              params[i] += '|' + $(this).val();
            } else {
              params[i] = $(this).val();
            }
          });

          $.each(params, function(i, val) {
            dtInstance.column(i).search(val ? val : '', false, false);
          });

          dtInstance.draw();
      });

    });

    this.renderer.listenGlobal('document', 'click', (event) => {
      if (event.target.hasAttribute('data-visit-id')) {
        this.visitService.deleteVisit (event.target.getAttribute("data-visit-id")).subscribe (() => {
          window.location.reload ()
        })
      }
    });
  }

  public removeVisit (id: number) {

    console.log (id)

  }
}
