import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const openSearchFilters: AnimationTriggerMetadata = trigger('openSearchFilters', [

	
	state ('false', style({
		opacity: 0,
		left: "-100px"
	})),

	state ('true', style({
		opacity: 1,
		left: "40px"
	})),

	//hide
	transition ('true => false', animate('.5s ease-in-out')),

	//show
	transition ('false => true', animate('.5s ease-in-out'))
])

export const showFilterList: AnimationTriggerMetadata = trigger('showFilterList', [

	state (':leave', style({left: "-350px", opacity: 0})),
	state (':enter', style({left: "0", opacity: 1})),

	//hide
	transition (':leave', animate('.3s ease-in-out', keyframes ([
		style ({offset: 0, left: "0", opacity: 1}),
		style ({offset: 1, left: "-350px", opacity: 0}),
	]))),

	//show
	transition (':enter', animate('.3s ease-in-out', keyframes ([
		style ({offset: 0, left: "-350px", opacity: 0}),
		style ({offset: 1, left: "0", opacity: 1}),
	])))
])