import { Injectable } from '@angular/core';
import { ParkingSpace } from '../classes/ParkingSpace';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { Error } from '../classes/Error';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class ParkingSpaceService {

	private controller: string

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller = 'ParkingSpaces'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}

	public addParkingSpace (parkingSpace: ParkingSpace) {

		let err = new Error ()
		let body = new HttpParams ().set ('parkingSpace', parkingSpace.toJson ())
		let errorMessage = 'No hemos podido actualizar la plaza de garaje. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "addParkingSpace", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (parkingSpace)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'parking-space.service')
				return err
			}
			

		}))
	}
}
