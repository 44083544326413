import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const showStatusSelector: AnimationTriggerMetadata = trigger('showStatusSelector', [

	
	state ('false', style({
		opacity: 0,
		transform: "scale(.5)"
	})),

	state ('true', style({
		opacity: 1,
		transform: "scale(1)"
	})),

	//hide
	transition ('true => false', animate('.5s ease-in-out')),

	//show
	transition ('false => true', animate('.5s ease-in-out'))
])