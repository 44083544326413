import { Component, OnInit, ElementRef, Renderer2, Inject, OnDestroy } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';
import { Floor } from 'src/app/classes/Floor';
import { Zone } from 'src/app/classes/Zone';
import { StorageService } from 'src/app/services/storage.service';
import { ZonesService } from 'src/app/services/zones.service';
import { Error } from './../../../classes/Error';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import * as interact from 'interactjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { openSearchFilters } from 'src/app/animations/map-zone.animation';
import { Agency } from 'src/app/classes/Agency';

@Component({
	selector: 'app-map-zone',
	templateUrl: './map-zone.component.html',
	styleUrls: ['./map-zone.component.scss'],
	host: {'class': 'map-zone-content'} ,
	animations: [ openSearchFilters ]
})
export class MapZoneComponent implements OnInit, OnDestroy {

	xmlns = "http://www.w3.org/2000/svg"
	flats: Flat[]
	hoverFlat: Flat
	floor: Floor
	zone: Zone
	timeInt

	//interactions
	scale: number
	angle: number
	gestureArea
	scaleElement
	isMoving: boolean
		
	getInfoForMapSub: Subscription = new Subscription ()

	//filters
	showFiltersList: boolean
	showPopupFilters: {agencies: boolean}
	agencies: Agency[]

	htmlElementsToHideInAgenciesFilter: any[]


	constructor(private storage: StorageService, private zonesService: ZonesService, private elementRef:ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private router: Router, private errorService: ErrorService) {
		this.flats = []
		this.hoverFlat = new Flat ()
		this.floor = new Floor ()
		this.zone = new Zone ()
		this.isMoving = false
		this.scale = 1
		this.angle = 0
		this.showFiltersList = false
		this.showPopupFilters = {agencies: false}
		this.agencies = []
		this.htmlElementsToHideInAgenciesFilter = []
	}

	ngOnInit() {
		
		this.storage.setCurrentViewName ('map')
		

		for (let view of this.storage.viewList) {
			if (view.name == 'zones') {
				this.zone = new Zone(view.data);
			}
		}

		this.getInfoForMapSub = this.zonesService.getInfoForMap (this.zone.getId ()).subscribe ((err: Error) => {
			
			if (!err.hasErrors ()) {
				this.floor = err.getData ().floor
				this.flats = err.getData ().flats
				
				for (let flat of this.flats) {
					
					let isInAgencies = this.agencies.find ((agency: Agency) => agency.getId () == flat.getPromoter ().getId ())

					if (typeof isInAgencies == 'undefined' && flat.getPromoter ().getId () > 0) {
						this.agencies.push (flat.getPromoter ())
					}	
				}

				this.timeInt = setInterval (() => {

					if (this.elementRef.nativeElement.querySelector('#flats')) {
						clearInterval (this.timeInt)
						this.printFlatsInPlan ()
						this.initInteractions ()
					}

				}, 500)
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'map-zone.component.ts'))
	}

	ngOnDestroy () {
		this.getInfoForMapSub.unsubscribe ()
	}


	printFlatsInPlan () {

		var d1 = this.elementRef.nativeElement.querySelector('#flats');

		for (let flat of this.flats) {

			let polygon = this.document.createElementNS(this.xmlns, 'polygon');
			this.renderer.setAttribute (polygon, 'points', flat.getCoordsMap ())
			this.renderer.setAttribute (polygon, 'fill', flat.getStatus ().getRgb ())
			this.renderer.setAttribute (polygon, 'stroke', '#2B2A29')
			this.renderer.setAttribute (polygon, 'stroke-width', '2')
			this.renderer.setAttribute (polygon, 'data-id', flat.getId ())
			this.renderer.setAttribute (polygon, 'class', 'flat-map')

			//click
			this.renderer.listen (polygon, 'click', (event) => {

				let idElement = event.target || event.srcElement
				let flatId = idElement.dataset.id

				this.openFlat (flatId)
			})
			
			//hover in
			polygon.addEventListener ('mouseenter', (event:any) => {

				let idElement = event.target || event.srcElement
				let flatId = idElement.dataset.id

				this.hoverFlat = this.getFlatClassById (flatId)
			})

			this.renderer.appendChild(d1, polygon);
		}
	}


	openFlat (flatId: number) {

		if (this.isMoving) return

		let flat = this.getFlatClassById (flatId)

		this.storage.addView ('flat', this.router.url, flat)
		this.router.navigate (['/hometouch/flat'])

	}


	getFlatClassById (id: number = 0): Flat {

		return this.flats.find ((flat: Flat) => flat.getId () == id)
	}


	initInteractions () {

		this.gestureArea = this.document.getElementById ('svg-plan')

		interact(this.gestureArea)
			.gesturable({
				onstart: (event) => { this.isMoving = true },
				onend: (event) => { setTimeout (() => { this.isMoving = false }, 100) },

				onmove: (event) => {
					
					this.scale = this.scale * (1 + event.ds);
					this.angle += event.da;

					this.setScaleAndRotation ()

					this.dragMoveListener(event);
				}
			})
			.draggable({
				inertia: true,

				onmove: (event) => { this.dragMoveListener (event) },
				onstart: (event) => { this.isMoving = true },
				onend: (event) => { setTimeout (() => { this.isMoving = false }, 100) }
			});
	}


	setScaleAndRotation () {

		this.scaleElement = this.document.getElementById ('svg-content-plan')
		this.scaleElement.style.webkitTransform = this.scaleElement.style.transform = 'scale(' + this.scale + ') rotate(' + this.angle + 'deg)';
	}


	dragMoveListener (event) {

		var target = event.target
        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

		// translate the element
		target.style.webkitTransform =
		target.style.transform =
		'translate(' + x + 'px, ' + y + 'px)';

		// update the posiion attributes
		target.setAttribute('data-x', x);
		target.setAttribute('data-y', y);
	}


	//FILTERS LIST AGENCIES

	agenciesSelectorCallbackEmitter (agency: Agency) {
		
		if (this.htmlElementsToHideInAgenciesFilter.length == 0) {
			this.htmlElementsToHideInAgenciesFilter.push (this.elementRef.nativeElement.querySelector('#base'))
			this.htmlElementsToHideInAgenciesFilter.push (this.elementRef.nativeElement.querySelector('#to-hide'))
			this.htmlElementsToHideInAgenciesFilter.push (this.elementRef.nativeElement.querySelector('#textos'))
		}
		

		let itemsToChangeOpacity = []

		if (agency.getId () > 0) {

			itemsToChangeOpacity = this.elementRef.nativeElement.querySelectorAll('.flat-map')
			this.changeAllItemsOpacity (itemsToChangeOpacity, 0.15)
			this.changeAllItemsOpacity (this.htmlElementsToHideInAgenciesFilter, 0.15)


			itemsToChangeOpacity = []

			for (let flat of this.flats) {

				if (flat.getIdPromoter () == agency.getId ()) {

					for (let item of this.elementRef.nativeElement.querySelectorAll('.flat-map')) {
						if (item.dataset.id == flat.getId ()) {
							itemsToChangeOpacity.push (item)
						}
					}
				}
			}

			this.changeAllItemsOpacity (itemsToChangeOpacity, 1)
		} else {

			itemsToChangeOpacity = this.elementRef.nativeElement.querySelectorAll('.flat-map')
			this.changeAllItemsOpacity (itemsToChangeOpacity, 1)
			this.changeAllItemsOpacity (this.htmlElementsToHideInAgenciesFilter, 1)

			this.showPopupFilters.agencies = false
			this.showFiltersList = false
		}


		
	}

	changeAllItemsOpacity (items: any[] = [], opacity: number = 0) {

		for (let item of items) {
			if (item != null) {
				item.style.opacity = opacity
			}
		}

	}


}
