import { Component, OnInit } from '@angular/core';
import { showNavbar } from 'src/app/animations/navbar.animations';
import { DateService } from 'src/app/services/date.service';
;

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	animations: [ showNavbar ]
})
export class NavbarComponent implements OnInit {

	monthText = ""
	day = 1;
	showNavbarFlag = false
	version: string

	constructor( private dateService: DateService ) { }

	ngOnInit() {

		setTimeout (() => { this.showNavbarFlag = true }, 500)

		let date = new Date();
		let month = date.getMonth ()
		this.monthText = this.dateService.monthUmmanize (month);
		this.day = date.getUTCDate()
		
	}


	

}
