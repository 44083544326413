import { Injectable, EventEmitter } from '@angular/core';
import { Flat } from '../classes/Flat';
import { Comment } from '../classes/Comment'
import { CommentsService } from './comments.service';
import { Observable } from 'rxjs';
import { Error } from '../classes/Error';
import { DocumentsService } from './documents.service';
import { StorageService } from './storage.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { HistoryLogsService } from './history-logs.service';
import { EventClass } from '../classes/calendar/Event';
import { Costumer } from '../classes/Costumer';
import { UserService } from './user.service';
import { ErrorService } from './error.service';
import { Promoter } from '../classes/Promoter';
import { Agency } from '../classes/Agency';


@Injectable({
	providedIn: 'root'
})
export class FlatService {

	flat: Flat
	flatEmitter: EventEmitter<Flat> = new EventEmitter ()
	commentsEmitter: EventEmitter<Comment[]> = new EventEmitter<Comment[]> ()
	private controller: string

	constructor(
		private commentsServive: CommentsService,
		private documentsService: DocumentsService,
		private http: HttpClient,
		private storageService: StorageService,
		private historyLogsService: HistoryLogsService,
		private userService: UserService,
		private errorService: ErrorService
	) {
		this.controller = 'Flats'
	}


	private getUrl () {
		return routes.api + this.controller + "/"
	}

	setFlat (flat: Flat, addToHistory: boolean = true): void {
		this.flat = flat;
		this.saveFlat (addToHistory).subscribe ();
		this.storageService.updateView ('flat', this.flat)
		this.flatEmitter.emit (this.flat)
	}

	getFlat (): EventEmitter<Flat> {
		return this.flatEmitter
	}

	saveFlat (addToHistory: boolean = true) {

		let err = new Error ()
		let body = new HttpParams ()
		.set ('flat', this.flat.toJson ())
		
		let errorMessage = 'No hemos podido actualizar el cliente. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "updateFlat", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (this.flat)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'flat.service')
				return err
			}
		}))
	}

	getBookings () {

		let err = new Error ()
		let body = new HttpParams ().set ('idFlat', this.flat.getId ())
		let errorMessage = 'No hemos podido obtener las reservas. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "getBookings", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				err.setData (res.data)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'flat.service')
				return err
			}
			

		}))

	}

	createBooking (event: EventClass, costumer: Costumer = new Costumer (), promoter: Agency = new Agency (), typeOfEvent: string = 'costumer') {

		let variableData = {key: "", value: ""}

		if (typeOfEvent == 'costumer') {
			variableData.key = 'idCostumer'
			variableData.value = costumer.getId ().toString ()
		} else {
			variableData.key = 'idPromoter'
			variableData.value = promoter.getId ().toString ()
		}


		let err = new Error ()
		let body = new HttpParams ()
			.set ('idFlat', this.flat.getId ())
			.set ('idEvent', JSON.stringify (event.getId ().toString ()))
			.set ('typeOfEvent', JSON.stringify (typeOfEvent))
			.set (variableData.key, JSON.stringify (variableData.value))
			
		

		let errorMessage = 'No hemos podido añadir la reserva. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "createBooking", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (this.flat)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'flat.service')
				return err
			}
		}))

	}

	getComments (): Observable<Error> {
		return this.commentsServive.getComments (this.flat.getId ())
	}

	getDocuments (): Observable<Error> {
		return this.documentsService.getDocumentsByIdFlat (this.flat.getId ())
	}

	getHistoryLogs (): Observable<Error> {
		return this.historyLogsService.getHistoryLogsByIdFlat (this.flat.getId ())
	}
	
}
