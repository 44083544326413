import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Folder } from 'src/app/classes/homebox/Folder';
import { showSourceTree } from '../../animations/sourceTree.animations';
import { FolderService } from '../../services/folder.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { SourceTreeService } from '../../services/source-tree.service';
import { File } from 'src/app/classes/homebox/File';
import { FileService } from '../../services/file.service';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-folder-selector-item',
	templateUrl: './folder-selector-item.component.html',
	styleUrls: ['./folder-selector-item.component.scss'],
	animations: [showSourceTree]
})
export class FolderSelectorItemComponent implements OnInit, OnDestroy {

	@Input () sourceTree: Folder
	@Input () selectedFolder: Folder
	@Input () type: string
	@Input () selectedFile: File

	moveFolderSub: Subscription = new Subscription ()
	moveFileSub: Subscription = new Subscription ()

	constructor( private folderService: FolderService, private sourceTreeService: SourceTreeService, private fileService: FileService, private errorService: ErrorService ) { }

	ngOnInit() { }

	ngOnDestroy () {
		this.moveFolderSub.unsubscribe ()
		this.moveFileSub.unsubscribe ()
	}

	toggleOpenCloseFolder () {
		this.sourceTree.setOpened (!this.sourceTree.getOpened ())
	}

	selectFolder () {

		if (this.type == 'folder') {

			this.moveFolderSub = this.folderService.moveFolder (this.sourceTree).subscribe ((err: Error) => {
			
				if (err.status == httpStatus.success) {
					this.sourceTreeService.moveFolder (this.selectedFolder, this.sourceTree)
					this.sourceTreeService.setCurrentFolder (this.sourceTree)
					this.folderService.emitFolderSelectorCallback (true)
				}

			},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'folder.selector-item.component.ts'))

		} else {

			this.moveFileSub = this.fileService.moveFile (this.sourceTree).subscribe ((err: Error) => {
			
				if (err.status == httpStatus.success) {
					this.sourceTreeService.moveFile (this.selectedFile, this.sourceTree)
					this.sourceTreeService.setCurrentFolder (this.sourceTree)
					this.fileService.emitFileSelectorCallback (true)
				}
			},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'folder.selector-item.component.ts'))

		}

		
	}


	checkShowFolder () {
		if (!this.sourceTree) { return false }

		if (this.type == 'folder') {
			if (this.sourceTree.getId () != this.selectedFolder.getId ()) {
				return true
			} else { return false }
			
		} else {
			return true
		}
		
	}
}
