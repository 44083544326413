import { Flat } from './Flat';
import { Floor } from './Floor';
import { Zone } from './Zone';
import { Promotion } from './Promotion';

export class AlertClass {

	id: number = 0
	id_flat: number = 0
	content: string = ""
	flat: Flat = new Flat ()
	floor: Floor = new Floor ()
	zone: Zone = new Zone ()
	promotion: Promotion = new Promotion ()

	constructor (data: any = {}) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setIdFlat ((typeof data != "undefined" && typeof data.id_flat != "undefined") ? data.id_flat : 0)
		this.setContent ((typeof data != "undefined" && typeof data.content != "undefined") ? data.content : "")
		this.setFlat ((typeof data != "undefined" && typeof data.flat != "undefined") ? data.flat : new Flat ())
		this.setFloor ((typeof data != "undefined" && typeof data.floor != "undefined") ? data.floor : new Floor ())
		this.setZone ((typeof data != "undefined" && typeof data.zone != "undefined") ? data.zone : new Zone ())
		this.setPromotion ((typeof data != "undefined" && typeof data.promotion != "undefined") ? data.promotion : new Promotion ())
	}

	setId (id: number = 0) { this.id = id }
	setIdFlat (id: number = 0) { this.id_flat = id }
	setContent (content: string = "") { this.content = content }
	setFlat (flat: Flat = new Flat ()) { this.flat = flat }
	setFloor (floor: Floor = new Floor ()) { this.floor = floor }
	setZone (zone: Zone = new Zone ()) { this.zone = zone }
	setPromotion (promotion: Promotion = new Promotion ()) { this.promotion = promotion }

	getId (): number { return this.id }
	getIdFlat (): number { return this.id_flat }
	getContent (): string { return this.content }
	getFlat (): Flat { return this.flat }
	getFloor (): Floor { return this.floor }
	getZone (): Zone { return this.zone }
	getPromotion (): Promotion { return this.promotion }

	

}