import { Routes } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { HometouchComponent } from './hometouch/hometouch/hometouch.component';
import { HOMETOUCH_ROUTES } from './hometouch/hometouch-routing.module';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { DASHBOARD_ROUTES } from './dashboard/dashboard-routing.module';
import { CalendarViewComponent } from './calendar/calendar/calendar-view.component';
import { CALENDAR_ROUTES } from './calendar/calendar-routing.module';
import { PREFERENCES_ROUTES } from './preferences/preferences-routing.module';
import { PreferencesComponent } from './preferences/preferences/preferences.component';
import { HomeboxComponent } from './homebox/homebox/homebox.component';
import { HOMEBOX_ROUTES } from './homebox/homebox-routing.module';
import { LoginGuardGuard } from '../services/guards/login-guard.guard';



const routes: Routes = [

	{ path: '', component: PagesComponent, canActivate: [ LoginGuardGuard ], children: [
		{ path: 'calendar', component: CalendarViewComponent, children: CALENDAR_ROUTES },
		{ path: 'hometouch', component: HometouchComponent, children: HOMETOUCH_ROUTES },
		{ path: 'dashboard', component: DashboardComponent, children: DASHBOARD_ROUTES },
		{ path: 'preferences', component: PreferencesComponent, children: PREFERENCES_ROUTES },
		{ path: 'homebox', component: HomeboxComponent, children: HOMEBOX_ROUTES },
		//{ path: '**', component: DashboardComponent },
		{ path: '', pathMatch: 'full', redirectTo: '/dashboard' }
	]},

];

export const PAGES_ROUTES = routes
