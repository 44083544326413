import { Component, OnInit, OnDestroy } from '@angular/core';
import { Error } from 'src/app/classes/Error';
import { LocalDataSource } from 'ng2-smart-table';
import { httpStatus } from 'src/app/config/httpStatus';
import { Commercial } from 'src/app/classes/commercial';
import { CommercialsService } from 'src/app/services/commercials.service';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-commercials',
	templateUrl: './commercials.component.html',
	styleUrls: ['./commercials.component.scss'],
	host: {class: 'content-commercials'}
})
export class CommercialsComponent implements OnInit, OnDestroy {

	commercials: Commercial[]
	commercialSelected: Commercial
	showCommercialPopup: boolean
	dialog: any

	settings = {
		noDataMessage: 'Ups..! No hemos encontrado resultados',
		columns: {
		  	name: { title: 'Nombre', editable: false },
		  	position: { title: 'Cargo', editable: false },
		},
		actions: {
			add: false,
			edit: false,
			delete: false,
			custom: [
				{
					name: 'edit',
					title: '<i class="icon-list-preferences fas fa-pen"></i>',
			  	},
			  	{
					name: 'delete',
					title: '<i class="icon-list-preferences fas fa-trash"></i>',
				},
			],
		  },
	}

	source: LocalDataSource

	getAllCommercialsSubscription: Subscription = new Subscription ()
	removeCommercialSubscription: Subscription = new Subscription ()

	constructor ( private commercialsService: CommercialsService, private errorService: ErrorService ) {
		this.commercials = []
		this.source = new LocalDataSource ()
		this.showCommercialPopup = false
		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este comercial?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() {

		this.getAllCommercialsSubscription = this.commercialsService.getAllCommercials ().subscribe ((res: Error) => {
			
			if (res.status = httpStatus.success) {
				this.commercials = res.getData ()
				
				this.loadCommercialsOnTable ()
			}

		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'commercials.component.ts'))
	}

	ngOnDestroy () {

		this.getAllCommercialsSubscription.unsubscribe ()
		this.removeCommercialSubscription.unsubscribe ()
	}

	loadCommercialsOnTable () {

		let commercialsForTable = []

		for (let commercial of this.commercials) {
			let costum = {
				id: commercial.getId (),
				name: commercial.getName (),
				position: commercial.getPosition (),
				
			}
			commercialsForTable.push (costum)	
		}

		this.source.load (commercialsForTable)
	}

	rowActionClick (event) {

		this.commercialSelected = this.commercials.find ((commercial:Commercial) => commercial.getId () == event.data.id)

		if (event.action == 'edit') {
			this.editCommercial ()
		} else {
			this.removeCommercial ()
		}
	}

	editCommercial () {

		this.showCommercialPopup = true
	}

	closePopupCommercialCallback (flag: boolean = false) {

		this.showCommercialPopup = false

		if (!flag && this.commercials[this.commercials.length - 1].getId () <= 0) {
			this.commercials.splice (-1, 1);
		}

		this.loadCommercialsOnTable ()
	}

	createCommercial () {

		this.commercials.push (new Commercial ())
		this.commercialSelected = this.commercials[this.commercials.length - 1]
		this.showCommercialPopup = true
	}

	removeCommercial () {

		this.dialog.show = true
	}

	dialogRemoveCommercialCallback (flag: boolean) {

		this.dialog.show = false

		if (flag) {
			
			this.removeCommercialSubscription = this.commercialsService.removeCommercial (this.commercialSelected.getId ()).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {

					this.commercials.splice (this.commercials.indexOf (this.commercialSelected), 1)
					this.loadCommercialsOnTable ()
				}

			}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'commercials.component.ts'))	
		}
	}
}
