import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgenciesService } from 'src/app/services/agencies.service';
import { Error } from 'src/app/classes/Error';
import { Agency } from 'src/app/classes/Agency';
import { httpStatus } from 'src/app/config/httpStatus';
import { LocalDataSource } from 'ng2-smart-table';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr'
import { ErrorService } from 'src/app/services/error.service'
import { HttpErrorResponse } from '@angular/common/http';
import { FolderService } from '../../homebox/services/folder.service';
import { Folder } from 'src/app/classes/homebox/Folder';

@Component({
	selector: 'app-agencies',
	templateUrl: './agencies.component.html',
	styleUrls: ['./agencies.component.scss'],
	host: {class: 'content-agencies'}
})
export class AgenciesComponent implements OnInit, OnDestroy {

	agencies: Agency[]
	agencySelected: Agency
	showAgencyPopup: boolean
	dialog: any
	componentName: string = 'AgenciesComponent'
	showAgencyFlatsPopup: boolean
	showHomebox: boolean

	settings = {
		noDataMessage: 'Ups..! No hemos encontrado resultados',
		columns: {
		  	name: { title: 'Nombre', editable: false, width: '300px' },
		  	email: { title: 'E-mail', editable: false, width: '300px' },
			phone: { title: 'Teléfono', editable: false },
			market: { title: 'Mercado', editable: false },
			webpage: { title: 'Página web', editable: false, width: '300px' },
		},
		actions: {
			add: false,
			edit: false,
			delete: false,
			custom: [
				{
					name: 'watch',
					title: '<i class="icon-list-preferences fas fa-home"></i>',
				},
				{
					name: 'edit',
					title: '<i class="icon-list-preferences fas fa-pen"></i>',
				},
				
				{
					name: 'homebox',
					title: '<i class="icon-list-preferences fas fa-box-open"></i>',
				},
				{
					name: 'delete',
					title: '<i class="icon-list-preferences fas fa-trash"></i>',
				},
			],
		  },
	}

	source: LocalDataSource

	getAgenciesSubscription: Subscription = new Subscription ()
	removeAgencySubscription: Subscription = new Subscription ()
	removeAgencyFolderSubscription: Subscription = new Subscription ()

	constructor ( private agenciesService: AgenciesService, private toastr: ToastrService, private errorService: ErrorService, private folderService: FolderService ) {

		this.agencies = []
		this.source = new LocalDataSource ()
		this.showAgencyPopup = false
		this.showAgencyFlatsPopup = false
		this.showHomebox = false
		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar esta agencia? También se eliminarán los eventos de TBYB y los archivos enlazados a esta agencia',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() {

		this.getAgenciesSubscription = this.agenciesService.getAgencies ().subscribe ((res: Error) => {

			if (res.status = httpStatus.success) {
				this.agencies = res.getData ()
				
				this.loadAgenciesOnTable ()
			}

		}, (error: HttpErrorResponse) => {

			this.errorService.notifyMainError (error, 'agencies.component.ts')
		})
	}


	ngOnDestroy () {

		this.getAgenciesSubscription.unsubscribe ()
		this.removeAgencySubscription.unsubscribe ()
		this.removeAgencyFolderSubscription.unsubscribe ()
	}


	loadAgenciesOnTable () {

		let agenciesForTable = []

		for (let agency of this.agencies) {
			let costum = {
				id: agency.getId (),
				name: agency.getName (),
				email: agency.getEmail (),
				phone: agency.getPhone (),
				market: agency.getMarketClass ().getName (),
				webpage: agency.getWebpageFormated ()
			}
			agenciesForTable.push (costum)	
		}

		this.source.load (agenciesForTable)
	}


	rowActionClick (event) {

		this.agencySelected = this.agencies.find ((agency: Agency) => agency.getId () == event.data.id)

		if (event.action == 'edit') {
			this.editAgency ()
		} else if (event.action == 'watch') {
			this.watchAgency ()
		} else if (event.action == 'homebox') {
			this.openHomebox ()
		} else {
			this.removeAgency ()
		}
	}

	openHomebox () {
		this.showHomebox = true
	}

	closeHomebox (event) {
		this.showHomebox = false
	}

	editAgency () {

		this.showAgencyPopup = true
	}

	closePopupAgencyCallback (flag: boolean = false) {

		this.showAgencyPopup = false
		
		if (!flag && this.agencies[this.agencies.length - 1].getId () <= 0) {
			this.agencies.splice (-1, 1);
		}

		this.loadAgenciesOnTable ()
	}

	createAgency () {

		this.agencies.push (new Agency ())
		this.agencySelected = this.agencies[this.agencies.length - 1]
		this.showAgencyPopup = true
	}

	removeAgency () {

		this.dialog.show = true
	}

	dialogRemoveAgencyCallback (flag: boolean) {

		this.dialog.show = false

		if (flag) {
			
			this.removeAgencySubscription = this.agenciesService.removeAgency (this.agencySelected).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {

					let folder = new Folder ({id: this.agencySelected.getIdFolder ()});

					this.removeAgencyFolderSubscription = this.folderService.removeFolder (folder).subscribe ((resFolder: any) => {

						if (resFolder.status == httpStatus.success) {
							this.agencies.splice (this.agencies.indexOf (this.agencySelected), 1)
							this.loadAgenciesOnTable ()
						}

					}, (error: HttpErrorResponse) => {

						this.errorService.notifyMainError (error, 'agencies.component.ts')
					})

					
				}
				
			}, (error: HttpErrorResponse) => {

				this.errorService.notifyMainError (error, 'agencies.component.ts')
			})	
		}
	}

	watchAgency () {
		this.showAgencyFlatsPopup = true
	}

	closePopupAgencyFlatsCallback (flag: boolean = false) {

		this.showAgencyFlatsPopup = false
	}
	
}
