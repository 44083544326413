import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { Error } from '../classes/Error';
import { HistoryLog } from '../classes/HistoryLog';
import { Status } from '../classes/Status';
import { User } from '../classes/User';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class HistoryLogsService {

	controller: string

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller = 'HistoryLogs'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}

	getHistoryLogsByIdFlat (id: number = 0) {
		
		let err = new Error ()
		let HistoryLogClasses = []
		let body = new HttpParams ().set ('id', id.toString ())
		let errorMessage = 'No hemos podido encontrar los registros. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "getHistoryLogsByIdFlat", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				for (let historyLogArray of res.data) {

					historyLogArray.created_at = new Date (historyLogArray.created_at)
					let historyLog = new HistoryLog (historyLogArray)
					historyLog.setStatus (new Status (historyLog.status))
					historyLog.setUser (new User (historyLog.user))
					HistoryLogClasses.push (historyLog)
				}

				err.setData (HistoryLogClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'history-logs.service')
				return err
			}
		}))
	}

	addHistoryLog (historyLog: HistoryLog) {

		let err = new Error ()
		let body = new HttpParams ().set ('historyLog', historyLog.toJson ())
		let errorMessage = 'No hemos podido añadir el item al historial. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "addHistoryLog", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'history-logs.service')
				return err
			}
		}))
	}
}
