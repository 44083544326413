import { Status } from './Status';
import { Promoter } from './Promoter';
import { routes } from './../config/routes'
import { Costumer } from './Costumer';
import { Lawyer } from './Lawyer';
import { Agency } from './Agency';
import { Promotion } from './Promotion';
import { Zone } from './Zone';


export interface FlatInterface {
	id: number
	id_floor: number
	id_status: number
	id_promoter: number
	id_costumer: number
	id_lawyer: number
	name: string
	price: number
	beds: number
	baths: number
	reference: string
	orientation: number
	plan_path: string
	coords_map: string
	created_at: Date
	updated_at: Date
	reserved_at: Date
	registered_at: Date
	useful_surface: number
	terrace: number
	solarium: number
	garden: number
	total_surface: number
	rent_price: number
	has_been_cancelled: any
	order: number
	id_folder: number
	has_tbyb: number
	status: Status
	promoter: Agency
	costumer: Costumer
	lawyer: Lawyer
	showAnimation: boolean
	promotion: Promotion
	zone: Zone

	getId (): number
	getIdFloor (): number
	getIdStatus (): number
	getIdPromoter (): number
	getIdCostumer (): number
	getIdLawyer (): number
	getName (): string
	getPrice (): number
	getBeds (): number
	getBaths (): number
	getReference (): string
	getOrientation (): number
	getPlanPath (): string
	getCoordsMap (): string
	getCreatedAt (): Date
	getUpdatedAt (): Date
	getReservedAt (): Date
	getRegisteredAt (): Date
	getUsefulSurface (): number
	getTerrace (): number
	getSolarium (): number
	getGarden (): number
	getTotalSurface (): number
	getRentPrice (): number
	getHasBeenCancelled (): boolean
	getIdFolder (): number
	getHasTbyb (): number
	getOrder (): number
	getStatus (): Status
	getPromoter (): Agency
	getCostumer (): Costumer
	getLawyer (): Lawyer
	getShowAnimation (): boolean
	getPromotion (): Promotion
	getZone (): Zone

	setId (id: number): void
	setIdFloor (id: number): void
	setIdStatus (id: number): void
	setIdPromoter (id: number): void
	setIdLawyer (): void
	setIdCostumer (id: number): void
	setName (name: string): void
	setPrice (price: number): void
	setBeds (beds: number): void
	setBaths (baths: number): void
	setReference (reference: string): void
	setOrientation (orientation: number): void
	setPlanPath (path: string): void
	setCoordsMap (cords: string): void
	setCreatedAt (date: Date): void
	setUpdatedAt (date: Date): void
	setReservedAt (date: Date): void
	setRegisteredAt (date: Date): void
	setUsefulSurface (surface: number): void
	setTerrace (terrace: number): void
	setSolarium (solarium: number): void
	setGarden (garden: number): void
	setTotalSurface (surface: number): void
	setRentPrice (price: number): void
	setHasBeenCancelled (hasBeenCancelled: boolean): void
	setIdFolder (idFolder: number): void
	setHasTbyb (hasTbyb: number): void
	setOrder (order: number): void
	setStatus (status: Status): void
	setPromoter (promoter: Promoter): void
	setCostumer (costumer: Costumer): void
	setLawyer (lawyer: Lawyer): void
	setShowAnimation (flag): void
	setPromotion (promotion): void
	setZone (zone): void

	toJson (): string
	toArray (): any

}

export class Flat implements FlatInterface {

	id
	id_floor
	id_status
	id_promoter
	id_costumer
	id_lawyer
	name
	price
	beds
	baths
	reference
	orientation
	plan_path
	coords_map
	created_at
	updated_at
	reserved_at
	registered_at
	useful_surface
	terrace
	solarium
	garden
	total_surface
	rent_price
	has_been_cancelled
	id_folder
	has_tbyb
	order
	status
	promoter
	costumer
	lawyer
	showAnimation
	promotion
	zone

	constructor (data?: FlatInterface) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setIdFloor ((typeof data != "undefined" && typeof data.id_floor != "undefined") ? data.id_floor : 0)
		this.setIdStatus ((typeof data != "undefined" && typeof data.id_status != "undefined") ? data.id_status : 0)
		this.setIdPromoter ((typeof data != "undefined" && typeof data.id_promoter != "undefined") ? data.id_promoter : 0)
		this.setIdCostumer ((typeof data != "undefined" && typeof data.id_costumer != "undefined") ? data.id_costumer : 0)
		this.setIdLawyer ((typeof data != "undefined" && typeof data.id_lawyer != "undefined") ? data.id_lawyer : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setPrice ((typeof data != "undefined" && typeof data.price != "undefined") ? data.price : 0)
		this.setBeds ((typeof data != "undefined" && typeof data.beds != "undefined") ? data.beds : 0)
		this.setBaths((typeof data != "undefined" && typeof data.baths != "undefined") ? data.baths : 0)
		this.setReference ((typeof data != "undefined" && typeof data.reference != "undefined") ? data.reference : "")
		this.setOrientation ((typeof data != "undefined" && typeof data.orientation != "undefined") ? data.orientation : 0)
		this.setPlanPath ((typeof data != "undefined" && typeof data.plan_path != "undefined") ? data.plan_path : "")
		this.setCoordsMap ((typeof data != "undefined" && typeof data.coords_map != "undefined") ? data.coords_map : "")
		this.setCreatedAt ((typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : null)
		this.setUpdatedAt ((typeof data != "undefined" && typeof data.updated_at != "undefined") ? data.updated_at : null)
		this.setReservedAt ((typeof data != "undefined" && typeof data.reserved_at != "undefined" && data.reserved_at != null) ? new Date (data.reserved_at) : null)
		this.setRegisteredAt ((typeof data != "undefined" && typeof data.registered_at != "undefined" && data.registered_at != null) ? new Date (data.registered_at) : null)
		this.setUsefulSurface ((typeof data != "undefined" && typeof data.useful_surface != "undefined") ? data.useful_surface : 0)
		this.setTerrace ((typeof data != "undefined" && typeof data.terrace != "undefined") ? data.terrace : 0)
		this.setSolarium ((typeof data != "undefined" && typeof data.solarium != "undefined") ? data.solarium : 0)
		this.setGarden ((typeof data != "undefined" && typeof data.garden != "undefined") ? data.garden : 0)
		this.setTotalSurface ((typeof data != "undefined" && typeof data.total_surface != "undefined") ? data.total_surface : 0)
		this.setRentPrice ((typeof data != "undefined" && typeof data.rent_price != "undefined") ? data.rent_price : 0)
		this.setHasBeenCancelled ((typeof data != "undefined" && typeof data.has_been_cancelled != "undefined"  && data.has_been_cancelled == true) ? true : false)
		this.setIdFolder ((typeof data != "undefined" && typeof data.id_folder != "undefined") ? data.id_folder : 0)
		this.setHasTbyb ((typeof data != "undefined" && typeof data.has_tbyb != "undefined") ? data.has_tbyb : 0)
		this.setOrder ((typeof data != "undefined" && typeof data.order != "undefined") ? data.order : 0)
		this.setStatus ((typeof data != "undefined" && typeof data.status != "undefined") ? data.status : new Status ())
		this.setPromoter ((typeof data != "undefined" && typeof data.promoter != "undefined") ? data.promoter : new Agency ())
		this.setCostumer ((typeof data != "undefined" && typeof data.costumer != "undefined") ? data.costumer : new Costumer ())
		this.setLawyer ((typeof data != "undefined" && typeof data.lawyer != "undefined") ? data.lawyer : new Lawyer ())
		this.setShowAnimation ((typeof data != "undefined" && typeof data.showAnimation != "undefined") ? data.showAnimation : false)
		this.setPromotion ((typeof data != "undefined" && typeof data.promotion != "undefined") ? data.promotion : new Promotion ())
		this.setZone ((typeof data != "undefined" && typeof data.zone != "undefined") ? data.zone : new Zone ())
	}

	getId () { return this.id }
	getIdFloor () { return this.id_floor }
	getIdStatus () { return this.id_status }
	getIdPromoter () { return this.id_promoter }
	getIdCostumer () { return this.id_costumer }
	getIdLawyer () { return this.id_lawyer }
	getName () { return this.name }
	getPrice () { return this.price }
	getBeds () { return this.beds }
	getBaths () { return this.baths }
	getReference () { return this.reference }
	getOrientation () { return this.orientation }
	getPlanPath () { return (this.plan_path != "") ? routes.media + "flats/" + this.plan_path : null }
	getCoordsMap () { return this.coords_map }
	getCreatedAt () { return this.created_at }
	getUpdatedAt () { return this.updated_at }
	getReservedAt () { return this.reserved_at }
	getRegisteredAt () { return this.registered_at }
	getUsefulSurface () { return this.useful_surface }
	getTerrace () { return this.terrace }
	getSolarium () { return this.solarium }
	getGarden () { return this.garden }
	getTotalSurface () { return this.total_surface }
	getRentPrice () { return this.baths.rent_price }
	getHasBeenCancelled () { return this.has_been_cancelled }
	getIdFolder () { return this.id_folder }
	getHasTbyb () { return this.has_tbyb }
	getOrder () { return this.order }
	getStatus () { return this.status }
	getPromoter () { return this.promoter }
	getCostumer () { return this.costumer }
	getLawyer () { return this.lawyer }
	getShowAnimation () { return this.showAnimation }
	getPromotion () { return this.promotion }
	getZone () { return this.zone }

	setId (id = 0) { this.id = id }
	setIdFloor (id = 0) { this.id_floor = id }
	setIdStatus (id = 0) { this.id_status = id }
	setIdPromoter (id = 0) { this.id_promoter = id }
	setIdCostumer (id = 0) { this.id_costumer = id }
	setIdLawyer (id = 0) { this.id_lawyer = id }
	setName (name = "") { this.name = name }
	setPrice (price = 0) { this.price = price }
	setBeds (beds = 0) { this.beds = beds }
	setBaths (baths = 0) { this.baths = baths }
	setReference (reference = "") { this.reference = reference }
	setOrientation (orientation = 0) { this.orientation = orientation }
	setPlanPath (path = "") { this.plan_path = path }
	setCoordsMap (coords = "") { this.coords_map = coords }
	setCreatedAt (createdAt = null) { this.created_at = createdAt }
	setUpdatedAt (updatedAt = null) { this.updated_at = updatedAt }
	setReservedAt (reservedAt = null) { this.reserved_at = reservedAt }
	setRegisteredAt (registeredAt = null) { this.registered_at = registeredAt }
	setUsefulSurface (surface = 0) { this.useful_surface = surface }
	setTerrace (terrace = 0) { this.terrace = terrace }
	setSolarium (solarium = 0) { this.solarium = solarium }
	setGarden (garden = 0) { this.garden = garden }
	setTotalSurface (surface = 0) { this.total_surface = surface }
	setRentPrice (price = 0) { this.rent_price = price }
	setHasBeenCancelled (has_been_cancelled = false) { this.has_been_cancelled = has_been_cancelled }
	setIdFolder (id_folder = 0) { this.id_folder = id_folder }
	setHasTbyb (has_tbyb = 0) { this.has_tbyb = has_tbyb }
	setOrder (order = 0) { this.order = order }
	setStatus (status) { this.status = status }
	setPromoter (promoter) { this.promoter = promoter; }
	setCostumer (costumer) { this.costumer = costumer }
	setLawyer (lawyer) { this.lawyer = lawyer }
	setShowAnimation (flag = false) { this.showAnimation = flag }
	setPromotion (promotion) { this.promotion = promotion }
	setZone (zone) { this.zone = zone }

	toJson () {
		return JSON.stringify (this.toArray ())
	}

	toArray () {

		return {
			id: this.getId (),
			id_floor: this.getIdFloor (),
			id_status: this.getIdStatus (),
			id_promoter: this.getIdPromoter (),
			id_costumer: this.getIdCostumer (),
			id_lawyer: this.getIdLawyer (),
			name: this.getName (),
			price: this.getPrice (),
			beds: this.getBeds (),
			baths: this.getBaths (),
			reference: this.getReference (),
			orientation: this.getOrientation (),
			plan_path: "",
			useful_surface: this.getUsefulSurface (),
			terrace: this.getTerrace (),
			solarium: this.getSolarium (),
			garden: this.getGarden (),
			total_surface: this.getTotalSurface (),
			rent_price: this.getRentPrice (),
			id_folder: this.getIdFolder (),
			has_been_cancelled: (this.getHasBeenCancelled ()) ? 1 : 0,
			has_tbyb: this.getHasTbyb (),
			reserved_at: (this.getReservedAt () != null && this.getReservedAt ().getFullYear () != 1970) ? this.convertDate (this.getReservedAt ()) : null,
			registered_at: (this.getRegisteredAt () != null && this.getRegisteredAt ().getFullYear () != 1970) ? this.convertDate (this.getRegisteredAt ()) : null,
		}

	}

	private convertDate (inputFormat) {
		function pad(s) { return (s < 10) ? '0' + s : s; }
		var d = new Date(inputFormat);
		return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('-');
	  }
	

}