import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from '../config/routes';
import { Commercial } from '../classes/commercial';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { Error } from '../classes/Error';
import { EventClass } from '../classes/calendar/Event';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class CommercialsService {

	private controller: string

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller = 'Commercials'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}
	
	public getAllCommercials () {

		let commercialsClasses: Commercial[] = []
		let err = new Error ()
		let errorMessage = 'No hemos podido obtener los comerciales. Vuelve a intentarlo'

		return this.http.get (this.getUrl () + "getAllCommercials").pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {
				let commercials = res.data

				for (let commercial of commercials) {
					
					commercialsClasses.push(new Commercial (commercial))
				}

				err.setData (commercialsClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'commercials.service')
				return err
			}
		}))
	}

	public addCommercial (commercial: Commercial) {

		let err = new Error ()
		let body = new HttpParams ().set ('commercial', commercial.toJson ())
		let errorMessage = 'No hemos podido añadir al comercial. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "addCommercial", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				let data: any = res.data

				if (typeof data.id != "undefined" && data.id != null && data.id > 0) {
					commercial.setId (data.id)
				}

				err.setData (commercial)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'commercials.service')
				return err
			}
		}))
	}

	public removeCommercial (idCommercial: number) {

		let err = new Error ()
		let body = new HttpParams ().set ('idCommercial', idCommercial.toString ())
		let errorMessage = 'No hemos podido borrar el cliente. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "removeCommercial", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (idCommercial)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'commercials.service')
				return err
			}
		}))
	}


	getEvents (commercial: Commercial = new Commercial ()) {

		let err = new Error ()
		let body = new HttpParams ().set ('idCommercial', commercial.getId ().toString ())
		let errorMessage = 'No hemos podido obtener los eventos. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "getEvents", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				err.setData (res.data)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'commercials.service')
				return err
			}
		}))
	}

	createEvent (event: EventClass, commercial: Commercial) {

		let err = new Error ()
		let body = new HttpParams ()
			.set ('idEvent', JSON.stringify (event.getId ().toString ()))
			.set ('idCommercial', commercial.getId ().toString ())
		let errorMessage = 'No hemos podido añadir la reserva. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "createEvent", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (event)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'commercials.service')
				return err
			}
		}))

	}

	removeEvent (event: EventClass) {

		let err = new Error ()
		let body = new HttpParams ().set ('idEvent', JSON.stringify (event.getId ().toString ()))
		let errorMessage = 'No hemos podido eliminar la reserva. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "removeEvent", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (event)
				return err

			} else {
				
				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'commercials.service')
				return err

			}
		}))
	}
}
