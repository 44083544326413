import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { SourceTreeService } from '../services/source-tree.service';
import { DEFAULT_DROPZONE_CONFIG } from 'src/app/config/dropzoneSettings';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { httpStatus } from 'src/app/config/httpStatus';
import { File } from 'src/app/classes/homebox/File';
import { ErrorService } from 'src/app/services/error.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-homebox',
	templateUrl: './homebox.component.html',
	styleUrls: ['./homebox.component.scss'],
	host: {class: 'content-homebox'}
})

export class HomeboxComponent implements OnInit, AfterViewInit, OnDestroy {

	public showDropzoneFlag: boolean
	private dropzoneSuspended: boolean
	public showProgressBar: boolean
	private percentageProgressbar: number
	private dropzoneConfig: DropzoneConfigInterface
	@ViewChild ('contenedorgeneral') contenedorGeneral: ElementRef
	@Input ('idRootFolder') idRootFolder: number
	@Output () closeHomeboxCallback: EventEmitter<boolean> = new EventEmitter<boolean> ()

	getSourceTreeFromDatabaseSub: Subscription = new Subscription ()

	constructor( private sourceTreeService: SourceTreeService, private errorService: ErrorService ) {
		this.dropzoneConfig = DEFAULT_DROPZONE_CONFIG
		this.showDropzoneFlag = false
		this.dropzoneSuspended = false
		this.showProgressBar = false
		this.percentageProgressbar = 0
	}

	ngOnInit() {

		this.contenedorGeneral.nativeElement.addEventListener ('dragenter', (e) => {

			if (e.dataTransfer.items[0].kind == 'file') {
				this.showDropZone ()
			}	
		})
	}

	ngOnDestroy () {
		this.getSourceTreeFromDatabaseSub.unsubscribe ()
	}

	openFileDialog () {
		this.showDropzoneFlag = true
		
	}

	ngAfterViewInit () {
		//init sourceTree
		this.getSourceTreeFromDatabaseSub = this.sourceTreeService.getSourceTreeFromDatabase (this.idRootFolder).subscribe ()
	}

	showDropZone () { this.showDropzoneFlag = true }
	hideDropZone () {
		this.showDropzoneFlag = false
		this.dropzoneSuspended = false
	}

	allowDrag(e:any) {

		e.preventDefault ()
		e.stopPropagation()

		e.dataTransfer.files[0]

		if (true) {  // Test that the item being dragged is a valid one
			e.dataTransfer.dropEffect = 'copy';
			e.preventDefault();
		}
	}


	dropzoneAddedFile () {
		
		this.dropzoneSuspended = true
		this.showProgressBar = true
	}

	dropzoneSuccessEvent (event) {

		let res = JSON.parse(event[1])
		
		if (res.status == httpStatus.success) {
			
			let file = new File ()
			let fileArray = res.data.file

			file.setId (fileArray.id)
			file.setIdFolder (fileArray.id_folder)
			file.setRgb (fileArray.rgb)
			file.setName (fileArray.name)
			file.setUrl (fileArray.url)
			file.setCreatedAt (fileArray.created_at)

			this.sourceTreeService.addFile (file)
		}
	}

	dropzoneErrorEvent (event) {
		alert (event)
	}

	dropZoneSendingEvent (event) {
		event[2].append("idParentFolder", this.sourceTreeService.currentFolder.getId ());
	}

	dropzoneQueuecomplete (event) {
		this.hideDropZone ()	
	}

	updateTotalUploadProgress (event) {
		this.percentageProgressbar = event[0]

		if (this.percentageProgressbar >= 100) {
			setTimeout (() => { this.showProgressBar = false }, 2000)
		}
	}


	emitCloseEventCallback () {
		this.closeHomeboxCallback.emit (true)
	}


}
