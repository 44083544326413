import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarViewComponent } from './calendar/calendar-view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FullCalendarModule } from 'ng-fullcalendar';
import { ComponentsModule } from 'src/app/components/components.module';
import { MaterialModule } from 'src/app/modules/material.module';




@NgModule({
	declarations: [
		CalendarViewComponent,
	],

	imports: [
		CommonModule,
		SharedModule,
		FullCalendarModule,
		ComponentsModule,
		MaterialModule
	],

	exports: [
		CalendarViewComponent
	]
})
export class CalendarModule { }
