import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';
import { GoogleCalendarService } from 'src/app/services/google-calendar.service';
import { GoogleAouthService } from 'src/app/services/google-aouth.service';
import { Calendar } from 'src/app/classes/calendar/Calendar';
import { EventClass } from 'src/app/classes/calendar/Event';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { calendarsIdsEditables } from 'src/app/config/googleCalendarSettings';
import { ToastrService } from 'ngx-toastr'

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})
export class CalendarViewComponent implements OnInit, OnDestroy {

	calendarOptions: Options
	calendars: Calendar[]
	eventEditting: EventClass
	showPopupEvent: boolean
	showEventTools: boolean
	typeOfEdit: string
	dialog: any
	userSignInSubscription: Subscription = new Subscription ()


	@ViewChild('ucCalendar') ucCalendar: CalendarComponent;

	constructor(private googleCalendarService: GoogleCalendarService, private googleAouthService: GoogleAouthService, private zone: NgZone, private toastr: ToastrService) {
		this.calendars = []
		this.eventEditting = new EventClass ()
		this.showPopupEvent = false
		this.showEventTools = false
		this.typeOfEdit = 'create' //edit - create
		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este evento?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() {

		this.userSignInSubscription = this.googleAouthService.userIsSignedIn ().subscribe (() => {
			this.afterSignIn ()
		})

		this.googleAouthService.initClient ()
	}

	ngOnDestroy () {
		this.userSignInSubscription.unsubscribe ()
	}


	private afterSignIn () {

		this.initCalendar ()
		let count = 0

		this.googleCalendarService.getCalendarsFromApi ().then ((calendars: Calendar[]) => {
			this.zone.run (() => {
				this.calendars = calendars

				for (let calendar of this.calendars) {

					this.googleCalendarService.getEventsFromApi (calendar.getId ()).then ((events: EventClass[]) => {

						count += 1;
						calendar.setEvents (events)

						if (count == this.calendars.length) {
							this.fillCalendar ()
						}
					})
				}
			})
		})
	}


	private fillCalendar () {

		let events = []

		for (let calendar of this.calendars) {
			events = events.concat (calendar.getEventsForFullCalendar ())
		}

		this.ucCalendar.renderEvents (events)
	}

	public addNewEvent (dateStart: moment.Moment = moment ()) {

		let event = new EventClass ()
		let date = moment (dateStart.format ())

		if (parseInt (date.hours ().toString ()) == 0) {
			date.hours (moment ().hours ()).minutes (moment ().minutes ())
		}

		event.setStart (date)
		event.setEnd (moment (date.format ()).add(1, 'hours'))

		this.typeOfEdit = 'create'
		this.eventEditting = event
		this.showPopupEvent = true
	}

	private editEvent (event: EventClass) {

		this.showEventTools = false

		this.typeOfEdit = 'edit'
		this.eventEditting = event
		this.showPopupEvent = true
	}

	//callback popup event
	public eventAdded (event: EventClass) {

		this.toggleEventPopup(false)

		if (event.getId () != null) {

			let calendar: Calendar = this.calendars.find ((calendar: Calendar) => calendar.getId () == event.getCalendarId ())

			calendar.addEvent (event)

			this.fillCalendar ()
		}
	}

	//callback popup event
	public eventUpdated (event: EventClass) {
		this.toggleEventPopup(false)

		if (event.getId () != null) {

			let calendar: Calendar = this.calendars.find ((calendar: Calendar) => calendar.getId () == event.getCalendarId ())
			calendar.replaceEvent (event)

			this.fillCalendar ()
		}

	}


	// ---------- EVENTS FROM FULL CALENDAR

	public dayClick (data) {

		this.addNewEvent (data.detail.date)
	}

	public eventClick (data) {

		let srcElement = data.detail.jsEvent.currentTarget

		let eventCalendar = this.getEventClassFromEventFullCalendar (data.detail.event)
		let calendar = eventCalendar.calendar

		if (calendarsIdsEditables.indexOf (calendar.getId ()) >= 0) {
			this.eventEditting = eventCalendar.event

			if (calendar.getRole () == 'owner') {
				this.eventEditting.setFullCalendarEvent (srcElement)
				this.toggleEventTools (true)
			} else {
				this.toggleEventTools (false)
			}
		} else {
			this.toastr.warning ('Este evento no lo puedes editar desde aquí', 'Espera...')
		}
	}

	public eventDrop (data) {

		let event = this.getEventClassFromEventFullCalendar (data.detail.event).event

		event.setStart (moment (data.detail.event.start.format ()))
		event.setEnd (moment (data.detail.event.end.format ()))

		this.googleCalendarService.updateEventOnApi (event).then ((event: EventClass) => {
			let calendar = this.getEventClassFromEventFullCalendar (data.detail.event).calendar
			calendar.replaceEvent (event)
			this.fillCalendar ()
		})
	}

	public eventResize (data) {

		let event = this.getEventClassFromEventFullCalendar (data.detail.event).event

		event.setStart (moment (data.detail.event.start.format ()))
		event.setEnd (moment (data.detail.event.end.format ()))

		this.googleCalendarService.updateEventOnApi (event).then ((event: EventClass) => {
			let calendar = this.getEventClassFromEventFullCalendar (data.detail.event).calendar
			calendar.replaceEvent (event)
			this.fillCalendar ()
		})
	}

	// ---------- END EVENTS FROM FULL CALENDAR


	public toggleEventPopup (flag: boolean = false) {
		this.showPopupEvent = flag
	}

	public toggleEventTools (flag: boolean = false) {
		this.showEventTools = flag
	}

	public removeEvent () {
		this.toggleEventTools (false)

		if (calendarsIdsEditables.indexOf (this.eventEditting.getCalendarId ()) > -1) {
			this.dialog.show = true
		}else {
			this.toastr.warning ('Este calendario no se puede editar desde aquí', "Espera...")
		}
	}

	private getEventClassFromEventFullCalendar (eventFullCalendar) {

		let calendar = this.calendars.find ((calendar: Calendar) => calendar.getId () == eventFullCalendar.calendarId)
		let event = calendar.getEventById (eventFullCalendar.id)
		return {event: event, calendar: calendar}
	}


	public dialogRemoveEventCallback (flag: boolean = false) {
		this.dialog.show = false

		if (flag) {

				this.googleCalendarService.removeEventOnApi (this.eventEditting).then ((event: EventClass) => {

					let calendarId = event.getCalendarId ()
					let calendar = this.calendars.find ((calendar: Calendar) => calendar.getId () == calendarId)
					calendar.removeEvent (this.eventEditting)
					this.fillCalendar ()
				})
		}
	}


	private initCalendar () {

		this.calendarOptions = {
			height: 100,
			editable: true,
			eventLimit: false,

			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,agendaWeek,agendaDay,listMonth',

			},

			defaultView: 'month',
			//fixedWeekCount: false,

			buttonText: {
				today: 'Hoy',
				month: 'Mes',
				week: 'Semana',
				day: 'Día',
				//list: 'Próximos eventos'
			},

			dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			dayNamesShort: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			monthNamesShort: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			firstDay: 1,
			//columnHeader: false,
			titleFormat: "MMMM YYYY",

			events: []
		};
	}

}
