import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const showBackground: AnimationTriggerMetadata = trigger('showBackground', [

	state (':leave', style({opacity: 0})),
	state (':enter', style({opacity: 1})),

	//hide
	transition (':leave', animate('.3s ease-in-out', keyframes ([
		style ({opacity: 1, offset: 0}),
		style ({opacity: 0, offset: 1}),
	]))),

	//show
	transition (':enter', animate('.3s ease-in-out', keyframes ([
		style ({opacity: 0, offset: 0}),
		style ({opacity: 1, offset: 1}),
	])))
])

export const showAlertsList: AnimationTriggerMetadata = trigger('showAlertsList', [

	state (':leave', style({right: "-550px"})),
	state (':enter', style({right: "0"})),

	//hide
	transition (':leave', animate('.3s ease-in-out', keyframes ([
		style ({offset: 0, right: "0"}),
		style ({offset: 1, right: "-550px"}),
	]))),

	//show
	transition (':enter', animate('.3s ease-in-out', keyframes ([
		style ({offset: 0, right: "-550px"}),
		style ({offset: 1, right: "0"}),
	])))
])
