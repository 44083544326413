import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeboxComponent } from './homebox/homebox.component';
import { FilesTreeComponent } from './homebox/files-tree/files-tree.component';
import { SourceTreeFolderComponent } from './components/source-tree-folder/source-tree-folder.component';
import { HeaderComponent } from './homebox/header/header.component';
import { ContentComponent } from './homebox/content/content.component';
import { FolderComponent } from './components/folder/folder.component';
import { FormsModule } from '@angular/forms';
import { FolderSelectorComponent } from './components/folder-selector/folder-selector.component';
import { FolderSelectorItemComponent } from './components/folder-selector-item/folder-selector-item.component';
import { ToolsComponent } from './homebox/tools/tools.component';
import { FileComponent } from './components/file/file.component';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DEFAULT_DROPZONE_CONFIG } from 'src/app/config/dropzoneSettings';

import { ComponentsModule } from 'src/app/components/components.module';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';





@NgModule({
  declarations: [
    HomeboxComponent,
    FilesTreeComponent,
    SourceTreeFolderComponent,
    HeaderComponent,
    ContentComponent,
    FolderComponent,
    FolderSelectorComponent,
    FolderSelectorItemComponent,
    ToolsComponent,
    FileComponent,
    ProgressbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropzoneModule,
    ComponentsModule
  ],
  providers: [
		{
			provide: DROPZONE_CONFIG,
			useValue: DEFAULT_DROPZONE_CONFIG
		}
  ],

  exports: [ HomeboxComponent ]
})
export class HomeboxModule { }
