import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material';
import { FlatService } from 'src/app/services/flat.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { takeUntil, take } from 'rxjs/Operators';
import { Costumer } from 'src/app/classes/Costumer';
import { CostumersService } from 'src/app/services/costumers.service';
import { ToastrService } from 'ngx-toastr'
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { HistoryLogsService } from 'src/app/services/history-logs.service';
import { UserService } from 'src/app/services/user.service';
import { ParkingSpace } from 'src/app/classes/ParkingSpace';
import { StorageRoom } from 'src/app/classes/StorageRoom';

@Component({
	selector: 'app-flat-information-costumer',
	templateUrl: './flat-information-costumer.component.html',
	styleUrls: ['./flat-information-costumer.component.scss']
})
export class FlatInformationCostumerComponent implements OnInit, OnDestroy {

	flat: Flat
	costumerSelected: Costumer
	showCostumerPopup: boolean
	parkingSpaces: ParkingSpace[]
	storageRooms: StorageRoom[]

	costumers: Costumer[]
	formCostumersControl = new FormControl()
	formFilteredCostumersControl = new FormControl ()
	filteredCostumers: ReplaySubject<Costumer[]> = new ReplaySubject<Costumer[]>(1);
	@ViewChild('singleSelect') singleSelect: MatSelect;

	protected _onDestroy = new Subject<void>();

	private getFlatSubscription: Subscription = new Subscription ()
	private getCostumersSubscription: Subscription = new Subscription ()
	private getParkingSpacesSubscription: Subscription = new Subscription ()
	private getStorageRoomsSubscription: Subscription = new Subscription ()

	constructor( private flatService: FlatService, private costumersService: CostumersService, private toastr: ToastrService, private errorService: ErrorService, private userService: UserService, private historyLogService: HistoryLogsService ) {
		this.costumers = []
		this.costumerSelected = new Costumer()
		this.showCostumerPopup = false
		this.parkingSpaces = []
		this.storageRooms = []
	}

	ngOnInit() {

		this.flat = this.flatService.flat
		this.getFlatSubscription = this.flatService.getFlat ().subscribe ((flat: Flat) => this.flat = flat)

		//get costumers
		this.getCostumersSubscription = this.costumersService.getCostumers ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {

				this.costumers.push (new Costumer ({id: 0, name: 'Desenlazar cliente'}))
				this.costumers = this.costumers.concat (res.data)

				this.formCostumersControl.setValue(this.costumers);

				this.formCostumersControl.valueChanges.subscribe (res => {
					
					if (res instanceof Costumer && res.getId () != 0) {

						this.flat.setCostumer (res)
						this.flat.setIdCostumer (res.getId ())
						this.flatService.setFlat (this.flat)

						this.getParkingSpacesOfCostumer ()
						this.getStorageRoomsOfCostumer ()

						let historyLog = new HistoryLog ()
						historyLog.setIdFlat (this.flat.getId ())
						historyLog.setIdStatus (this.flat.getIdStatus ())
						historyLog.setIdUser (this.userService.getUser ().getId ())
						historyLog.setLog ('Ha actualizado el cliente relacionado a la vivienda: ' + this.flatService.flat.getCostumer ().getName () + " " + this.flatService.flat.getCostumer ().getSurname ())

						this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
						
					} else {

						this.flat.setCostumer (new Costumer ({id: null, name: ''}))
						this.flat.setIdCostumer (0)
						this.flatService.setFlat (this.flat)

						this.getParkingSpacesOfCostumer ()
						this.getStorageRoomsOfCostumer ()

						let historyLog = new HistoryLog ()
						historyLog.setIdFlat (this.flat.getId ())
						historyLog.setIdStatus (this.flat.getIdStatus ())
						historyLog.setIdUser (this.userService.getUser ().getId ())
						historyLog.setLog ('Ha borrado el cliente relacionado a la vivienda')

						this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
					}
				})
				
				this.filteredCostumers.next(this.costumers.slice());
				this.formFilteredCostumersControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterCostumers ());

				this.setInitialValue();
			}

		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-costumer.component.ts'))

		
		//get parkingSpaces from user and flat
		this.getParkingSpacesOfCostumer ()

		//get storage rooms from user and flat
		this.getStorageRoomsOfCostumer ()

		

		
	}


	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
		this.getFlatSubscription.unsubscribe ()
		this.getCostumersSubscription.unsubscribe ()
		this.getParkingSpacesSubscription.unsubscribe ()
		this.getStorageRoomsSubscription.unsubscribe ()
	}

	ngAfterViewInit() {
		this.setInitialValue();
	}

	private filterCostumers () {

		if (!this.costumers)
			return;

		let search = this.formFilteredCostumersControl.value;

		if (!search) {
			this.filteredCostumers.next(this.costumers.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		
		this.filteredCostumers.next(
			this.costumers.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
		);
	}


	protected setInitialValue() {

		this.filteredCostumers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			this.singleSelect.compareWith = (a: Costumer, b: Costumer) => a && b && a === b;
		})
	}


	public openPopupCostumerEdit () {

		this.costumerSelected = this.flat.getCostumer ()
		this.showCostumerPopup = true
	}


	public closePopupCostumerCallback (flag: boolean = false) {

		this.showCostumerPopup = false
		this.flatService.setFlat (this.flat)
	}

	private getParkingSpacesOfCostumer () {

		this.parkingSpaces = []

		if (this.flat.getCostumer ().id > 0) {

			this.getParkingSpacesSubscription = this.costumersService.getParkingSpacesByIdFlat (this.flat.getId (), this.flat.getCostumer ().id).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {

					this.parkingSpaces = res.data
				}

			}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-costumer.component.ts'))
		}
	}

	private getStorageRoomsOfCostumer () {

		this.storageRooms = []

		if (this.flat.getCostumer ().id > 0) {

			this.getStorageRoomsSubscription = this.costumersService.getStorageRoomsByIdFlat (this.flat.getId (), this.flat.getCostumer ().id).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {

					this.storageRooms = res.data
				}

			}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-costumer.component.ts'))
		}
	}
}
