export interface StatusInterface {
	id: number
	name: string
	order: number
	rgb: string

	getId (): number
	getName (): string
	getOrder (): number
	getRgb (): string

	setId (id: number): void
	setName (name: string): void
	setOrder (order: number): void
	setRgb (rgb: string): void
}

export class Status implements StatusInterface {

	id
	name
	order
	rgb

	constructor (data?: StatusInterface) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setOrder ((typeof data != "undefined" && typeof data.order != "undefined") ? data.order : 0)
		this.setRgb ((typeof data != "undefined" && typeof data.rgb != "undefined") ? data.rgb : "")
	}

	getId () { return this.id }
	getName () { return this.name }
	getOrder () { return this.order }
	getRgb () { return this.rgb }

	setId (id = 0) { this.id = id }
	setName (name = "") { this.name = name }
	setOrder (order = 0) { this.order = order }
	setRgb (rgb = "#ffffff") { this.rgb = rgb }
}