import { routes } from 'src/app/config/routes';

export class File {

	id: number = 0
	name: string = ""
	url: string = ""
	id_folder: number = 0
	created_at: Date = null
	rgb: string = "#fff"
	imageExtensions = ['png', 'jpg', 'svg', 'gif', 'jpeg', 'svg']

	constructor (data: any = {}) {
		this.setId ((typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data.name != "undefined") ? data.name : "")
		this.setUrl ((typeof data.url != "undefined") ? data.url : "")
		this.setIdFolder ((typeof data.id_folder != "undefined") ? data.id_folder : 0)
		this.setRgb ((typeof data.rgb != "undefined") ? data.rgb : "#fff")
		this.setCreatedAt ((typeof data.created_at != "undefined") ? data.created_at : 0)
		
	}

	setId (id: number = 0) { this.id = id }
	setName (name: string = "") { this.name = name }
	setUrl (url: string = "") { this.url = url }
	setIdFolder (id: number = 0) { this.id_folder = id }
	setCreatedAt (date: Date = null) { this.created_at = date }
	setRgb (rgb: string = "") { this.rgb = rgb }

	getId (): number { return this.id }
	getName (): string { return this.name }
	getUrl (): string { return this.url }
	getIdFolder (): number { return this.id_folder }
	getCreatedAt (): Date { return this.created_at }
	getRgb (): string { return this.rgb }
	getFilePath (): string { return  routes.media + "homebox/" + this.getUrl () }

	getIcon () {

		let ext = this.getExtension ()
		let path = routes.media + "icons/files/"
		
		if (this.isImage ()) {
			return this.getFilePath ()
		} else {
			return path + ext + '.svg';
		}
	}

	isImage () {

		let ext = this.getExtension ()

		if (this.imageExtensions.indexOf (ext.toLocaleLowerCase ()) > -1) {
			return true;
		} else {
			return false
		}
	}

	getExtension () {
		return this.getFilePath ().split ('.').reverse ()[0]
	}

	toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			id_folder: this.getIdFolder (),
			url: this.getUrl (),
			created_at: this.getCreatedAt (),
			rgb: this.getRgb (),
		}
	}

	toJson () { return JSON.stringify (this.toArray ()) }

}