import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FolderService } from '../../services/folder.service';
import { Folder } from 'src/app/classes/homebox/Folder';
import { SourceTreeService } from '../../services/source-tree.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';



@Component({
	selector: 'app-tools',
	templateUrl: './tools.component.html',
	styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit, OnDestroy {

	@Output () openFileDialog: EventEmitter<boolean> = new EventEmitter<boolean> ()
	addFolderSub: Subscription = new Subscription ()

	constructor ( private folderService: FolderService, private sourceTreeService: SourceTreeService, private errorService: ErrorService ) { }

	ngOnInit() {
	}

	ngOnDestroy () {
		this.addFolderSub.unsubscribe ()
	}

	addFolder () {

		let folder = new Folder ()
		folder.setName ('Carpeta sin título')
		folder.setIdParent (this.sourceTreeService.currentFolder.getId ())

		this.addFolderSub = this.folderService.addFolder (folder).subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {

				let result:any = res.data

				folder.setId (result.id)
				this.sourceTreeService.addFolder (folder)
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'tools.component.ts'))
	}


}
