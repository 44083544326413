import { Component, OnInit } from '@angular/core';
import { SourceTreeService } from '../../services/source-tree.service';
import { Folder } from 'src/app/classes/homebox/Folder';
import { HomeboxService } from '../../services/homebox.service';
import { File } from 'src/app/classes/homebox/File';

@Component({
	selector: 'app-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

	public currentFolder: Folder = new Folder ()
	public foldersFiltered: Folder[] = []
	public filesFiltered: File[] = []
	
	viewType: string

	constructor( private sourceTreeService: SourceTreeService, private homeboxService: HomeboxService ) { }

	ngOnInit() {

		this.sourceTreeService.getCurrentFolder ().subscribe ((currentFolder: Folder = new Folder ()) => {
			
			this.currentFolder = currentFolder
			this.foldersFiltered = this.currentFolder.getFolders ()
			this.filesFiltered = this.currentFolder.getFiles ()
			this.applyFilter (this.homeboxService.search)

		})

		this.homeboxService.getSearch ().subscribe ((search: string = "") => {
			this.applyFilter (search)
		})

		this.viewType = this.homeboxService.type
		this.homeboxService.getType ().subscribe ((type: string = 'grid') => this.viewType = type)
	}


	private applyFilter (search: string = "") {

		this.foldersFiltered = []
		this.filesFiltered = []

		for (let folder of this.currentFolder.getFolders ()) {
			if (folder.getName ().toLocaleLowerCase ().indexOf (search.toLocaleLowerCase ()) > -1) {
				this.foldersFiltered.push (folder)
			}
		}

		for (let file of this.currentFolder.getFiles ()) {
			if (file.getName ().toLocaleLowerCase ().indexOf (search.toLocaleLowerCase ()) > -1) {
				this.filesFiltered.push (file)
			}
		}

	}

}
