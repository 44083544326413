export class Visit {

    private id: number
    private promotion_id: number
    private promoter_id: number
    private seller_id: number
    private phone_contact: string
    private email_contact: string
    private name_contact: string
    private country_id: number
    private budget: number
    private preferences: string
    private observations: string
    private created_at: Date

    constructor (data?: any) {
        if (typeof data !== 'undefined') {
            this.id = (typeof data.id !== 'undefined') ? data.id : 0
            this.promotion_id = (typeof data.promotion_id !== 'undefined') ? data.promotion_id : 0
            this.seller_id = (typeof data.seller_id !== 'undefined') ? data.seller_id : 0
            this.phone_contact = (typeof data.phone_contact !== 'undefined') ? data.phone_contact : ''
            this.email_contact = (typeof data.email_contact !== 'undefined') ? data.email_contact : ''
            this.name_contact = (typeof data.name_contact !== 'undefined') ? data.name_contact : ''
            this.country_id = (typeof data.country_id !== 'undefined') ? data.country_id : 0
            this.budget = (typeof data.budget !== 'undefined') ? data.budget : 0
            this.preferences = (typeof data.preferences !== 'undefined') ? data.preferences : ''
            this.observations = (typeof data.observations !== 'undefined') ? data.observations : ''
            this.created_at = (typeof data.created_at !== 'undefined') ? data.created_at : new Date ()
        }
    }

    public setId (id: number = 0) { this.id = id }
    public setPromotionId (id: number = 0) { this.promotion_id = id }
    public setSellerId (id: number = 0) { this.seller_id = 0 }
    public setPhoneContact (phone: string = '') { this.phone_contact = phone }
    public setEmailContact (email: string = '') { this.email_contact = email }
    public setNameContact (name: string = '') { this.name_contact = name }
    public setCountryId (id: number = 0) { this.country_id = id }
    public setBudget (budget: number = 0) { this.budget = budget }
    public setPreferences (preferences: string = '') { this.preferences = preferences }
    public setOnservations (observations: string = '') { this.observations = observations }
    public setCreatedAt (date: Date = new Date ()) { this.created_at = date }

    public getId (): number { return this.id }
    public getPromotionId (): number { return this.promoter_id }
}