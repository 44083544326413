import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FlatsService } from 'src/app/services/flats.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { showStatusSelector } from 'src/app/animations/status-selector.animations';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tbyb-selector',
  templateUrl: './tbyb-selector.component.html',
  styleUrls: ['./tbyb-selector.component.scss'],
  animations: [showStatusSelector]
})
export class TbybSelectorComponent implements OnInit, OnDestroy {

  animationFlag: boolean
  items: any
  @Output () closeTbybSelectorCallback: EventEmitter<any> = new EventEmitter<any> ()

  getRoutesForTbybSub: Subscription = new Subscription ()

  constructor(private flatsService: FlatsService, private errorService: ErrorService ) {
    this.animationFlag = true
  }

  ngOnInit() {

    this.getRoutesForTbybSub = this.flatsService.getRoutesForTbyb ().subscribe ((res: Error) => {

      if (res.status == httpStatus.success) {
        this.items = res.data
      }

    },(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'tbyb-selector.component.ts'))
  }

  ngOnDestroy () {
    this.getRoutesForTbybSub.unsubscribe ()
  }

  close () {
    this.closeTbybSelectorCallback.emit (null)
  }

  selectItem (item) {
    this.closeTbybSelectorCallback.emit (item)
  }

}
