import { Component, OnInit, ViewChild } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';
import { Agency } from 'src/app/classes/Agency';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material';
import { FlatService } from 'src/app/services/flat.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { takeUntil, take } from 'rxjs/Operators';
import { ToastrService } from 'ngx-toastr'
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { HistoryLogsService } from 'src/app/services/history-logs.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-flat-information-agency',
    templateUrl: './flat-information-agency.component.html',
    styleUrls: ['./flat-information-agency.component.scss']
})
export class FlatInformationAgencyComponent implements OnInit {

    flat: Flat
    agencySelected: Agency
    showAgencyPopup: boolean

    agencies: Agency[]
    formAgenciesControl = new FormControl()
    formFilteredAgenciesControl = new FormControl ()
    filteredAgencies: ReplaySubject<Agency[]> = new ReplaySubject<Agency[]>(1);
    @ViewChild('singleSelect') singleSelect: MatSelect;

    protected _onDestroy = new Subject<void>();

    private getFlatSubscription: Subscription = new Subscription ()
    private getAgenciesSubscription: Subscription = new Subscription ()

    constructor( private flatService: FlatService, private agenciesService: AgenciesService, private toastr: ToastrService, private errorService: ErrorService, private userService: UserService, private historyLogService: HistoryLogsService ) {
        this.agencies = []
        this.agencySelected = new Agency()
        this.showAgencyPopup = false
    }

    ngOnInit() {

        this.flat = this.flatService.flat
        this.getFlatSubscription = this.flatService.getFlat ().subscribe ((flat: Flat) => this.flat = flat)

        this.getAgenciesSubscription = this.agenciesService.getAgencies ().subscribe ((res: Error) => {

            if (res.status == httpStatus.success) {

                this.agencies.push (new Agency ({id: 0, name: 'Desenlazar agencia'}))
                this.agencies = this.agencies.concat (res.data)

                this.formAgenciesControl.setValue(this.agencies);

                this.formAgenciesControl.valueChanges.subscribe (res => {
                    
                    if (res instanceof Agency && res.getId () != 0) {

                        this.flat.setPromoter (res)
                        this.flat.setIdPromoter (res.getId ())
                        this.flatService.setFlat (this.flat)	

                        let historyLog = new HistoryLog ()
                        historyLog.setIdFlat (this.flat.getId ())
                        historyLog.setIdStatus (this.flat.getIdStatus ())
                        historyLog.setIdUser (this.userService.getUser ().getId ())
                        historyLog.setLog ('Ha actualizado la agencia relacionada a la propiedad: ' + this.flatService.flat.getPromoter ().getName ())

                        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
                    } else {

                        this.flat.setPromoter (new Agency ({id: 0, name: ''}))
                        this.flat.setIdPromoter (0)
                        this.flatService.setFlat (this.flat)	

                        let historyLog = new HistoryLog ()
                        historyLog.setIdFlat (this.flat.getId ())
                        historyLog.setIdStatus (this.flat.getIdStatus ())
                        historyLog.setIdUser (this.userService.getUser ().getId ())
                        historyLog.setLog ('Ha borrado la agencia relacionada a la propiedad')

                        this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))

                    }
                }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-agency.component.ts'))
                
                this.filteredAgencies.next(this.agencies.slice());
                this.formFilteredAgenciesControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterAgencies ());

                this.setInitialValue();
                    
            }
        }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-agency.component.ts'))
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.getFlatSubscription.unsubscribe ()
        this.getAgenciesSubscription.unsubscribe ()
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    private filterAgencies () {

        if (!this.agencies)
            return;
        
        let search = this.formFilteredAgenciesControl.value;

        if (!search) {
            this.filteredAgencies.next(this.agencies.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        
        this.filteredAgencies.next(
            this.agencies.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
        );
    }


    protected setInitialValue() {

        this.filteredAgencies.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
            this.singleSelect.compareWith = (a: Agency, b: Agency) => a && b && a === b;
        })
    }


    public openPopupAgencyEdit () {

        this.agencySelected = this.flat.getPromoter ()
        this.showAgencyPopup = true
    }


    public closePopupAgencyCallback (flag: boolean = false) {

        this.showAgencyPopup = false
        this.flatService.setFlat (this.flat)
    }

}
