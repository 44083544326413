import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from 'src/app/config/routes';
import { map } from 'rxjs/Operators';
import { Error } from 'src/app/classes/Error';
import { Folder } from 'src/app/classes/homebox/Folder';
import { File } from 'src/app/classes/homebox/File';
//import { FileService } from './file.service';

@Injectable({
	providedIn: 'root'
})
export class SourceTreeService {

	private controller: string
	private idRootFolder: number
	
	public sourceTree : Folder = new Folder ()
	private sourceTreeEmitter: EventEmitter<Folder> = new EventEmitter<Folder> ()

	public currentFolder: Folder = new Folder ()
	private currentFolderEmitter: EventEmitter<Folder> = new EventEmitter<Folder> ()

	private breadcrumbs: Folder[] = []
	private breadcrumbsEmitter: EventEmitter<Folder[]> = new EventEmitter<Folder[]> ()

	constructor( private http: HttpClient ) {
		this.controller = 'SourceTree'
		this.idRootFolder = 7
	}

	getUrl () { return routes.api + this.controller + "/" }

	public getSourceTree () { return this.sourceTreeEmitter }
	public getCurrentFolder () { return this.currentFolderEmitter }
	public getBreadcrumbs () { return this.breadcrumbsEmitter }

	
	//inicializar el servicio de homebox creando todas las variables iniciales y los emitter
	public getSourceTreeFromDatabase (idRootFolder: number = this.idRootFolder) {

		idRootFolder = (typeof idRootFolder == "undefined" || idRootFolder == 0) ? this.idRootFolder : idRootFolder

		console.log ('GetSourceTreeFromDatabase')

		let err = new Error ()

		let headers = new HttpHeaders ({
			'Content-Type': 'application/x-www-form-urlencoded'
		})

		let body = new HttpParams ()
		.set ('idRootFolder', JSON.stringify(idRootFolder))


		return this.http.post (this.getUrl () + "getSourceTree", body.toString (), { headers: headers }).pipe (map ((res: any[]) => {

			this.sourceTree = this.generateSoruceTreeClasses (res)
			this.sourceTreeEmitter.emit (this.sourceTree)
			
			this.setCurrentFolder (this.sourceTree)
			
		}, () => {
			err.setError ('Ups..! No hemos podido mostrar el árbol de carpetas. Vuelve a intentarlo')
			return err
		}))
	}

	public setCurrentFolder (folder: Folder = new Folder ()) {

		this.currentFolder = folder
		this.currentFolderEmitter.emit (this.currentFolder)
		this.setBreadCrumbs ()

	}

	public setBreadCrumbs () {
		this.breadcrumbs = this.getBreadcrumbsRecursive (this.getPlanSourceTreeOfFolders (this.sourceTree), this.currentFolder).reverse ()
		this.breadcrumbsEmitter.emit (this.breadcrumbs)
	}


	//devuelve las migas de pan de las carpetas incluyendo la carpeta actual
	private getBreadcrumbsRecursive (flatSourceTree: Folder[] = [], folderToSearch: Folder = new Folder ()) {

		folderToSearch.setActive (true)

		let breadcrumbs: Folder[] = []
		let parent = flatSourceTree.find ((folder: Folder, index: number) => folder.getId () == folderToSearch.getIdParent ())

		breadcrumbs.push (folderToSearch)

		if (typeof parent != "undefined") {

			if (parent.getIdParent () == 0) {
				parent.setActive (true)
				breadcrumbs.push (parent)
			} else {
				breadcrumbs = breadcrumbs.concat (this.getBreadcrumbsRecursive (flatSourceTree, parent))
			}
		}

		return breadcrumbs;
	}

	//devuelve todas las carpetas en un array plano
	private getPlanSourceTreeOfFolders (sourceTree: Folder = new Folder ()) {

		sourceTree.setActive (false)

		let flatSourceTree: Folder[] = []

		flatSourceTree.push (sourceTree)

		for (let childrenFolder of sourceTree.getFolders ()) {
			flatSourceTree = flatSourceTree.concat (this.getPlanSourceTreeOfFolders (childrenFolder))
		}

		return flatSourceTree
	}



	//recursiva para cambiar los objetos por classes
	private generateSoruceTreeClasses (parentFolder: any = {}) {

		let parentFolderClass = new Folder (parentFolder)
		let filesClasses: File[] = []
		
		for (let file of parentFolder.files) {
			filesClasses.push (new File (file))
		}

		parentFolderClass.setFiles (filesClasses)

		for (let childrenFolder of parentFolder.folders) {
			parentFolderClass.folders.push (this.generateSoruceTreeClasses (childrenFolder))
		}

		return parentFolderClass
	}

	//mueve las carpetas en el sourceTree. Para moverlas en la base de datos está en folder.service.ts
	public moveFolder (folderToMove: Folder = new Folder (), destinationFolder: Folder = new Folder ()) {

		let planSourceTreeOfFolders = this.getPlanSourceTreeOfFolders (this.sourceTree)

		//padre de la carpeta a mover
		let parentFolderToMoveInSourceTreeOfFolders = planSourceTreeOfFolders.find ((folder: Folder) => folder.getId () == folderToMove.getIdParent ())

		parentFolderToMoveInSourceTreeOfFolders.removeChildFolder (folderToMove)
		destinationFolder.folders.push (folderToMove)

		folderToMove.setIdParent (destinationFolder.getId ())

		this.currentFolderEmitter.emit (this.currentFolder)

	}

	public moveFile (file: File = new File (), destinationFolder: Folder = new Folder ()) {

		let planSourceTreeOfFolders = this.getPlanSourceTreeOfFolders (this.sourceTree)

		let parentFolderOfFile = planSourceTreeOfFolders.find ((folder: Folder) => folder.getId () == file.getIdFolder ())
		parentFolderOfFile.removeFile (file)
		destinationFolder.files.push (file)
		file.setIdFolder (destinationFolder.getId ())

		this.currentFolderEmitter.emit (this.currentFolder)

	}

	public addFile (file: File = new File ()) {
		this.currentFolder.files.push (file)
		this.currentFolderEmitter.emit (this.currentFolder)
	}

	public addFolder (folder: Folder = new Folder ()) {
		this.currentFolder.folders.push (folder)
		this.currentFolderEmitter.emit (this.currentFolder)
	}

	public removeFolder (folderToRemove: Folder = new Folder ()) {

		let planSourceTreeOfFolders = this.getPlanSourceTreeOfFolders (this.sourceTree)
		let parent = planSourceTreeOfFolders.find ((folder: Folder) => folderToRemove.getIdParent () == folder.getId ())
		
		parent.removeChildFolder (folderToRemove)
		this.currentFolderEmitter.emit (this.currentFolder)

	}

	public removeFile (file: File = new File ()) {

		let planSourceTreeOfFolders = this.getPlanSourceTreeOfFolders (this.sourceTree)
		let folder = planSourceTreeOfFolders.find ((folder: Folder) => file.getIdFolder () == folder.getId ())

		folder.removeFile (file)
		this.currentFolderEmitter.emit (this.currentFolder)

	}
}
