import { Injectable } from '@angular/core';
import { Calendar } from '../classes/calendar/Calendar';
import { EventClass } from '../classes/calendar/Event';
import { GoogleAouthService } from './google-aouth.service';
import * as moment from 'moment';
import { CommentsService } from './comments.service';

declare var gapi: any

@Injectable({
	providedIn: 'root'
})
export class GoogleCalendarService {

	events: EventClass[]
	calendars: Calendar[]


	constructor (private googleAouthService: GoogleAouthService, private commentService: CommentsService) {
		this.events = []
		this.calendars = []
	
	}



	getCalendarsFromApi () {

		this.calendars = []

		return new Promise((resolve, reject) => {

			gapi.client.calendar.calendarList.list ().then ((calendars) => {

				calendars = calendars.result.items
	
				for (let calendar of calendars) {
					this.calendars.push (new Calendar (calendar))
				}

				resolve (this.calendars)
			})
		})
	}


	getEventsFromApi (calendarId: string = "", from = null, maxResults = null) {

		let eventClasses = []//new Date().toISOString()

		return new Promise((resolve, reject) => {

			let data:any
			data = {
				calendarId: calendarId,
				showDeleted: false,
				singleEvents: true,
				orderBy: 'startTime'
			}

			if (from != null) {
				data.timeMin = from
			}

			if (maxResults != null) {
				data.maxResults = maxResults
			}

		gapi.client.calendar.events.list(data).then ((events) => {

			events = events.result.items;

			for (let event of events) {

				event.start = (typeof event.start.date != "undefined") ? moment (event.start.date) : moment (event.start.dateTime)
				event.end = (typeof event.end.date != "undefined") ? moment (event.end.date) : moment (event.end.dateTime)
				event.calendarId = calendarId

				let ev = new EventClass (event)
				eventClasses.push (ev)
			}

			resolve (eventClasses)

		})
	})
		
	}


	getEventFromApi (eventId: string, calendarId: string) {

		return new Promise((resolve, reject) => {

			gapi.client.calendar.events.get ({
				'calendarId': calendarId,
				'eventId': eventId
			}). execute (response => {

				let ev = response.result
				ev.start = (typeof ev.start.date != "undefined") ? moment (ev.start.date) : moment (ev.start.dateTime)
				ev.end = (typeof ev.end.date != "undefined") ? moment (ev.end.date) : moment (ev.end.dateTime)
				ev.calendarId = calendarId

				let event = new EventClass (ev)

				resolve (event)

			})
		})

	}


	createEventOnApi (event: EventClass) {

		let eventObj = event.getStringForApi ()

		return new Promise((resolve, reject) => {

			gapi.client.calendar.events.insert({

				'calendarId': event.getCalendarId (),
				'resource': eventObj

			}).execute (response => {

				event.setId (response.result.id)
				resolve (event)

			});	
		})
	}


	removeEventOnApi (event: EventClass) {

		return new Promise((resolve, reject) => {

			let removeOnGoogle = false
			let checkComments = false

			gapi.client.calendar.events.delete({

				'calendarId': event.getCalendarId (),
				'eventId': event.getId ()

			}).execute (response => {

				removeOnGoogle = true

				if (removeOnGoogle && checkComments) {
					resolve (event)
				}
			});


			this.commentService.removeCommentsWidthEventId (event.getId ()).subscribe (() => {

				checkComments = true

				if (removeOnGoogle && checkComments) {
					resolve (event)
				}
			})

			
		})

	}


	updateEventOnApi (event: EventClass) {

		return new Promise((resolve, reject) => {

			gapi.client.calendar.events.patch ({

				'calendarId': event.getCalendarId (),
				'eventId': event.getId (),
				'resource': event.getStringForApi ()

			}).execute (response => {

				resolve (event)
			});
		})
	}

	

}
