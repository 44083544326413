import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Lawyer } from 'src/app/classes/Lawyer';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material';
import { LawyersService } from 'src/app/services/lawyers.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { takeUntil, take } from 'rxjs/Operators';
import { FlatService } from 'src/app/services/flat.service';
import { Flat } from 'src/app/classes/Flat';
import { ToastrService } from 'ngx-toastr'
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { HistoryLogsService } from 'src/app/services/history-logs.service';
import { UserService } from 'src/app/services/user.service';
@Component({
	selector: 'app-flat-information-lawyer',
	templateUrl: './flat-information-lawyer.component.html',
	styleUrls: ['./flat-information-lawyer.component.scss']
})
export class FlatInformationLawyerComponent implements OnInit, OnDestroy {

	flat: Flat
	lawyerSelected: Lawyer
	showLawyerPopup: boolean

	lawyers: Lawyer[]
	formLawyersControl = new FormControl()
	formFilteredLawyersControl = new FormControl ()
	filteredLawyers: ReplaySubject<Lawyer[]> = new ReplaySubject<Lawyer[]>(1);
	@ViewChild('singleSelect') singleSelect: MatSelect;

	protected _onDestroy = new Subject<void>();

	private getFlatSubscription: Subscription
	private getLawyersSubscription: Subscription

	constructor( private LawyerService: LawyersService, private flatService: FlatService, private toastr: ToastrService, private errorService: ErrorService, private userService: UserService, private historyLogService: HistoryLogsService ) {
		this.lawyers = []
		this.lawyerSelected = new Lawyer()
		this.showLawyerPopup = false
	}

	ngOnInit() {

		this.flat = this.flatService.flat
		this.getFlatSubscription = this.flatService.getFlat ().subscribe ((flat: Flat) => this.flat = flat)

		this.getLawyersSubscription = this.LawyerService.getLawyers ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {
				
				this.lawyers.push (new Lawyer ({id: 0, name: 'Desenlazar abogado'}))
				this.lawyers = this.lawyers.concat (res.data)

				this.formLawyersControl.setValue(this.lawyers);
				this.formLawyersControl.valueChanges.subscribe (res => {
					
					if (res instanceof Lawyer && res.getId () != 0) {

						this.flat.setLawyer (res)
						this.flat.setIdLawyer (res.getId ())
						this.flatService.setFlat (this.flat)

						let historyLog = new HistoryLog ()
						historyLog.setIdFlat (this.flat.getId ())
						historyLog.setIdStatus (this.flat.getIdStatus ())
						historyLog.setIdUser (this.userService.getUser ().getId ())
						historyLog.setLog ('Ha actualizado el abogado relacionado a la vivienda: ' + this.flatService.flat.getLawyer ().getName ())

						this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
					
					} else {
						
						this.flat.setLawyer (new Lawyer ({id: 0, name: ''}))
						this.flat.setIdLawyer (0)
						this.flatService.setFlat (this.flat)

						let historyLog = new HistoryLog ()
						historyLog.setIdFlat (this.flat.getId ())
						historyLog.setIdStatus (this.flat.getIdStatus ())
						historyLog.setIdUser (this.userService.getUser ().getId ())
						historyLog.setLog ('Ha borrado el abogado relacionado a la vivienda')

						this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))

					}
				})
				
				this.filteredLawyers.next(this.lawyers.slice());
				this.formFilteredLawyersControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterLawyers () );

				this.setInitialValue();
	
			}

		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-lawyer.component.ts'))
	}


	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
		this.getFlatSubscription.unsubscribe ()
		this.getLawyersSubscription.unsubscribe ()
	}

	ngAfterViewInit() {
		this.setInitialValue();
	}

	private filterLawyers () {

		if (!this.lawyers)
			return

		let search = this.formFilteredLawyersControl.value;

		if (!search) {
			this.filteredLawyers.next(this.lawyers.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		
		this.filteredLawyers.next(
			this.lawyers.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
		);
	}


	protected setInitialValue() {

		this.filteredLawyers.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			this.singleSelect.compareWith = (a: Lawyer, b: Lawyer) => a && b && a === b;
		})
	}


	openPopupLawyerEdit () {

		this.lawyerSelected = this.flat.getLawyer ()
		this.showLawyerPopup = true
	}


	closePopupLawyerCallback (flag: boolean = false) {

		this.showLawyerPopup = false
		this.flat.setLawyer (this.flat.getLawyer ())
		this.flat.setIdLawyer (this.flat.getLawyer ().getId ())
		this.flatService.setFlat (this.flat)
	}
}
