import * as moment from 'moment';

export interface EventInterface {

}

export class EventClass {

	id: string
	calendarId: string
	start: moment.Moment
	end: moment.Moment
	summary: string
	url: string
	fullCalendarEvent: any

	constructor (data?) {

		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : null)
		this.setStart ((typeof data != "undefined" && typeof data.start != "undefined") ? data.start : moment ())
		this.setEnd ((typeof data != "undefined" && typeof data.end != "undefined") ? data.end : moment ())
		this.setSummary ((typeof data != "undefined" && typeof data.summary != "undefined") ? data.summary : '')
		this.setUrl ((typeof data != "undefined" && typeof data.url != "undefined") ? data.url : '')
		this.setCalendarId ((typeof data != "undefined" && typeof data.calendarId != "undefined") ? data.calendarId : '')
		this.setFullCalendarEvent ((typeof data != "undefined" && typeof data.fullCalendarEvent != "undefined") ? data.fullCalendarEvent : null)
	}

	setId (id = "") { this.id = id }
	setStart (date: moment.Moment = moment ()) { this.start = date }
	setEnd (date: moment.Moment = moment ()) { this.end = date }
	setSummary (summary = "") { this.summary = summary }
	setUrl (url = "") { this.url = url }
	setCalendarId (id: string = "") { this.calendarId = id }
	setFullCalendarEvent (event) { this.fullCalendarEvent = event }

	getId () { return this.id }
	getStart () { return this.start }
	getEnd () { return this.end }
	getSummary () { return this.summary }
	getUrl () { return this.url }
	getCalendarId () { return this.calendarId }
	getFullCalendarEvent () { return this.fullCalendarEvent }

	getStringForApi () {

		return {
			summary: this.getSummary (),
			start: {dateTime: this.getStart ().format (), timeZone: 'Europe/Madrid'},
			end: {dateTime: this.getEnd ().format (), timeZone: 'Europe/Madrid'}
		}
	}

	toArray () {
		return {
			id: this.getId (),
			start: this.getStart ().format (),
			end: this.getEnd ().format (),
			summary: this.getSummary (),
			url: this.getUrl (),
			calendar_id: this.getCalendarId (),
		}
	}

	toJson () {
		return JSON.stringify (this.toArray ())
	}

}