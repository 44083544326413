import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-progressbar',
	templateUrl: './progressbar.component.html',
	styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {

	_percetage: number
	finished: boolean

	@Input ('percentage') set percentage (percentage) {
		
		this._percetage = percentage.toFixed (2)

		if (this._percetage >= 100) {
			this.finished = true
		}
	}

	constructor() {
		this._percetage = 0
		this.finished = false
	}

	ngOnInit() {
	}

}
