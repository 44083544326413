import { StorageRoom } from './StorageRoom';
import { ParkingSpace } from './ParkingSpace';
export class Costumer {

	private id: number = 0
	private name: string = ""
	private surname: string = ""
	private card_id: string = ""
	private passport: string = ""
	private email: string = ""
	private phone: string = ""
	private civil_status: string = ""
	private address: string = ""
	private province: string = ""
	private country: string = ""
	private cp: string = ""
	private created_at: Date = new Date ()
	private updated_at: Date = new Date ()
	private parkingSpaces: ParkingSpace[] = []
	private storageRooms: StorageRoom [] = []

	constructor (data?) {

		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setSurname ((typeof data != "undefined" && typeof data.surname != "undefined") ? data.surname : "")
		this.setCardId ((typeof data != "undefined" && typeof data.card_id != "undefined") ? data.card_id : "")
		this.setPassport ((typeof data != "undefined" && typeof data.passport != "undefined") ? data.passport : "")
		this.setEmail ((typeof data != "undefined" && typeof data.email != "undefined") ? data.email : "")
		this.setPhone ((typeof data != "undefined" && typeof data.phone != "undefined") ? data.phone : "")
		this.setCivilStatus ((typeof data != "undefined" && typeof data.civil_status != "undefined") ? data.civil_status : "")
		this.setAddress ((typeof data != "undefined" && typeof data.address != "undefined") ? data.address : "")
		this.setProvince ((typeof data != "undefined" && typeof data.province != "undefined") ? data.province : "")
		this.setCountry ((typeof data != "undefined" && typeof data.country != "undefined") ? data.country : "")
		this.setCp ((typeof data != "undefined" && typeof data.cp != "undefined") ? data.cp : "")
		this.setCreatedAt ((typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : new Date ())
		this.setUpdatedAt ((typeof data != "undefined" && typeof data.updated_at != "undefined") ? data.updated_at : new Date ())
		this.setParkingSpaces ((typeof data != "undefined" && typeof data.parkingSpaces != "undefined") ? data.parkingSpaces : [])
		this.setStorageRooms ((typeof data != "undefined" && typeof data.storageRooms != "undefined") ? data.storageRooms : [])
	}

	setId (id: number = 0): void { this.id = id }
	setName (name: string = ""): void { this.name = name }
	setSurname (surname: string = ""): void { this.surname = surname }
	setCardId (cardId: string = ""): void { this.card_id = cardId }
	setPassport (passport: string = ""): void { this.passport = passport }
	setEmail (email: string = ""): void { this.email = email }
	setPhone (phone: string = ""): void { this.phone = phone }
	setCivilStatus (civilStatus: string = ""): void { this.civil_status = civilStatus }
	setAddress (address: string = ""): void { this.address = address }
	setProvince (province: string = ""): void { this.province = province }
	setCountry (country: string = ""): void { this.country = country }
	setCp (cp: string = ""): void { this.cp = cp }
	setCreatedAt (date: Date = new Date ()): void { this.created_at = date }
	setUpdatedAt (date: Date = new Date ()): void { this.updated_at = date }
	setParkingSpaces (parkingSpaces: ParkingSpace [] = []) { this.parkingSpaces = parkingSpaces }
	setStorageRooms (storageRooms: StorageRoom [] = []) { this.storageRooms = storageRooms }

	getId (): number { return this.id }
	getName (): string { return this.name }
	getSurname (): string { return this.surname }
	getCardId (): string { return this.card_id }
	getPassport (): string { return this.passport }
	getEmail (): string { return this.email }
	getPhone (): string { return this.phone }
	getCivilStatus (): string { return this.civil_status }
	getAddress (): string { return this.address }
	getProvince (): string { return this.province }
	getCountry (): string { return this.country }
	getCp (): string { return this.cp }
	getCreatedAt (): Date { return this.created_at }
	getUpdatedAt (): Date { return this.updated_at }
	getParkingSpaces (): ParkingSpace [] { return this.parkingSpaces }
	getStorageRooms (): StorageRoom [] { return this.storageRooms }

	clone (newCostumer: Costumer) {
		this.setId (newCostumer.getId ())
		this.setName (newCostumer.getName ())
		this.setSurname (newCostumer.getSurname())
		this.setCardId (newCostumer.getCardId ())
		this.setPassport (newCostumer.getPassport ())
		this.setEmail(newCostumer.getEmail ())
		this.setPhone (newCostumer.getPhone ())
		this.setCivilStatus (newCostumer.getCivilStatus ())
		this.setAddress (newCostumer.getAddress ())
		this.setProvince (newCostumer.getProvince ())
		this.setCountry (newCostumer.getCountry ())
		this.setCp (newCostumer.getCp ())
		this.setCreatedAt (newCostumer.getCreatedAt ())
		this.setUpdatedAt (newCostumer.getUpdatedAt ())
		this.setParkingSpaces (newCostumer.getParkingSpaces ())
		this.setStorageRooms (newCostumer.getStorageRooms ())
	}

	toJson () {

		return JSON.stringify (this.toArray ())
	}

	toArray () {
		return {
			id: this.getId (),
			name: this.getName (),
			surname: this.getSurname (),
			card_id: this.getCardId (),
			passport: this.getPassport (),
			email: this.getEmail (),
			phone: this.getPhone (),
			civil_status: this.getCivilStatus (),
			address: this.getAddress (),
			province: this.getProvince (),
			country: this.getCountry (),
			cp: this.getCp (),
			created_at: this.getCreatedAt (),
			updated_at: this.getUpdatedAt (),
		}
	}

}