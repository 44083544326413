import { Injectable } from '@angular/core';
import { routes } from '../config/routes';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { Error } from './../classes/Error'
import { ContactLawyer } from '../classes/ContactLawyer';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class ContactsLawyersService {

	private controller = 'ContactsLawyers';

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	
	getUrl () {
		return routes.api + this.controller + "/"
	}


	addContactsLawyers (contactsLawyers: ContactLawyer[]) {

		let err = new Error ()
		let contactsLawyersArray = []
		let errorMessage = 'No hemos podido añadir los contactos de los abogados. Vuelve a intentarlo'

		for (let contactLawyer of contactsLawyers) { contactsLawyersArray.push (contactLawyer.toArray ()) }

		let body = new HttpParams ().set ('contactsLawyers', JSON.stringify (contactsLawyersArray))

		return this.http.post (this.getUrl () + "addContactsLawyers", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				// if (typeof res.data.id != "undefined") {
				// 	agent.setId (res.data.id)
				// }
				
				//err.setData (agent)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'contact-lawyers.service')
				return err
			}
			

		}))
	}
	

	removeContactLawyer (id: number) {

		let err = new Error ()
		let body = new HttpParams ().set ('id', id.toString ())
		let errorMessage = 'No hemos podido eliminar los contactos. Vuelve a intentarlo'

		return this.http.post (this.getUrl() + "removeContactLawyer", body.toString (), {headers: httpHeaders}).pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {
				err.setData (res.data.id)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'contact-lawyers.service')
				return err
			}

		}))

	}
}
