import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { showSourceTree } from 'src/app/pages/homebox/animations/sourceTree.animations';
import { SourceTreeService } from '../../services/source-tree.service';
import { Folder } from 'src/app/classes/homebox/Folder';
import { HomeboxService } from '../../services/homebox.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	host: {class: 'header-homebox-component'},
	animations: [showSourceTree]
})
export class HeaderComponent implements OnInit, OnDestroy {

	searchOpen: boolean
	breadcrumbs: Folder[] = []
	getBreadcrumbsSub: Subscription = new Subscription ()

	@ViewChild ('searchInput') searchInput: ElementRef

	constructor( private sourceTreeService: SourceTreeService, public homeboxService: HomeboxService ) {
		this.searchOpen = false
	}

	ngOnInit() {
		
		this.getBreadcrumbsSub = this.sourceTreeService.getBreadcrumbs ().subscribe ((breadcrumbs: Folder[] = []) => {
			this.breadcrumbs = breadcrumbs
		})

	}

	ngOnDestroy () {
		this.getBreadcrumbsSub.unsubscribe ()
	}

	toggleSearchElement () {
		this.searchOpen = !this.searchOpen
		this.searchInput.nativeElement.focus ()
	}

	selectFolder (folder: Folder = new Folder ()) {
		this.sourceTreeService.setCurrentFolder (folder)
	}

	setSearchText () {
		let search = this.searchInput.nativeElement.value
		this.homeboxService.setSearch (search)
	}

	changeViewType (type: string = 'grid') {
		this.homeboxService.setType (type)
	}

}
