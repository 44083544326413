import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { Error } from '../classes/Error';
import { User } from '../classes/User';
import { Router } from '@angular/router';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class UserService {

	private controller = 'Users';

	constructor ( private http: HttpClient, private router: Router, private errorService: ErrorService ) { }

	private getUrl () {
		return routes.api + this.controller + "/"
	}

	public checkCode (password: string = "0") {

		let err = new Error ()
		let body = new HttpParams ().set ('code', password)
		let errorMessage = 'No hemos podido acceder a la aplicación. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "checkCode", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {

				err.setData (res.data)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'user.service')
				return err
			}
			
		}))
	}


	public recordUserInLocalStorage (user: User = new User ()) {
		
		if (user.getId () <= 0) { return; }

		localStorage.setItem ('user', JSON.stringify(user))
	}

	public isLogged () {

		let user = JSON.parse(localStorage.getItem ('user'))
		
		if (user != null && user.id > 0) {
			return true
		} else {
			return false
		}
	}

	public getUser () {

		let user = JSON.parse(localStorage.getItem ('user'))
		let userClass = new User (user)
		return userClass

	}

	public logOut () {
		
		localStorage.clear ()
		this.router.navigate (['login'])
	}


}
