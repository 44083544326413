import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ZonesService } from 'src/app/services/zones.service';
import { Zone } from 'src/app/classes/Zone';
import { ErrorInterface } from 'src/app/classes/Error';
import { showZones } from './../../../animations/zones.animations'
import { StorageService } from 'src/app/services/storage.service';
import { Garage } from 'src/app/classes/Garage';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-zones',
	templateUrl: './zones.component.html',
	styleUrls: ['./zones.component.scss'],
	animations: [ showZones ],
	host: {'class': 'zones-content'}
})
export class ZonesComponent implements OnInit, OnDestroy {

	zones: Zone[]
	lengthZones: number
	zoneSelected: Zone
	showPopupGarages: boolean

	getZonesSubscription: Subscription = new Subscription ()

	constructor(private zonesService: ZonesService, private route: ActivatedRoute, private storage: StorageService, private router: Router, private errorService: ErrorService) {
		this.zones = []
		this.lengthZones = null
		this.showPopupGarages = false
	}

	ngOnInit() {

		this.storage.setCurrentViewName ('zones')
		
		let promotionId = 0;

		for (let view of this.storage.viewList) {
			if (view.name == 'promotions') {
				promotionId = view.data.id;
			}
		}

		this.getZonesSubscription = this.zonesService.getZones (promotionId).subscribe ((data: ErrorInterface) => {
			
			if (!data.hasErrors ()) {
				this.zones = data.getData ()

				if (this.zones.length == 1 && typeof this.zones[0] != "undefined" && this.zones[0].getIdType() == 2) {
					this.storage.addView ('zones', this.router.url, this.zones[0])
					this.router.navigate(['/hometouch/map-zone'])
				}

				this.startAnimation (true)
				this.lengthZones = this.zones.length
			}

		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flats.component.ts'))
	}

	ngOnDestroy () {

		this.getZonesSubscription.unsubscribe ()
	}

	selectZone (zone: Zone) {
		
		this.storage.addView ('zones', this.router.url, zone)

		this.startAnimation (false, () => {

			if (zone.getIdType () == 1) {
				this.router.navigate (['/hometouch/flats'])
			} else {
				this.router.navigate (['/hometouch/map-zone'])
			}
		})
	}

	startAnimation (show: boolean = false, callback = () => {}) {

		let timer = 0

		this.zones.forEach ((zone: Zone) => {
			setTimeout (() => {
				zone.setShowAnimation (show)
			}, timer)

			timer += 70
		})

		setTimeout (() => { callback () }, timer + 500)
	}


	selectGarages (zone: Zone) {

		this.zoneSelected = zone

		if (zone.getGarages ().length == 1) {
			this.garageSelectorCallback (zone.getGarages ()[0]);
		} else {
			this.showPopupGarages = true
		}
	} 

	garageSelectorCallback (data: any) {

		this.showPopupGarages = false

		if (data == null) { return }
		else if (data instanceof Garage) {

			let garage: Garage = data

			this.storage.addView ('zones', this.router.url, this.zoneSelected)
			this.storage.addView ('garage', '/hometouch/garage', garage)
			this.storage.setCurrentViewName ('')

			this.startAnimation (false, () => {
				this.router.navigate (['/hometouch/garage'])
			})
		}
	}
}
