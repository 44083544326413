import { Injectable, EventEmitter } from '@angular/core';
import { googleCalendarSettings } from 'src/app/config/googleCalendarSettings'

declare var gapi


@Injectable({
	providedIn: 'root'
})
export class GoogleAouthService {

	private googleAuth: gapi.auth2.GoogleAuth
	private signedInEmitter: EventEmitter<boolean> = new EventEmitter<boolean> ()

	constructor () {
		this.googleAuth = null
	}

	initClient () {

		return new Promise((resolve, reject) => {

			gapi.load('client:auth2', () => {
				return gapi.client.init({

					apiKey: googleCalendarSettings.apiKey,
					clientId: googleCalendarSettings.client_id,
					discoveryDocs: googleCalendarSettings.discovery_docs,
					scope: googleCalendarSettings.scope,

				}).then(() => {                   
					this.googleAuth = gapi.auth2.getAuthInstance();

					gapi.auth2.getAuthInstance().isSignedIn.listen(() => { this.updateSigninStatus (gapi.auth2.getAuthInstance().isSignedIn.get()) });
					this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());

					resolve();
				});
			});
		});

	}

	updateSigninStatus(isSignedIn = false) {

		console.log ('updateSigninStatus', isSignedIn)

        if (isSignedIn) {
          this.signedInEmitter.emit (true)
        } else {
          this.signIn ()
        }
	}
	
	signIn () {
        gapi.auth2.getAuthInstance().signIn();
	}
	
	signOut () {
        gapi.auth2.getAuthInstance().signOut();
	}
	
	userIsSignedIn () {
		return this.signedInEmitter
	}
	
	
}
