import { routes } from '../config/routes';

export class Country {

	id: number = 0
	code: string = ""
	name: string = ""
	image_url: string = ""

	constructor (data?) {
		this.setId ((typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0)
		this.setCode ((typeof data != "undefined" && typeof data.code != "undefined") ? data.code : "")
		this.setName ((typeof data != "undefined" && typeof data.name != "undefined") ? data.name : "")
		this.setImageUrl ((typeof data != "undefined" && typeof data.image_url != "undefined") ? data.image_url : "")
	}

	setId (id: number = 0) { this.id = id }
	setCode (code: string = "") { this.code = code }
	setName (name: string = "") { this.name = name }
	setImageUrl (imageUrl: string = "") { this.image_url = imageUrl }

	getId (): number { return this.id }
	getCode (): string { return this.code }
	getName (): string { return this.name }
	getImageUrl (): string { return this.image_url }
	getFlagPath (): string { return routes.media + "flags/" + this.getImageUrl () }
 

}