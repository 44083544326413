import { Component, OnInit } from '@angular/core';
import { showPreferencesButton } from 'src/app/animations/preferences.animations';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	animations: [ showPreferencesButton ]
})
export class DashboardComponent implements OnInit {

	animations: boolean[]

	constructor() {
		this.animations = [false, false, false, false, false]
	}

	ngOnInit() {

		this.startAnimation (true)

	}

	startAnimation (flag: boolean = false) {
		let timer = 0

		for (let i = 0; i < this.animations.length; i++) {
			setTimeout (() => {
				this.animations[i] = flag
			}, timer)

			timer += 100
		}

		
	}

}
