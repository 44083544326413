import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { Error } from '../classes/Error';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { map } from 'rxjs/Operators';
import { StorageRoom } from '../classes/StorageRoom';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class StorageRoomsService {

	private controller: string

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller = 'StorageRooms'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}

	public addStorageRoom (storageRoom: StorageRoom) {

		let err = new Error ()
		let body = new HttpParams ().set ('storageRoom', storageRoom.toJson ())
		let errorMessage = 'No hemos podido actualizar el trastero. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "addStorageRoom", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (storageRoom)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'storage-rooms.service')
				return err
			}
		}))
	}
}
