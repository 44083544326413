import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'
import { HttpParams, HttpClient } from '@angular/common/http';
import { httpHeaders } from '../config/httpStatus';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  private controller: string

	constructor( private http: HttpClient, private errorService: ErrorService, private toastr: ToastrService ) {
		this.controller = 'Errors_controller'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}

  //error personalizado
  notifyError (error: any = "", message: string = "", title: string = "", controllerName: string = "") {

  	this.toastr.warning (message, title)

    //httpost para grabar el error en la base de datos
    let body = new HttpParams ().set ('error', JSON.stringify (error)).set ('message', JSON.stringify (message)).set ('controller', JSON.stringify (controllerName))
    
    this.http.post (this.getUrl () + "recordError", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {
    })).subscribe ()
		
		

  }

  //error general
   notifyMainError (error: any = "", controllerName: string = "") {
  	
   	this.notifyError (error, 'Hemos detectado un error y ya hemos sido notificados', 'Ups..!', controllerName)

  }

}
