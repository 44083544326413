import { Component, OnInit, ElementRef, Renderer2, Inject, OnDestroy } from '@angular/core';
import { Garage } from 'src/app/classes/Garage';
import { StorageService } from 'src/app/services/storage.service';
import { GaragesService } from 'src/app/services/garages.service';
import { Error } from 'src/app/classes/Error';
import * as interact from 'interactjs';
import { DOCUMENT } from '@angular/common';
import { httpStatus } from 'src/app/config/httpStatus';
import { ParkingSpace } from 'src/app/classes/ParkingSpace';
import { StorageRoom } from 'src/app/classes/StorageRoom';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-garage',
    templateUrl: './garage.component.html',
    styleUrls: ['./garage.component.scss'],
    host: {'class': 'map-zone-content'} 
})
export class GarageComponent implements OnInit, OnDestroy {

    garage: Garage
    parkingSpaces: ParkingSpace[]
    storageRooms: StorageRoom[]
    parkingSpaceSelected: ParkingSpace
    storageRoomSelected: StorageRoom
    timeIntParkingSpaces: NodeJS.Timer
    timeIntStorageRooms: NodeJS.Timer
    querSelectorParkingSpaces: string
    querSelectorStorageRooms: string
    xmlns: string
    showPopupParkingSpace: boolean
    showPopupStorageRoom: boolean

    //interactions
    scale: number
    angle: number
    gestureArea: HTMLElement
    scaleElement: HTMLElement
    isMoving: boolean

    getParkingSpacesByIdGarageSub: Subscription = new Subscription ()
    getStorageRoomsByIdGarageSub: Subscription = new Subscription ()

    constructor( private storage: StorageService, private garagesService: GaragesService, private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private errorService: ErrorService ) {
        this.querSelectorParkingSpaces = '#plazas'
        this.querSelectorStorageRooms = '#trasteros'
        this.xmlns = "http://www.w3.org/2000/svg"
        this.scale = 1
        this.angle = 0
        this.isMoving = false

        this.showPopupParkingSpace = false
        this.showPopupStorageRoom = false
        
    }

    ngOnInit() {

        for (let view of this.storage.viewList) {
            if (view.name == 'garage') {
                this.garage = new Garage(view.data);
            }
        }

        //obtiene el listado de plazas de garaje
        this.garagesService.getParkingSpacesByIdGarage (this.garage.getId ()).subscribe ((res: Error) => {

            if (res.status == httpStatus.success && res.data.length > 0) {

                this.parkingSpaces = res.data
                
                this.timeIntParkingSpaces = setInterval (() => {

                    if (this.elementRef.nativeElement.querySelector(this.querSelectorParkingSpaces)) {
                        clearInterval (this.timeIntParkingSpaces)
                        this.printGaragesInPlan ()
                        this.initInteractions ()
                    }

                }, 500)
            }
        }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'garage.component.ts'))

        //obtiene el listado de trasteros
        this.getStorageRoomsByIdGarageSub = this.garagesService.getStorageRoomsByIdGarage (this.garage.getId ()).subscribe ((res: Error) => {

            if (res.status == httpStatus.success && res.data.length > 0) {

                this.storageRooms = res.data
                
                this.timeIntStorageRooms = setInterval (() => {

                    if (this.elementRef.nativeElement.querySelector(this.querSelectorStorageRooms)) {
                        clearInterval (this.timeIntStorageRooms)
                        this.printStorageRoomsInPlan ()
                        this.initInteractions ()
                    }

                }, 500)
            }
        }, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'garage.component.ts'))
    }

    ngOnDestroy () {
        this.getParkingSpacesByIdGarageSub.unsubscribe ()
        this.getStorageRoomsByIdGarageSub.unsubscribe ()
    }


    /* ----------------------------- */
    /* ------- STORAGE ROOMS ------- */
    /* ----------------------------- */

    printStorageRoomsInPlan () {

        var contentPolygons = this.elementRef.nativeElement.querySelector(this.querSelectorStorageRooms);

        while (contentPolygons.firstChild) {
            contentPolygons.removeChild(contentPolygons.firstChild);
        }

        for (let storageRoom of this.storageRooms) {
            
            let polygon = this.document.createElementNS(this.xmlns, 'polygon');
            this.renderer.setAttribute (polygon, 'points', storageRoom.getCoordsMap ())
            this.renderer.setAttribute (polygon, 'fill', storageRoom.getStatus ().getRgb ())
            this.renderer.setAttribute (polygon, 'stroke', '#2B2A29')
            this.renderer.setAttribute (polygon, 'stroke-width', '2')
            this.renderer.setAttribute (polygon, 'data-id', storageRoom.getId ().toString ())

            this.renderer.listen (polygon, 'click', (event) => {

                let element = event.target || event.srcElement
                
                let storageRoomId = element.dataset.id
                this.openStorageRoom (storageRoomId)

            })

            this.renderer.appendChild(contentPolygons, polygon);
        }
    }

    openStorageRoom (storageRoomId: number) {

        if (this.isMoving) return 
        
        this.storageRoomSelected = this.storageRooms.find ((storageRoom: StorageRoom) => storageRoom.getId () == storageRoomId)
        this.showPopupStorageRoom = true
    }

    closePopupStorageRoomCallback (storageRoom: StorageRoom) {
        
        this.showPopupStorageRoom = false
        this.printStorageRoomsInPlan ()
    }


    /* --------------------------------- */
    /* ------- END STORAGE ROOMS ------- */
    /* --------------------------------- */


    /* ------------------------------ */
    /* ------- PARKING SPACES ------- */
    /* ------------------------------ */

    printGaragesInPlan () {

        var contentPolygons = this.elementRef.nativeElement.querySelector(this.querSelectorParkingSpaces);

        while (contentPolygons.firstChild) {
            contentPolygons.removeChild(contentPolygons.firstChild);
        }

        for (let parkingSpace of this.parkingSpaces) {
            
            let polygon = this.document.createElementNS(this.xmlns, 'polygon');
            this.renderer.setAttribute (polygon, 'points', parkingSpace.getCoordsMap ())
            this.renderer.setAttribute (polygon, 'fill', parkingSpace.getStatus ().getRgb ())
            this.renderer.setAttribute (polygon, 'stroke', '#2B2A29')
            this.renderer.setAttribute (polygon, 'stroke-width', '2')
            this.renderer.setAttribute (polygon, 'data-id', parkingSpace.getId ().toString ())

            this.renderer.listen (polygon, 'click', (event) => {

                let element = event.target || event.srcElement
                let parkingSpaceId = element.dataset.id

                this.openGarage (parkingSpaceId)
            })

            this.renderer.appendChild(contentPolygons, polygon);
        }
    }


    openGarage (parkingSpaceId: number) {

        if (this.isMoving) return 

        this.parkingSpaceSelected = this.parkingSpaces.find ((parkingSpace: ParkingSpace) => parkingSpace.getId () == parkingSpaceId)
        this.showPopupParkingSpace = true
    }

    closePopupParkingSpaceCallback (parkingSpace: ParkingSpace) {
        
        this.showPopupParkingSpace = false
        this.printGaragesInPlan ()
    }

    /* ---------------------------------- */
    /* ------- END PARKING SPACES ------- */
    /* ---------------------------------- */




    /* ---------------------------- */
    /* ------- INTERACTIONS ------- */
    /* ---------------------------- */
    initInteractions () {

        this.gestureArea = this.document.getElementById ('svg-plan')
        this.scaleElement = this.document.getElementById ('svg-content-plan')

        interact(this.gestureArea).gesturable({

                onstart: (event) => { this.isMoving = true },
                onend: (event) => { setTimeout (() => { this.isMoving = false }, 100) },

                onmove: (event) => {
                    
                    this.scale = this.scale * (1 + event.ds);
                    this.angle += event.da;

                    this.setScaleAndRotation ()
                    this.dragMoveListener(event);
                },

            }).draggable({
                
                inertia: true,
                onmove: (event) => { this.dragMoveListener (event) },
                onstart: (event) => { this.isMoving = true },
                onend: (event) => { setTimeout (() => { this.isMoving = false }, 100) }
            });
    }


    setScaleAndRotation () {

        this.scaleElement = this.document.getElementById ('svg-content-plan')
        this.scaleElement.style.webkitTransform = this.scaleElement.style.transform = 'scale(' + this.scale + ') rotate(' + this.angle + 'deg)';
    }


    dragMoveListener (event) {

        let target = event.target
        let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

        target.style.webkitTransform =
        target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)';

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
    }

    /* -------------------------------- */
    /* ------- END INTERACTIONS ------- */
    /* -------------------------------- */

}
