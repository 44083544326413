import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { popupBackground } from 'src/app/animations/popup.animation';
import { Agency } from 'src/app/classes/Agency';
import { AgenciesService } from 'src/app/services/agencies.service';
import { Country } from 'src/app/classes/country';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { CountriesService } from 'src/app/services/countries.service';
import { takeUntil } from 'rxjs/Operators';
import { Agent } from 'src/app/classes/Agent';
import { AgentsService } from 'src/app/services/agents.service';
import { ToastrService } from 'ngx-toastr'
import { ErrorService } from 'src/app/services/error.service'
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-agency',
	templateUrl: './agency.component.html',
	styleUrls: ['./agency.component.scss'],
	animations: [ popupBackground ]
})
export class AgencyComponent implements OnInit, OnDestroy {

	isWorking: boolean
	initAnimations: boolean = false

	@Input ('agency')
	set agency (agency: Agency) {
		this._agency = agency

		let agentsClasses = []
		this.originalAgency = new Agency (JSON.parse (JSON.stringify (agency)))
		this.originalAgency.setMarketClass (new Country (this.originalAgency.marketClass))
		
		this.changedAgency = new Agency (JSON.parse (JSON.stringify (agency)))
		this.changedAgency.setMarketClass (new Country (this.changedAgency.marketClass))

		for (let agent of this._agency.getAgents ()) {
			agentsClasses.push (new Agent (JSON.parse (JSON.stringify (agent))))
		}

		this.originalAgency.setAgents (agentsClasses)
		this.changedAgency.setAgents (agentsClasses)

		this.isWorking = false

		console.log (this.changedAgency)
		
		setTimeout (() => { this.initAnimations = true }, 0)
	}
	
	@Output () closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean> ()
	_agency: Agency
	originalAgency: Agency
	changedAgency: Agency

	//select
	countries: Country[]
	formCountriesControl = new FormControl()
	formFilteredCountriesControl = new FormControl ()
	filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
	protected _onDestroy = new Subject<void>();

	getCountriesSubscription: Subscription = new Subscription ()
	addAgencySubscription: Subscription = new Subscription ()
	addAgentsSubscription: Subscription = new Subscription ()

	constructor ( private agenciesService: AgenciesService, private countriesService: CountriesService, private agentsService: AgentsService, private toastr: ToastrService, private errorService: ErrorService ) {
		this.isWorking = false
		this.initAnimations = false
	}

	ngOnInit() {

		this.getCountriesSubscription = this.countriesService.getCountries ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {
				this.countries = res.data

				this.formCountriesControl.setValue(this.countries);
				this.filteredCountries.next(this.countries.slice());

				this.formFilteredCountriesControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(
					() => this.filterCountries() ,
					(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agency.component.ts'));

				this.formCountriesControl.valueChanges.subscribe (res => {
					
					if (res instanceof Country) {
						this.changedAgency.setMarketClass (res)
						this.changedAgency.setMarket (res.getId ())
					}
				})	
			}

		},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agency.component.ts'))
	}

	ngOnDestroy () {

		this.getCountriesSubscription.unsubscribe ()
		this.addAgencySubscription.unsubscribe ()
		this.addAgentsSubscription.unsubscribe ()
	}

	close () {

		this._agency.clone (this.originalAgency)
		this.closePopup (false)
	}

	save () {

		if (this.changedAgency.getMarket () <= 0 || this.changedAgency.getMarket () == null) {

			this.toastr.warning ('Es necesario añadir un mercado', 'Espera...');

		} else {

			this._agency.clone (this.changedAgency)
			this.isWorking = true
			this.addAgency ()
		}
	}

	addAgency () {

		this.addAgencySubscription = this.agenciesService.addAgency (this._agency).subscribe ((res: any) => {
			
			if (res.status == httpStatus.success) {

				if (typeof res.data.id != "undefined") {
					this._agency.setId (res.data.id)
				}

				for (let agent of this._agency.getAgents ())
					agent.setIdPromoter (this._agency.getId ())

				this.addAgentsSubscription = this.agentsService.addAgents (this._agency.getAgents ()).subscribe ((res: Error) => {

					if (res.status == httpStatus.success) {
						this.closePopup (true)
					}

				}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agency.component.ts'))	
			}
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agency.component.ts'))
	}

	closePopup (flag: boolean = false) {
		
		this.initAnimations = false
		setTimeout (() => this.closeEmitter.emit (flag) , 300)
	}

	private filterCountries () {

		if (!this.countries) return;

		let search = this.formFilteredCountriesControl.value;

		if (!search) {
			this.filteredCountries.next(this.countries.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		
		this.filteredCountries.next(
			this.countries.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
		);
	}

	addAgent () {

		let agent = new Agent()
		agent.setIdPromoter (this.changedAgency.getId ())

		this.changedAgency.agents.push (agent)
	}

	removeAgentCallback (agent: Agent) {

		this.changedAgency.agents.splice (this.changedAgency.agents.indexOf (agent), 1)

	} 

}
