import { Component, OnInit, Input } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';

@Component({
	selector: 'app-flat',
	templateUrl: './flat.component.html',
	styleUrls: ['./flat.component.scss'],
	host: {'class': 'flat-component'}
})

export class FlatComponent implements OnInit {

	@Input() flat: Flat
	currentDate: Date

	constructor() {
		this.currentDate = new Date ()
	}

	ngOnInit() {
		console.log (this.flat)
	}

	getBackgroundStatusStyle (flat: Flat) {

		if (flat.getHasBeenCancelled ()) {

			if (flat.getStatus ().getId () == 7) {
				return {'background-image': 'url(assets/media/icons/close-white.png)', 'background-color': flat.getStatus ().getRgb ()}
			} else {
				return {'background-image': 'url(assets/media/icons/close.png)', 'background-color': flat.getStatus ().getRgb ()}
			}
		}

		return {'background-color': flat.getStatus ().getRgb ()}

	}
}
