import { Component, OnInit, OnDestroy } from '@angular/core';
import { routes } from 'src/app/config/routes';
import { Promotion } from 'src/app/classes/Promotion';
import { showBreadcrumb, showLogo } from 'src/app/animations/breadcrumbs.animations';
import { StorageService } from 'src/app/services/storage.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { Location } from '@angular/common';
import { AlertsService } from 'src/app/services/alerts.service';
import { AlertClass } from 'src/app/classes/AlertClass'
import { Subscription } from 'rxjs';
import { showBackground, showAlertsList } from 'src/app/animations/alerts.animations';
import { User } from 'src/app/classes/User';
import { UserService } from 'src/app/services/user.service';


@Component({
	selector: 'app-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	styleUrls: ['./breadcrumbs.component.scss'],
	animations: [ showBreadcrumb, showLogo, showAlertsList ]
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

	urlLogo: string
	urlPromotionLogo: string
	promotion: Promotion
	loadingFinished: boolean = false
	viewList = []
	currentViewName = ""
	showAlertsContainer: boolean
	lengthAlerts: number
	getViewListSub: Subscription = new Subscription ()
	getCurrentViewNameSub: Subscription = new Subscription ()
	getPromotionLogoSub: Subscription = new Subscription ()
	getAlertsFromApiSub: Subscription = new Subscription ()
	getAlertsSub: Subscription = new Subscription ()

	user: User

	constructor( private breadcrumbsService: BreadcrumbsService, private storage: StorageService, private _location: Location, private alertsService: AlertsService, private userService: UserService ) {
		this.urlLogo = routes.media + "logo/logo-orange.png"
		this.loadingFinished = false
		this. showAlertsContainer = false
		this.lengthAlerts = 0
	}

	ngOnInit() {

		setTimeout (() => {this.loadingFinished = true}, 500)

		this.viewList = this.storage.viewList
		
		this.getViewListSub = this.storage.getViewList ().subscribe ((viewList: any) => {
			this.viewList = viewList
		})

		this.currentViewName = this.storage.currentViewName

		this.getCurrentViewNameSub = this.storage.getCurrentViewName (). subscribe ((viewName: string) => {
			this.currentViewName = viewName
		})

		this.getPromotionLogoSub = this.breadcrumbsService.getPromotionLogo ().subscribe ((logo: string) => {
			this.urlPromotionLogo = logo
		})

		this.getAlertsFromApiSub = this.alertsService.getAlertsFromApi ().subscribe ((alerts: AlertClass[]) => {
			this.lengthAlerts = alerts.length
		})

		this.getAlertsSub = this.alertsService.getAlerts ().subscribe ((alerts: AlertClass[]) => {
			this.lengthAlerts = alerts.length
		})

		this.user = this.userService.getUser ()
	}

	ngOnDestroy () {

		this.getViewListSub.unsubscribe ()
		this.getCurrentViewNameSub.unsubscribe ()
		this.getPromotionLogoSub.unsubscribe ()
		this.getAlertsFromApiSub.unsubscribe ()
		this.getAlertsSub.unsubscribe ()
	}

	backClicked() {
		
		this._location.back();
	  }

	

}
