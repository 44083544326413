import { routes } from './../config/routes'
import { Garage } from './Garage';

export interface ZoneInterface {
	id: number
	id_promotion: number
	id_type: number
	name: string
	created_at: Date
	sold: boolean
	image_url: string
	order: number
	garages: Garage[]
	showAnimation: boolean

	setId (): void
	setIdPromotion (): void
	setIdType (): void
	setName (): void
	setCreatedAt (): void
	setSold (): void
	setImageUrl (): void
	setOrder (): void
	setGarages (): void
	getShowAnimation (): boolean

	getId (): number
	getIdPromotion (): number
	getIdType (): number
	getName (): string
	getCreatedAt (): Date
	getSold (): boolean
	getImageUrl (): string
	getOrder (): number
	getGarages (): Garage[]
	setShowAnimation (): boolean
}

export class Zone {
	id = 0
	id_promotion = 0
	id_type = 0
	name = ""
	created_at = null
	sold = false
	image_url = ""
	order = 0
	garages = []
	showAnimation = false
	

	constructor (data?: ZoneInterface) {
		this.id = (typeof data != "undefined" && typeof data.id != "undefined") ? data.id : 0
		this.id_promotion = (typeof data != "undefined" && typeof data.id_promotion != "undefined") ? data.id_promotion : 0
		this.id_type = (typeof data != "undefined" && typeof data.id_type != "undefined") ? data.id_type : 0
		this.name = (typeof data != "undefined" && typeof data.name != "undefined") ? data.name : ""
		this.created_at = (typeof data != "undefined" && typeof data.created_at != "undefined") ? data.created_at : null
		this.sold = (typeof data != "undefined" && typeof data.sold != "undefined") ? data.sold : false
		this.image_url = (typeof data != "undefined" && typeof data.image_url != "undefined") ? data.image_url : ""
		this.order = (typeof data != "undefined" && typeof data.order != "undefined") ? data.order : 0
		this.garages = (typeof data != "undefined" && typeof data.garages != "undefined") ? data.garages : []
	}

	setId (id: number = 0) { this.id = id }
	setIdPromotion (idPromotion: number = 0) { this.id_promotion = idPromotion }
	setIdType (idType: number = 0) { this.id_type = idType }
	setName (name: string = "") { this.name = name }
	setCreatedAt (date: Date = new Date) { this.created_at = date }
	setSold (sold: boolean = false) { this.sold = sold }
	setImageUrl (imageUrl: string = "") { this.image_url = imageUrl }
	setOrder (order: number = 0) { this.order = order }
	setGarages (garages: Garage[] = []) { this.garages = garages }
	setShowAnimation (flag: boolean = false) { this.showAnimation = flag }

	getId () { return this.id }
	getIdPromotion () { return this.id_promotion }
	getIdType () { return this.id_type }
	getName () { return this.name }
	getCreatedAt () { return this.created_at }
	getSold () { return this.sold }
	getImageUrl () { return routes.media + "zones/" + this.image_url }
	getOrder () { return this.order }
	getGarages () { return this.garages }
	getShowAnimation () { return this.showAnimation }
}