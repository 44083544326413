import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { routes } from './../config/routes'
import { httpStatus, httpHeaders } from './../config/httpStatus'
import { map } from 'rxjs/Operators'
import { Promotion } from '../classes/Promotion';
import { Error } from './../classes/Error'
import { ErrorService } from './error.service';


@Injectable({
    providedIn: 'root'
})

export class PromotionsService {

    constructor( private http: HttpClient, private errorService: ErrorService ) { }

    private controller = 'Promotions';

    getUrl () {
        return routes.api + this.controller + "/"
    }

    getPromotions () {

        let promotionsClasses = []
        let err = new Error ()
        let errorMessage = 'No hemos podido descargar las promociones. Vuelve a intentarlo'

        return this.http.get (this.getUrl () + "getPromotions").pipe (map ((res: any) => {
            
            if (res.status == httpStatus.success) {
                let promotions = res.data;
                
                for (let promotion of promotions) {
                    let prom = promotion
                    prom.active = (prom.active == 1) ? true : false
                    prom.created_at = new Date (prom.created_at)
                    prom.sold = (prom.sold == 1) ? true : false
                    promotionsClasses.push (new Promotion (prom))
                }

                err.setData (promotionsClasses)

                return err

            }  else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, 'Ups..!', 'promotions.service')
                return err
            }

        }))

    }


    getPromotionByUrl (url: string) {

        let promotion: Promotion
        let err = new Error ()
        let body = new HttpParams ().set ('url', url)
        let errorMessage = 'No hemos podido descargar la promoción. Vuelve a intentarlo'

        return this.http.post (this.getUrl () + 'getPromotionByUrl', body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

            if (res.status == httpStatus.success && res.data != null) {
                
                let prom = res.data;
                    prom.active = (prom.active == 1) ? true : false
                    prom.created_at = new Date (prom.created_at)
                    prom.sold = (prom.sold == 1) ? true : false
                    promotion = new Promotion (prom)

                err.setData (promotion)

                return err

            }  else {

                err.setError (errorMessage)
                this.errorService.notifyError (res, errorMessage, 'Ups..!', 'promotions.service')
                return err
            }

        }))

    }

}
