import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { routes } from '../config/routes';
import { map } from 'rxjs/Operators';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { Error } from './../classes/Error';
import { Document } from './../classes/Document';
import { ErrorService } from './error.service';


@Injectable({
	providedIn: 'root'
})
export class DocumentsService {

	private controller: string

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller = 'Documents'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}

	getDocumentsByIdFlat (idFlat: number) {

		let err = new Error ()
		let documentsClasses: Document[] = []
		let body = new HttpParams ().set ('idFlat', idFlat.toString ())
		let errorMessage = 'No hemos podido obtener los documentos. Vuelve a intentarlo'
		
		return this.http.post (this.getUrl () + "getDocuments", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				for (let doc of res.data) {

					doc.created_at = new Date (doc.created_at)
					let document = new Document (doc)

					documentsClasses.push (document)

				}

				err.setData (documentsClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'documents.service')
				return err
			}
			

		}))
	}

	removeDocument (id: number = 0) {

		let err = new Error ()
		let documentsClasses: Document[] = []

		let headers = new HttpHeaders ({
			'Content-Type': 'application/x-www-form-urlencoded'
		})

		let body = new HttpParams ()
		.set ('id', id.toString ())
		

		return this.http.post (this.getUrl () + "removeDocument", body.toString (), { headers: headers }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				return err

			} else {

				err.setError ('Ups..! No hemos podido obtener los documentos. Vuelve a intentarlo')
				return err
			}
			

		}, () => {
			err.setError ('Ups..! No hemos podido obtener los documentos. Vuelve a intentarlo')
			return err
		}))
	}

}
