import { httpStatus } from './../config/httpStatus'

export interface ErrorInterface {
	status: httpStatus
	errors: string[]
	data: any
	customData: any

	setError (errors: any): void
	setData (data: any): void
	setKey (key: string, value: any): void
	hasErrors (): boolean
	getKey (key: string): any
	getData (): any
}

export class Error implements ErrorInterface {

	status = httpStatus.success
	errors = []
	data = []
	customData = []

	constructor (...error: any) {
		
		this.status = (typeof error.status != "undefined") ? error.status : (typeof error[0] != "undefined") ? error[0] : httpStatus.success
		this.errors = (typeof error.errors != "undefined") ? error.errors : (typeof error[1] != "undefined") ? error[1] : []
		this.data = (typeof error.data != "undefined") ? error.data : (typeof error[2] != "undefined") ? error[2] : []
		this.customData = (typeof error.customData != "undefined") ? error.customData : (typeof error[3] != "undefined") ? error[3] : []
	}

	setError (errors: any = []) {

		this.status = httpStatus.error

		if (this.errors.length == 0) {
			this.errors = errors

		}else if (errors.isArray ()) {
			this.errors.concat (errors)

		}else {
			this.errors.push (errors)
		}

	}

	getData (): any {
		return this.data
	}

	setData (data: any = []) {
		
		this.data = data
	}

	setKey (key: string, value: any) {
		this.customData[key] = value
	}

	hasErrors () {
		if (this.status == httpStatus.error) return true
		return false
	}

	getKey (key: string) {
		if (typeof this.customData[key] != "undefined") {
			return this.customData[key]
		} else {
			return null
		}
	}

	
}