import { trigger, state, style, animate, transition, keyframes, AnimationTriggerMetadata } from '@angular/animations';

export const showNavbar: AnimationTriggerMetadata = trigger('showNavbar', [

	
	state ('false', style({
		opacity: 0,
		transform: "scale(.6)  translateY(200px)"
	})),

	state ('true', style({
		opacity: 1,
		transform: "scale(1) translateY(0px)"
	})),

	//hide
	transition ('true => false', animate('.5s ease-in-out', keyframes ([
		style ({opacity: 1, transform: 'scale(1)', offset: 0}),
		style ({opacity: 1, transform: 'scale(1.3)', offset: .3}),
		style ({opacity: 0, transform: 'scale(0)', offset: 1}),
	]))),

	//show
	transition ('false => true', animate('.5s ease-out', keyframes ([
		style ({opacity: 0, transform: 'scale(.6) translateY(200px)', offset: 0}),
		style ({opacity: 1, transform: 'scale(1) translateY(0px)', offset: 1}),
	])))
	

	// state ('false', style({
	// 	opacity: 0,
	// 	transform: "translateY(40px)"
	// })),

	// state ('true', style({
	// 	opacity: 1,
	// 	transform: "translateY(0px)"
	// })),

	// //hide
	// transition ('true => false', animate('.5s ease-in-out')),

	// //show
	// transition ('false => true', animate('.5s ease-in-out'))
])