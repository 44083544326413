import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Costumer } from 'src/app/classes/Costumer';
import { CostumersService } from 'src/app/services/costumers.service';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { popupBackground } from 'src/app/animations/popup.animation';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-costumer',
	templateUrl: './costumer.component.html',
	styleUrls: ['./costumer.component.scss'],
	animations: [ popupBackground ]
})
export class CostumerComponent implements OnInit, OnDestroy {

	isWorking: boolean
	initAnimations: boolean = false

	@Input ('costumer')
	set costumer (costumer: Costumer) {
		this._costumer = costumer
		this.originalCostumer = new Costumer (JSON.parse (JSON.stringify (costumer)))
		this.changedCostumer = new Costumer (JSON.parse (JSON.stringify (costumer)))
		this.isWorking = false
		
		setTimeout (() => { this.initAnimations = true }, 0)
	}
	
	@Output () closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean> ()
	_costumer: Costumer
	originalCostumer: Costumer
	changedCostumer: Costumer

	addCostumerSubscription: Subscription = new Subscription ()

	constructor ( private costumerService: CostumersService, private errorService: ErrorService ) {
		this.isWorking = false
		this.initAnimations = false
	}

	ngOnInit() { }

	ngOnDestroy () {

		this.addCostumerSubscription.unsubscribe ()
	}

	close () {

		this._costumer.clone (this.originalCostumer)
		this.closePopup (false)
	}

	save () {
		
		this._costumer.clone (this.changedCostumer)
		this.isWorking = true
		this.addCostumer ()
	}

	addCostumer () {

		this.addCostumerSubscription = this.costumerService.addCostumer (this._costumer).subscribe ((res: Error) => {
			
			if (res.status == httpStatus.success) {

				this.closePopup (true)
			}
			
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'costumer.component.ts'))
	}

	closePopup (flag: boolean = false) {
		
		this.initAnimations = false
		setTimeout (() => { this.closeEmitter.emit (flag) }, 300)
	}

}
