import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from 'src/app/config/routes';
import { map } from 'rxjs/Operators';
import { Error } from 'src/app/classes/Error';
import { Folder } from 'src/app/classes/homebox/Folder';
import { httpStatus } from 'src/app/config/httpStatus';
import { SourceTreeService } from './source-tree.service';

@Injectable({
	providedIn: 'root'
})
export class FolderService {

	private controller: string
	private selectedFolder: Folder
	private folderSelectorCallback: EventEmitter<boolean> = new EventEmitter<boolean> ()

	constructor( private http: HttpClient, private sourceTreeService: SourceTreeService ) {
		this.controller = 'Folders'
		this.selectedFolder = new Folder ()
	}

	private getUrl () { return routes.api + this.controller + "/" }

	public getFolderSelectorCallback () { return this.folderSelectorCallback }

	public getSelectedFolder () { return this.selectedFolder }

	public setSelectedFolder (folder: Folder = new Folder ()) { this.selectedFolder = folder }

	//añadir o actualizar una carpeta
	public addFolder (folder: Folder = new Folder ()) {

		let err = new Error ()
		let headers = new HttpHeaders ({ 'Content-Type': 'application/x-www-form-urlencoded'})
		let body = new HttpParams ().set ('folder', folder.toJson ())

		return this.http.post (this.getUrl () + "addFolder", body.toString (), { headers: headers }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let folder = new Folder (res.data)
				err.setData (folder)
				return err
			}
			
		}, () => {
			err.setError ('Ups..! No hemos podido mostrar el árbol de carpetas. Vuelve a intentarlo')
			return err
		}))
	}

	//mover una carpeta de carpeta
	public moveFolder (destinationFolder: Folder = new Folder ()) {

		if (destinationFolder == this.selectedFolder) {
			this.emitFolderSelectorCallback (false)
		}

		let err = new Error ()
		let headers = new HttpHeaders ({ 'Content-Type': 'application/x-www-form-urlencoded'})
		let body = new HttpParams ()
		.set ('idFolderToMove', JSON.stringify (this.selectedFolder.getId ()))
		.set ('idDestinationFolder', JSON.stringify (destinationFolder.getId ()))


		return this.http.post (this.getUrl () + "moveFolder", body.toString (), { headers: headers }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				
				return err
			}
			
		}, () => {
			err.setError ('Ups..! No hemos podido mover la carpeta. Vuelve a intentarlo')
			return err
		}))
	}

	public emitFolderSelectorCallback (flag: boolean = false) {
		this.folderSelectorCallback.emit (flag)
	}

	//descargar carpeta
	public downloadFolder (folder: Folder = new Folder ()) {

		window.open (this.getUrl () + "downloadFolder/" + folder.getId (), '_blank')
		
	}

	//eliminar carpeta
	public removeFolder (folder: Folder = new Folder ()) {

		let err = new Error ()
		let headers = new HttpHeaders ({ 'Content-Type': 'application/x-www-form-urlencoded'})
		let body = new HttpParams ().set ('idFolder', JSON.stringify (folder.getId ()))

		return this.http.post (this.getUrl () + "removeFolder", body.toString (), { headers: headers }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				
				err.setData (folder)
				return err
			}
			
		}, () => {
			err.setError ('Ups..! No hemos podido eliminar la carpeta. Vuelve a intentarlo')
			return err
		}))

	}
}
