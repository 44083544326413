/* export enum routes {
	base_url = 'http://hometouch/',
	assets = 'http://hometouch/assets/',
	media = 'http://hometouch/application/assets/media/',
	api = 'http://hometouch/',
} */

export enum routes {
	base_url = 'https://hometouch.dicopgroup.com/',
	assets = 'https://hometouch.dicopgroup.com/assets/',
	media = 'https://hometouch.dicopgroup.com/api/application/assets/media/',
	api = 'https://hometouch.dicopgroup.com/api/',
}
