import { Component, OnInit } from '@angular/core';
import { Flat } from 'src/app/classes/Flat';
import { FlatService } from 'src/app/services/flat.service';
import * as moment from 'moment';
import { HistoryLog } from 'src/app/classes/HistoryLog';
import { HistoryLogsService } from 'src/app/services/history-logs.service';
import { UserService } from 'src/app/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';



@Component({
	selector: 'app-flat-information-information',
	templateUrl: './flat-information-information.component.html',
	styleUrls: ['./flat-information-information.component.scss']
})
export class FlatInformationInformationComponent implements OnInit {

	flat: Flat
	datepickerSettings: any
	currentDate: Date

	constructor (private flatService: FlatService, private historyLogService: HistoryLogsService, private userService: UserService, private errorService: ErrorService) {
		
		this.datepickerSettings = {
			bigBanner: false,
			timePicker: false,
			format: 'dd-MM-yyyy',
			defaultOpen: false
		}

		this.currentDate = new Date ()
	}

	ngOnInit() {

		this.flat = this.flatService.flat
		this.flatService.getFlat ().subscribe ((flat: Flat) => this.flat = flat )
	}

	setBaths (event) {
		let baths = parseInt (this.getValFromEvent (event))

		if (isNaN (baths)) baths = 0

		this.flat.setBaths (baths)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado el nº de baños: ' + baths)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setBeds (event) {
		let beds = parseInt (this.getValFromEvent (event))

		if (isNaN (beds)) beds = 0

		this.flat.setBeds (beds)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado el nº de habitaciones: ' + beds)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setSuperficieUtil (event) {
		let usefulSurface = parseInt (this.getValFromEvent (event))

		if (isNaN (usefulSurface)) usefulSurface = 0

		this.flat.setUsefulSurface (usefulSurface)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado el la superficie útil: ' + usefulSurface)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setTerraza (event) {
		let terrace = parseInt (this.getValFromEvent (event))

		if (isNaN (terrace)) terrace = 0

		this.flat.setTerrace (terrace)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado la terraza: ' + terrace)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setSolarium (event) {
		let solarium = parseInt (this.getValFromEvent (event))

		if (isNaN (solarium)) solarium = 0

		this.flat.setSolarium (solarium)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado el solarium: ' + solarium)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setSuperficieTotal (event) {
		let totalSurface = parseInt (this.getValFromEvent (event))

		if (isNaN (totalSurface)) totalSurface = 0

		this.flat.setTotalSurface (totalSurface)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado la superficie total: ' + totalSurface)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setGarden (event) {
		let garden = parseInt (this.getValFromEvent (event))

		if (isNaN (garden)) garden = 0

		this.flat.setGarden (garden)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado el jardín: ' + garden)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	getValFromEvent (event) {
		return event.srcElement.value;
	}

	setReference (event) {
		let ref = this.getValFromEvent (event)
		this.flat.setReference (ref)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado la referencia: ' + ref)

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setDate (stringDate: string) {

		let dateObj = new Date (stringDate)

		this.flat.setReservedAt (dateObj)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado la fecha de reserva: ' + this.flatService.flat.getReservedAt ().toISOString ().slice(0, 10).replace('T', ' '))

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

	setRegisteredAt (stringDate: string) {

		let dateObj = new Date (stringDate)

		this.flat.setRegisteredAt (dateObj)
		this.flatService.setFlat (this.flat)

		let historyLog = new HistoryLog ()
		historyLog.setIdFlat (this.flat.getId ())
		historyLog.setIdStatus (this.flat.getIdStatus ())
		historyLog.setIdUser (this.userService.getUser ().getId ())
		historyLog.setLog ('Ha actualizado la fecha de escritura: ' + this.flatService.flat.getReservedAt ().toISOString ().slice(0, 10).replace('T', ' '))

		this.historyLogService.addHistoryLog (historyLog).subscribe (() => {}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'flat-information-information.component.ts'))
	}

}
