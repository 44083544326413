import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {

	@Input () text: string
	@Input () config: any
	@Output () dialogEmitter: EventEmitter<boolean> = new EventEmitter<boolean> ()

	constructor() {
		this.text = 'Seguro que quieres borrar este evento?'
		this.config = {cancelText: 'Cancelar', successText: 'Confirmar'}
	}

	ngOnInit() { }

	emitEvent (flag: boolean = false) { this.dialogEmitter.emit (flag) }

}
