import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { Options } from 'fullcalendar';
import { EventClass } from 'src/app/classes/calendar/Event';
import { Subscription, ReplaySubject, Subject } from 'rxjs';
import { CalendarComponent } from 'ng-fullcalendar';
import { Calendar } from 'src/app/classes/calendar/Calendar';
import { GoogleCalendarService } from 'src/app/services/google-calendar.service';
import { GoogleAouthService } from 'src/app/services/google-aouth.service';
import { Costumer } from 'src/app/classes/Costumer';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { httpStatus } from 'src/app/config/httpStatus';
import { Error } from 'src/app/classes/Error';
import { takeUntil, take } from 'rxjs/Operators';
import { CostumersService } from 'src/app/services/costumers.service';
import * as moment from 'moment';
import { NotificationTypes } from 'src/app/components/notification/notificationTypes';
import { FlatService } from 'src/app/services/flat.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastrService } from 'ngx-toastr'
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Promoter } from 'src/app/classes/Promoter';
import { AgenciesService } from 'src/app/services/agencies.service';
import { Agency } from 'src/app/classes/Agency';

@Component({
	selector: 'app-tbyb',
	templateUrl: './tbyb.component.html',
	styleUrls: ['./tbyb.component.scss']
})
export class TbybComponent implements OnInit, OnDestroy {

	private calendarId: string
	calendarOptions: Options
	calendar: Calendar
	eventEditting: EventClass
	showEventTools: boolean
	dialog: any

	typeOfEdit: string
	typeOfEvent: string
	@ViewChild('ucCalendar') ucCalendar: CalendarComponent;

	costumerSelected: Costumer
	costumers: Costumer[]
	formCostumersControl = new FormControl()
	formFilteredCostumersControl = new FormControl ()
	filteredCostumers: ReplaySubject<Costumer[]> = new ReplaySubject<Costumer[]>(1);

	promoterSelected: Agency
	promoters: Agency[]
	formPromotersControl = new FormControl()
	formFilteredPromotersControl = new FormControl ()
	filteredPromoters: ReplaySubject<Agency[]> = new ReplaySubject<Agency[]>(1);

	notification = { text: '', type: NotificationTypes.error }

	showCostumerPopup: boolean
	showPromoterPopup: boolean

	protected _onDestroy = new Subject<void>();

	userSignInSubscription: Subscription = new Subscription ()
	getCostumersSubscription: Subscription = new Subscription ()
	getPromotersSubscription: Subscription = new Subscription ()
	getBookingsSubscription: Subscription = new Subscription ()

	constructor(
		private googleCalendarService: GoogleCalendarService,
		private googleAouthService: GoogleAouthService,
		private zone: NgZone,
		private costumersService: CostumersService,
		private agenciesService: AgenciesService,
		private flatService: FlatService,
		private storage: StorageService,
		private toastr: ToastrService,
		private errorService: ErrorService
	) {
		this.calendarId = 'n90llgjiv8ivbn627401rgaifg@group.calendar.google.com'
		this.showEventTools = false
		this.typeOfEdit = 'create' //edit - create
		this.typeOfEvent = 'costumer' //costumer - promoter

		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este evento?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}

		this.costumers = []
		this.costumerSelected = new Costumer()
		this.showCostumerPopup = false

		this.promoters = []
		this.promoterSelected = new Agency ()
		this.showPromoterPopup = false
	}

	ngOnInit() {

		this.userSignInSubscription = this.googleAouthService.userIsSignedIn ().subscribe (() => {
			this.afterSignIn ()
		})

		this.googleAouthService.initClient ()

		this.getCostumersSubscription = this.costumersService.getCostumers ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {

				this.costumers = res.data
				this.formCostumersControl.setValue(this.costumers);

				this.formCostumersControl.valueChanges.subscribe (res => {

					if (res instanceof Costumer) {

						this.costumerSelected = res
					}
				})

				this.filteredCostumers.next(this.costumers.slice());
				this.formFilteredCostumersControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterCostumers () );
			}
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'tbyb.component.ts'))




		this.getPromotersSubscription = this.agenciesService.getAgencies ().subscribe ((res: Error) => {

			if (res.status == httpStatus.success) {

				this.promoters = res.data
				this.formPromotersControl.setValue(this.promoters);

				this.formPromotersControl.valueChanges.subscribe (res => {

					console.log (res)

					if (res instanceof Agency) {

						this.promoterSelected = res
					}
				})

				this.filteredPromoters.next(this.promoters.slice());
				this.formFilteredPromotersControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterPromoters () );
			}
		}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'tbyb.component.ts'))
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
		this.userSignInSubscription.unsubscribe ()
		this.getCostumersSubscription.unsubscribe ()
		this.getBookingsSubscription.unsubscribe ()
	}

	private filterCostumers () {

		if (!this.costumers) {
			return;
		}

		let search = this.formFilteredCostumersControl.value;

		if (!search) {
			this.filteredCostumers.next(this.costumers.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredCostumers.next(
			this.costumers.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
		);
	}


	private filterPromoters () {

		if (!this.promoters) {
			return;
		}

		let search = this.formFilteredPromotersControl.value;

		if (!search) {
			this.filteredPromoters.next(this.promoters.slice());
			return;
		} else {
			search = search.toLowerCase();
		}

		this.filteredPromoters.next(
			this.promoters.filter(country => country.getName ().toLowerCase().indexOf(search) > -1)
		);
	}




	private afterSignIn () {

		this.initCalendar ()

		this.googleCalendarService.getCalendarsFromApi ().then ((calendars: Calendar[]) => {
			this.zone.run (() => {

				for (let calendar of calendars) {
					if (calendar.id == this.calendarId) {
						this.calendar = calendar
					}
				}

				this.googleCalendarService.getEventsFromApi (this.calendar.getId ()).then ((events: EventClass[]) => {

					this.getBookingsSubscription = this.flatService.getBookings ().subscribe ((err: Error) => {

						let eventsOfFlat: EventClass[] = []

						if (err.status == httpStatus.success) {

							for (let eventDb of err.data) {
								for (let event of events) {
									if (event.getId () == eventDb.id_event) {
										eventsOfFlat.push (event)
									}
								}
							}

							this.calendar.setEvents (eventsOfFlat)
							this.fillCalendar ()
						}

					}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'tbyb.component.ts'))
				})
			})
		})
	}


	private fillCalendar () {

		let events = this.calendar.getEventsForFullCalendar ()
		this.ucCalendar.renderEvents (events)
	}


	// ---------- EVENTS FROM FULL CALENDAR

	public eventClick (data) {

		this.eventEditting = this.calendar.getEventById (data.detail.event.id)
		this.dialog.show = true
	}


	public dayClick (data) {

		if (this.typeOfEvent == 'costumer' && this.costumerSelected.getId () <= 0) {
			this.toastr.warning ('Primero tienes que seleccionar un cliente', "Espera...")
			return
		}

		console.log (this.promoterSelected)

		if (this.typeOfEvent == 'promoter' && this.promoterSelected.getId () <= 0) {
			this.toastr.warning ('Primero tienes que seleccionar una agencia', "Espera...")
			return
		}

		this.addNewEvent (data.detail.date)
	}

	// ---------- END EVENTS FROM FULL CALENDAR


	public dialogRemoveEventCallback (flag: boolean = false) {

		this.dialog.show = false

		console.log ('event editting')
		console.log (this.eventEditting)

		if (flag) {

			this.googleCalendarService.removeEventOnApi (this.eventEditting).then ((event: EventClass) => {

				this.calendar.removeEvent (this.eventEditting)
				this.fillCalendar ()
			})
		}
	}


	private addNewEvent (dateStart: moment.Moment = moment ()) {

		let event = new EventClass ()
		let promotion:any = {}

		for (let view of this.storage.viewList) {
			if (view.name == 'promotions') {
				promotion = view.data;
			}
		}

		let eventText = ""

		if (this.typeOfEvent == 'costumer') {
			eventText = 'TBYB: ' + this.getContraccionPromotionName (promotion.name) + ": " + this.costumerSelected.getName () + " " + this.costumerSelected.getSurname ()
		} else {
			eventText = 'TBYB: ' + this.getContraccionPromotionName (promotion.name) + ": " + this.promoterSelected.getName ()
		}


		event.setSummary (eventText)

		let date = moment (dateStart.format ())
		date.hours (0).minutes (0)

		event.setStart (date)
		event.setEnd (moment (date.format ()).add(23, 'hours').add(59, 'minutes'))
		event.setCalendarId (this.calendarId)

		this.googleCalendarService.createEventOnApi (event).then ((newEvent: EventClass) => {

			this.flatService.createBooking (newEvent, this.costumerSelected, this.promoterSelected, this.typeOfEvent).subscribe ((err: Error) => {

				if (err.status == httpStatus.success) {
					this.calendar.addEvent (event)
					this.fillCalendar ()
				}

			}, (error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'tbyb.component.ts'))
		})
	}


	public setTypeOfEvent (type: string = 'costumer') {

		this.typeOfEvent = type
	}


	public openPopupCostumerEdit () { this.showCostumerPopup = true }
	public closePopupCostumerCallback () { this.showCostumerPopup = false }


	private initCalendar () {

		this.calendarOptions = {
			height: 100,
			editable: false,
			eventLimit: false,

			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,listMonth',

			},

			defaultView: 'month',
			//fixedWeekCount: false,

			buttonText: {
				today: 'Hoy',
				month: 'Mes',
				//list: 'Próximos eventos'
			},

			dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			dayNamesShort: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			monthNamesShort: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			firstDay: 1,
			//columnHeader: false,
			titleFormat: "MMMM YYYY",

			events: []
		};
	}


	private getContraccionPromotionName (promotionName: string = "") {

		let contraccion = ""

		switch (promotionName) {
			case 'Altos del mediterraneo': contraccion = 'ADM'; break;
			case 'Breezes': contraccion = 'Br'; break;
			case 'Dama IV': contraccion = 'D.IV'; break;
			case 'Edificio San Antonio': contraccion = 'ESA'; break;
			case 'Green Hills': contraccion = 'GH'; break;
			case 'New Sierra Golf': contraccion = 'NSG'; break;
			case 'Villa Mediterraneo': contraccion = 'VM'; break;
			case 'Dama I': contraccion = 'D.I'; break;
			case 'Dama II': contraccion = 'D.II'; break;
			case 'Dama III': contraccion = 'D.III'; break;
			default: contraccion = promotionName; break;
		}

		return contraccion

	}
}



