import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from 'src/app/config/routes';
import { map } from 'rxjs/Operators';
import { Error } from 'src/app/classes/Error';
import { Folder } from 'src/app/classes/homebox/Folder';
import { httpStatus } from 'src/app/config/httpStatus';
import { SourceTreeService } from './source-tree.service';
import { File } from 'src/app/classes/homebox/File';

@Injectable({
	providedIn: 'root'
})
export class FileService {

	private controller: string
	private selectedFile: File
	private fileSelectorCallback: EventEmitter<boolean> = new EventEmitter<boolean> ()

	constructor( private http: HttpClient, private sourceTreeService: SourceTreeService ) {
		this.controller = 'Files'
		this.selectedFile = new File ()
	}
	
	getUrl () { return routes.api + this.controller + "/" }

	setSelectedFile (file: File = new File ()) { this.selectedFile = file }
	getSelectedFile () { return this.selectedFile }

	getFileSelectorCallback () { return this.fileSelectorCallback }

	//actualiza un archivo
	updateFile (file: File = new File ()) {

		let err = new Error ()
		let headers = new HttpHeaders ({ 'Content-Type': 'application/x-www-form-urlencoded'})
		let body = new HttpParams ().set ('file', file.toJson ())

		return this.http.post (this.getUrl () + "updateFile", body.toString (), { headers: headers }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let file = new File (res.data)
				err.setData (file)
				return err
			}
			
		}, () => {
			err.setError ('Ups..! No hemos podido mostrar el árbol de carpetas. Vuelve a intentarlo')
			return err
		}))
	}
	
	emitFileSelectorCallback (flag: boolean = false) {
		this.fileSelectorCallback.emit (flag)
	}


	//mover un archivo de carpeta (solo en el backend)
	moveFile (destinationFolder: Folder = new Folder ()) {

		if (destinationFolder == this.sourceTreeService.currentFolder) {
			this.emitFileSelectorCallback (false)
		}

		let err = new Error ()
		let headers = new HttpHeaders ({ 'Content-Type': 'application/x-www-form-urlencoded'})
		let body = new HttpParams ()
		.set ('idFileToMove', JSON.stringify (this.selectedFile.getId ()))
		.set ('idDestinationFolder', JSON.stringify (destinationFolder.getId ()))


		return this.http.post (this.getUrl () + "moveFile", body.toString (), { headers: headers }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				
				return err
			}
			
		}, () => {
			err.setError ('Ups..! No hemos podido mover el archivo. Vuelve a intentarlo')
			return err
		}))
	}

	downloadFile (file: File = new File ()) {
		window.open (this.getUrl () + "forceDownload/" + file.getUrl (), '_blank')
	}

	openFile (file: File = new File ()) {
		window.open (file.getFilePath (), '_blank')
	}

	removeFile (file: File = new File ()) {

		let err = new Error ()
		let headers = new HttpHeaders ({ 'Content-Type': 'application/x-www-form-urlencoded'})
		let body = new HttpParams ()
		.set ('idFile', JSON.stringify (file.getId ()))


		return this.http.post (this.getUrl () + "removeFile", body.toString (), { headers: headers }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				
				return err
			}
			
		}, () => {
			err.setError ('Ups..! No hemos podido mover el archivo. Vuelve a intentarlo')
			return err
		}))

	}
}
