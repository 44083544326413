import { Component, OnInit, Input } from '@angular/core';
import { NotificationTypes } from './notificationTypes';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

	_text: string

	@Input() type: NotificationTypes
	@Input ('text')
	set text (text: string) {
		this._text = text
		setTimeout(() => {
			this._text = ''
			this.text = ''
		}, 5000);
	}

	get text () { return this._text }

	constructor() {
		this.type = NotificationTypes.error
		this.text = ''
		this._text = ''
	}

	ngOnInit() {
	}

}
