import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { version } from 'src/app/config/version'
import { routes } from 'src/app/config/routes'

@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

	version: string
	baseUrl: string

	constructor (private userService: UserService) {
		this.version = version
		this.baseUrl = routes.base_url
	}

	ngOnInit() {
	}

	logOut () {
		
		this.userService.logOut ()

	}

}
