import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { routes } from '../config/routes';
import { Costumer } from '../classes/Costumer';
import { Error } from '../classes/Error';
import { map } from 'rxjs/Operators';
import { httpStatus, httpHeaders } from '../config/httpStatus';
import { ErrorService } from './error.service';
import { ParkingSpace } from '../classes/ParkingSpace';
import { StorageRoom } from '../classes/StorageRoom';

@Injectable({
	providedIn: 'root'
})
export class CostumersService {

	private controller: string

	constructor( private http: HttpClient, private errorService: ErrorService ) {
		this.controller = 'Costumers'
	}

	private getUrl () {
		return routes.api + this.controller + "/"
	}

	public getCostumers (flatId: number = 0) {

		if (flatId <= 0) {
			return this.getAllCostumers ()
		}

	} 

	private getAllCostumers () {

		let costummersClasses: Costumer[] = []
		let err = new Error ()
		let errorMessage = 'No hemos podido obtener el listado de clientes. Vuelve a intentarlo'

		return this.http.get (this.getUrl () + "getAllCostumers").pipe (map ((res: any) => {
			
			if (res.status == httpStatus.success) {
				let costumers = res.data

				for (let costumer of costumers) {
					
					costumer.created_at = new Date (costumer.created_at)
					costumer.updated_at = new Date (costumer.updated_at)
					costummersClasses.push(new Costumer (costumer))

				}

				err.setData (costummersClasses)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'costumers.service')
				return err
			}
		}))

	}

	public addCostumer (costumer: Costumer) {

		let err = new Error ()
		let body = new HttpParams ().set ('costumer', costumer.toJson ())
		let errorMessage = 'No hemos podido actualizar al cliente. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "addCostumer", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				err.setData (costumer)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'costumers.service')
				return err
			}
		}))
	}

	public removeCostumer (idCostumer: number) {

		let err = new Error ()
		let body = new HttpParams ().set ('idCostumer', idCostumer.toString ())
		let errorMessage = 'No hemos podido borrar el cliente. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "removeCostumer", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				err.setData (idCostumer)
				return err

			}  else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'costumers.service')
				return err
			}
		}))
	}


	public getParkingSpacesByIdFlat (idFlat: number, idCostumer: number) {

		let err = new Error ()
		let body = new HttpParams ().set ('idCostumer', idCostumer.toString ()).set ('idFlat', idFlat.toString ())
		let errorMessage = 'No hemos podido obtener las plazas de garage. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "getParkingSpacesByIdFlat", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				
				let parkingSpacesClasses = []

				for (let parkingSpace of res.data) {
					parkingSpacesClasses.push(new ParkingSpace (parkingSpace))
				}

				err.data = parkingSpacesClasses

				return err

			}  else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'costumers.service')
				return err
			}
		}))
	}

	public getStorageRoomsByIdFlat (idFlat: number, idCostumer: number) {

		let err = new Error ()
		let body = new HttpParams ().set ('idCostumer', idCostumer.toString ()).set ('idFlat', idFlat.toString ())
		let errorMessage = 'No hemos podido obtener los trasteros. Vuelve a intentarlo'

		return this.http.post (this.getUrl () + "getStorageRoomsByIdFlat", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {
				
				let storageRommsClasses = []

				for (let storageRoom of res.data) {
					storageRommsClasses.push(new StorageRoom (storageRoom))
				}

				err.data = storageRommsClasses

				return err

			}  else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'costumers.service')
				return err
			}
		}))
	}


}
