import { Routes, RouterModule } from '@angular/router';
import { HometouchComponent } from './hometouch/hometouch.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ZonesComponent } from './zones/zones.component';
import { FlatsComponent } from './flats/flats.component';
import { FlatViewComponent } from './flat-view/flat-view.component';
import { FLAT_ROUTES } from './flat-view/flat-routing.module';
import { MapZoneComponent } from './map-zone/map-zone.component';
import { GarageComponent } from './garage/garage.component';

const routes: Routes = [

	{ path: 'promotions', component: PromotionsComponent },
	{ path: 'zones', component: ZonesComponent },
	{ path: 'map-zone', component: MapZoneComponent },
	{ path: 'flats', component: FlatsComponent },
	{ path: 'flat', component: FlatViewComponent, children: FLAT_ROUTES },
	{ path: 'garage', component: GarageComponent },
	{ path: '', pathMatch: 'full', redirectTo: '/hometouch/promotions' }
	
	
];

export const HOMETOUCH_ROUTES = routes
