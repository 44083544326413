import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AgentsService } from 'src/app/services/agents.service';
import { Agent } from 'src/app/classes/Agent';
import { Error } from 'src/app/classes/Error';
import { httpStatus } from 'src/app/config/httpStatus';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-agent',
	templateUrl: './agent.component.html',
	styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit, OnDestroy {

	@Input ('agent') agent: Agent
	@Output () removeAgentEmitter: EventEmitter<Agent> = new EventEmitter<Agent> ()
	dialog: any
	
	removeAgentSub: Subscription = new Subscription ()

	constructor( private agentsService: AgentsService, private errorService: ErrorService ) {
		this.dialog = {
			show: false,
			text: '¿Seguro que quieres borrar este agente?',
			config: {cancelText: 'Cancelar', successText: 'Eliminar'}
		}
	}

	ngOnInit() { }

	ngOnDestroy () {
		this.removeAgentSub.unsubscribe ()
	}

	removeAgent () {
		this.dialog.show = true
	}


	dialogRemoveAgentCallback (flag: boolean) {

		this.dialog.show = false

		if (flag) {
			
			this.removeAgentSub = this.agentsService.removeAgent (this.agent.getId ()).subscribe ((res: Error) => {
				
				if (res.status == httpStatus.success) {
					this.removeAgentEmitter.emit (this.agent)
				}
			},(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'agent.component.ts'))	
		}
	}

}
