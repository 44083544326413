import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Agency } from 'src/app/classes/Agency';
import { Flat } from 'src/app/classes/Flat';
import { FlatsService } from 'src/app/services/flats.service';
import { ErrorService } from 'src/app/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { httpStatus } from 'src/app/config/httpStatus';

@Component({
  selector: 'app-agency-flats',
  templateUrl: './agency-flats.component.html',
  styleUrls: ['./agency-flats.component.scss']
})
export class AgencyFlatsComponent implements OnInit {

  @Input () agency: Agency
  @Output () closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean> ()
  isWorking: boolean
  flats: Flat[]

  constructor(private flatsService: FlatsService, private errorService: ErrorService) {
    this.isWorking = false
    this.flats = []
  }

  ngOnInit() {

    this.flatsService.getFlatsByAgencyId (this.agency.getId ()).subscribe ((res: any) => {
      
      if (res.status == httpStatus.success) {

        this.flats = res.data

      }

      console.log (this.flats[0])
      
    },(error: HttpErrorResponse) => this.errorService.notifyMainError (error, 'costumer-flats.component.ts'))

  }
  
  closePopup (flag: boolean = false) {
		
		//this.initAnimations = false
		setTimeout (() => { this.closeEmitter.emit (flag) }, 300)
  }
  
  openFlat (flat) {

    let item = {
      promotion: flat.getPromotion (),
      zone: flat.getZone (),
      flat: flat
    }

    this.flatsService.setAllRouteOfFlat (item)

  }

}
