import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { routes } from './../config/routes'
import { httpStatus, httpHeaders } from './../config/httpStatus'
import { map } from 'rxjs/Operators'
import { Error } from './../classes/Error'
import { Lawyer } from '../classes/Lawyer';
import { Country } from '../classes/country';
import { ContactLawyer } from '../classes/ContactLawyer';
import { ErrorService } from './error.service';

@Injectable({
	providedIn: 'root'
})
export class LawyersService {

	constructor( private http: HttpClient, private errorService: ErrorService ) { }

	private controller = 'Lawyers';

	getUrl () {
		return routes.api + this.controller + "/"
	}

	getLawyers () {

		let lawyersClasses = []
		let err = new Error ()
		let errorMessage = 'No hemos podido descargar los abogados. Vuelve a intentarlo'

		return this.http.get (this.getUrl () + "getLawyers").pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {
				let lawyers = res.data;
				
				
				for (let lawyer of lawyers) {

					let lawy = new Lawyer (lawyer)
					let country = new Country (lawyer['country'])
					let contactsLawyerClasses = []

					for (let contactLawyer of lawyer.contactsLawyer) {
						let contactLawyerClass = new ContactLawyer (contactLawyer)
						contactsLawyerClasses.push (contactLawyerClass)
					}

					lawy.setContactsLawyer (contactsLawyerClasses)
					lawy.setCountry (country)
					
					lawyersClasses.push (lawy)
				}

				err.setData (lawyersClasses)

				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'lawyers.service')
				return err
			}

		}))
	}


	addLawyer (lawyer: Lawyer) {

		let err = new Error ()
		let body = new HttpParams ().set ('lawyer', lawyer.toJson ())
		let errorMessage = 'No hemos podido actualizar el abogado. Vuelve a intentarlo'


		return this.http.post (this.getUrl () + "addLawyer", body.toString (), { headers: httpHeaders }).pipe (map ((res: any) => {

			if (res.status == httpStatus.success) {

				if (typeof res.data.id != "undefined") {
					lawyer.setId (res.data.id)
				}

				err.setData (lawyer)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'lawyers.service')
				return err
			}
			
		}))
	}


	public removeLawyer (idLawyer: number) {

		let err = new Error ()
		let body = new HttpParams ().set ('idLawyer', idLawyer.toString ())
		let errorMessage = 'No hemos podido borrar el abogado. Vuelve a intentarlo'


		return this.http.post (this.getUrl () + "removeLawyer", body.toString (), { headers: httpHeaders }).pipe (map ((res: Error) => {

			if (res.status == httpStatus.success) {

				err.setData (idLawyer)
				return err

			} else {

				err.setError (errorMessage)
				this.errorService.notifyError (res, errorMessage, 'Ups..!', 'lawyers.service')
				return err
			}
			
		}))
	}
}
